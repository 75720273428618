import { jsx as _jsx } from "react/jsx-runtime";
// src/components/PendingDeleteRoute.tsx
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
var PendingDeleteRoute = function () {
    var isAuthenticated = useSelector(function (state) { return state.auth; }).isAuthenticated;
    var location = useLocation();
    var currentStatus = sessionStorage.getItem('currentStatus');
    var isPendingDelete = currentStatus === 'PENDING_DELETE';
    if (!isAuthenticated) {
        return _jsx(Navigate, { to: "/login", state: { from: location }, replace: true });
    }
    // If user is pending delete, allow access to the pending delete page
    // Otherwise, redirect to dashboard
    return isPendingDelete ? _jsx(Outlet, {}) : _jsx(Navigate, { to: "/dashboard", replace: true });
};
export default PendingDeleteRoute;
