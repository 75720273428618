// src/config/languageConfig.ts
export var SUPPORTED_LANGUAGES = [
    { code: 'en', label: 'English', nativeName: 'English' },
    { code: 'es', label: 'Spanish', nativeName: 'Español' },
    { code: 'fr', label: 'French', nativeName: 'Français' },
    { code: 'de', label: 'German', nativeName: 'Deutsch' }
];
// Helper function to check if a language is supported
export var isSupportedLanguage = function (code) {
    return SUPPORTED_LANGUAGES.map(function (lang) { return lang.code; }).includes(code);
};
