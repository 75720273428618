// website/src/utils/apiConnector.js

import axios from 'axios';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import configJson from '../config/infrastructure.json';
import { logger } from '@utils/logger';

const stage = process.env.REACT_APP_STAGE || 'beta';
const repository = process.env.REACT_APP_REPOSITORY || 'routy-web';
const infrastructure = JSON.parse(JSON.stringify(configJson));
const poolKey = stage === 'prod' ? 'routy-web-prod' : 'routy-web-beta';

class ApiConnector {
  constructor(getState) {
    this.baseURL = this.getBaseURL();
    this.getState = getState;
    this.instance = axios.create({
      baseURL: this.baseURL,
    });

    // Add request interceptor
    this.instance.interceptors.request.use(
      async (config) => {
        try {
          const token = await this.getAuthToken();
          // logger.log('token: '+token);
          if (token) {
            config.headers['Authorization'] = 'Bearer '+token;
          }
        } catch (error) {
          logger.error('Error getting auth token', error);
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  async getAuthToken() {
    // First, try to get the token from Redux store
    const state = this.getState();
    if (state.auth && state.auth.token) {
      return state.auth.token;
    }

    // If not in Redux, fall back to Cognito
    return new Promise((resolve, reject) => {
      const userPool = new CognitoUserPool({
        UserPoolId: this.getUserPoolId(),
        ClientId: this.getUserPoolClientId(),
      });
      const cognitoUser = userPool.getCurrentUser();

      if (cognitoUser) {
        cognitoUser.getSession((err, session) => {
          logger.log('Token expired, attempting to refresh...');
          if (err) {
            reject(err);
          } else {
            resolve(session.getIdToken().getJwtToken());
          }
        });
      } else {
        resolve(null);
      }
    });
  }

  getUserPoolId() {
    // logger.log('getUserPoolId for stage: '+stage)
    // logger.log('getUserPoolId for repository: '+repository)
    return infrastructure.cognito.pools[repository+'-'+stage].userPoolId;
  }

  getUserPoolClientId() {
    // logger.log('getUserPoolClientId for stage: '+stage)
    // logger.log('getUserPoolClientId for repository: '+repository)
    return infrastructure.cognito.pools[repository+'-'+stage].userPoolClientId;
  }

  getBaseURL() {
    // logger.log('getBaseURL for stage: '+process.env.REACT_APP_STAGE)
    if (!configJson.apiEndpoints[stage]) {
      // logger.warn(`No API endpoint found for stage ` + stage +`. Defaulting to beta.`);
      return 'https://'+configJson.apiEndpoints.beta;
    }
    return 'https://'+configJson.apiEndpoints[stage];
  }

  async get(url, config = {}) {
    const fullConfig = {
      ...config,
      params: {
        ...(config.params || {}),
        repository,
        stage,
        poolKey,
      },
    };
    return this.instance.get(url, fullConfig);
  }

  async post(url, data, config = {}) {
    const fullData = {
      ...data,
      repository,
      stage,
      poolKey,
    };    
    // logger.log('fullData: '+JSON.stringify(fullData));
    return this.instance.post(url, fullData, config);
  }

  async put(url, data, config = {}) {
    const fullData = {
      ...data,
      repository,
      stage,
      poolKey,
    };
    return this.instance.put(url, fullData, config);
  }

  async delete(url, config = {}) {
    const fullConfig = {
      ...config,
      params: {
        ...(config.params || {}),
        repository,
        stage,
        poolKey,
      },
    };
    return this.instance.delete(url, fullConfig);
  }
}

// Export the class, not an instance
export default ApiConnector;