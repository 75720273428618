var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
// website/src/components/events/EventMap.tsx
import { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { Paper } from '@mui/material';
import defaultAvatar from '../../assets/avatars/default.svg';
import { calculateParticipantBounds, createAvatarMarker } from '../../utils/mapUtils';
import { selectSecret, fetchSecret } from '../../store/secretsSlice';
import { setSelectedParticipant } from '@store/eventSlice';
var EventMap = function (_a) {
    var locations = _a.locations, followUserId = _a.followUserId, onMarkerClick = _a.onMarkerClick, profiles = _a.profiles, selectedParticipantId = _a.selectedParticipantId, participantHistory = _a.participantHistory;
    var dispatch = useDispatch();
    var mapContainer = useRef(null);
    var map = useRef(null);
    var markers = useRef({});
    var mapOptions = {
        centeringDuration: 1000,
        centeringEasing: 'easeInOutCubic',
        zoomLevel: 15,
        padding: 50 // Padding around centered point
    };
    var _b = useState({}), positionHistory = _b[0], setPositionHistory = _b[1];
    var _c = useState(true), autoCenter = _c[0], setAutoCenter = _c[1];
    var region = process.env.REACT_APP_AWS_REGION || 'us-east-2';
    // Get the location key from Redux store
    var locationKey = useSelector(function (state) { return selectSecret(state, 'routy-location-key'); });
    var fitMapToBounds = function (bounds) {
        if (!map.current)
            return;
        var sw = [bounds.west, bounds.south];
        var ne = [bounds.east, bounds.north];
        // console.log('Fitting map to bounds:');
        // console.log('Southwest:', sw);
        // console.log('Northeast:', ne);
        // Add padding to ensure all markers are visible
        var padding = {
            top: 50,
            bottom: 50,
            left: 50,
            right: 50
        };
        map.current.fitBounds([sw, ne], {
            padding: padding,
            duration: 1000,
            maxZoom: 15,
            essential: true // This makes the animation essential and ensures it completes
        });
        // console.log('Map bounds after fit:', map.current.getBounds());
        // console.log('Map zoom after fit:', map.current.getZoom());
    };
    // Enhanced centering function
    var centerOnLocation = function (longitude, latitude) {
        if (!map.current)
            return;
        map.current.easeTo({
            center: [longitude, latitude],
            duration: mapOptions.centeringDuration,
            easing: function (t) {
                // easeInOutCubic
                return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
            },
            // zoom: mapOptions.zoomLevel,
            padding: mapOptions.padding
        });
    };
    // EFFECT 1: Map Initialization and Setup
    useEffect(function () {
        var initializeMap = function () { return __awaiter(void 0, void 0, void 0, function () {
            var key;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!mapContainer.current)
                            return [2 /*return*/];
                        if (!!locationKey) return [3 /*break*/, 2];
                        return [4 /*yield*/, dispatch(fetchSecret('routy-location-key'))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/]; // The next useEffect run will handle map initialization
                    case 2:
                        try {
                            key = locationKey;
                            map.current = new maplibregl.Map({
                                container: mapContainer.current,
                                style: "https://maps.geo.".concat(region, ".amazonaws.com/maps/v0/maps/routy-hybrid/style-descriptor?key=").concat(locationKey),
                                center: [-122.02954165, 37.33065778],
                                zoom: 13,
                                transformRequest: function (url) {
                                    if (url.includes('amazonaws.com')) {
                                        return {
                                            url: "".concat(url).concat(url.includes('?') ? '&' : '?', "key=").concat(locationKey)
                                        };
                                    }
                                }
                            });
                            // Handle map load
                            map.current.on('load', function () {
                                // console.log('Map loaded successfully');
                                // Add source for tracking lines
                                map.current.addSource('tracks', {
                                    type: 'geojson',
                                    data: {
                                        type: 'FeatureCollection',
                                        features: []
                                    }
                                });
                                // Add line layer
                                map.current.addLayer({
                                    id: 'tracks-layer',
                                    type: 'line',
                                    source: 'tracks',
                                    paint: {
                                        'line-color': '#2196f3',
                                        'line-width': 3,
                                        'line-opacity': 0.8
                                    }
                                });
                            });
                            // Handle missing images
                            map.current.on('styleimagemissing', function (e) {
                                var id = e.id;
                                var placeholder = new ImageData(1, 1);
                                if (map.current) {
                                    map.current.addImage(id, placeholder);
                                }
                            });
                            // Handle errors
                            map.current.on('error', function (e) {
                                console.error('Map error:', e);
                            });
                        }
                        catch (error) {
                            console.error('Failed to initialize map:', error);
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        initializeMap();
        // Cleanup
        return function () {
            if (map.current) {
                map.current.remove();
            }
        };
    }, [locationKey, dispatch, region]);
    // EFFECT 2: Marker Management
    useEffect(function () {
        if (!map.current || !locations.length)
            return;
        // Update or create markers for each location
        locations.forEach(function (_a) {
            var _b, _c;
            var userId = _a.userId, location = _a.location;
            var userProfile = profiles[userId];
            var avatarData = (_b = userProfile === null || userProfile === void 0 ? void 0 : userProfile.basic) === null || _b === void 0 ? void 0 : _b.avatarImageData;
            var avatarUrl = (avatarData === null || avatarData === void 0 ? void 0 : avatarData.data)
                ? "data:".concat(avatarData.contentType, ";base64,").concat(avatarData.data)
                : defaultAvatar;
            if (!markers.current[userId]) {
                // Create new marker
                var markerElement = createAvatarMarker(location.heading, avatarUrl, userId);
                // Add styles for non-selected markers when there's a selection
                if (selectedParticipantId && userId !== selectedParticipantId) {
                    markerElement.style.filter = 'grayscale(80%)';
                    markerElement.style.opacity = '0.3';
                }
                // Add click handler to marker element
                markerElement.addEventListener('click', function (e) {
                    e.stopPropagation(); // Prevent map click
                    if (onMarkerClick) {
                        console.log('Marker clicked:', userId);
                        console.log('selected user:', selectedParticipantId);
                        // If clicking the selected marker, deselect it
                        if (userId === selectedParticipantId) {
                            // Reset position history for the deselected participant
                            // setPositionHistory(prev => {
                            //     const newHistory = { ...prev };
                            //     delete newHistory[userId];
                            //     return newHistory;
                            // });
                            console.log('Deselecting participant:', userId);
                            dispatch(setSelectedParticipant(null));
                            onMarkerClick(null);
                        }
                        else {
                            console.log('Selecting participant:', userId);
                            dispatch(setSelectedParticipant(userId));
                            onMarkerClick(userId);
                        }
                    }
                });
                markers.current[userId] = new maplibregl.Marker({
                    element: markerElement,
                    rotationAlignment: 'map',
                    anchor: 'center'
                })
                    .setLngLat([location.longitude, location.latitude])
                    .addTo(map.current);
            }
            else {
                // Update existing marker
                var marker = markers.current[userId];
                var markerElement = marker.getElement();
                // Update styles based on selection state
                if (selectedParticipantId) {
                    if (userId !== selectedParticipantId) {
                        markerElement.style.filter = 'grayscale(80%)';
                        markerElement.style.opacity = '0.3';
                    }
                    else {
                        markerElement.style.filter = 'none';
                        markerElement.style.opacity = '1';
                    }
                }
                else {
                    // Reset styles when no selection
                    markerElement.style.filter = 'none';
                    markerElement.style.opacity = '1';
                }
                marker.setLngLat([location.longitude, location.latitude]);
                // Update avatar if profile changed
                if ((_c = userProfile === null || userProfile === void 0 ? void 0 : userProfile.basic) === null || _c === void 0 ? void 0 : _c.avatarImageData) {
                    var avatarImg = markerElement.querySelector('img');
                    if (avatarImg) {
                        avatarImg.src = avatarUrl;
                    }
                }
            }
        });
        // Update track lines
        if (map.current.getSource('tracks')) {
            var features = Object.entries(positionHistory).map(function (_a) {
                var userId = _a[0], positions = _a[1];
                return ({
                    type: 'Feature',
                    properties: { userId: userId },
                    geometry: {
                        type: 'LineString',
                        coordinates: positions
                    }
                });
            });
            map.current.getSource('tracks').setData({
                type: 'FeatureCollection',
                features: features
            });
        }
        // Cleanup old markers
        Object.keys(markers.current).forEach(function (userId) {
            if (!locations.find(function (loc) { return loc.userId === userId; })) {
                markers.current[userId].remove();
                delete markers.current[userId];
                setPositionHistory(function (prev) {
                    var newHistory = __assign({}, prev);
                    delete newHistory[userId];
                    return newHistory;
                });
            }
        });
    }, [locations, profiles, onMarkerClick, selectedParticipantId]);
    // EFFECT 3: Selected Participant Route Display
    useEffect(function () {
        if (!map.current || !(participantHistory === null || participantHistory === void 0 ? void 0 : participantHistory.locations.length))
            return;
        var routeData = {
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'LineString',
                coordinates: participantHistory.locations.map(function (loc) { return [
                    loc.longitude,
                    loc.latitude
                ]; })
            }
        };
        var sourceId = 'selected-participant-route';
        if (map.current.getSource(sourceId)) {
            map.current.getSource(sourceId)
                .setData(routeData);
        }
        else {
            map.current.addSource(sourceId, {
                type: 'geojson',
                data: routeData
            });
            map.current.addLayer({
                id: 'route-line',
                type: 'line',
                source: sourceId,
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                paint: {
                    'line-color': '#2196f3',
                    'line-width': 3,
                    'line-opacity': 0.8
                }
            });
        }
    }, [participantHistory]);
    // EFFECT 4: Route Cleanup
    useEffect(function () {
        if (!map.current)
            return;
        if (!selectedParticipantId && map.current.getSource('selected-participant-route')) {
            if (map.current.getLayer('route-line')) {
                map.current.removeLayer('route-line');
            }
            map.current.removeSource('selected-participant-route');
        }
    }, [selectedParticipantId]);
    // EFFECT 5: Unified Viewport Management
    useEffect(function () {
        var _a;
        if (!map.current || map.current.isMoving())
            return;
        // Priority 1: Selected participant with route history
        if (selectedParticipantId && (participantHistory === null || participantHistory === void 0 ? void 0 : participantHistory.locations.length)) {
            var coordinates = participantHistory.locations.map(function (loc) {
                return [loc.longitude, loc.latitude];
            });
            if (coordinates.length > 0) {
                var bounds_1 = new maplibregl.LngLatBounds();
                coordinates.forEach(function (coord) {
                    bounds_1.extend(coord);
                });
                map.current.fitBounds(bounds_1, {
                    padding: 50,
                    maxZoom: 15,
                    duration: 1000
                });
            }
            return;
        }
        // Priority 2: Following specific user
        if (followUserId && locations.length) {
            var userLocation = (_a = locations.find(function (loc) { return loc.userId === followUserId; })) === null || _a === void 0 ? void 0 : _a.location;
            if (userLocation) {
                map.current.easeTo({
                    center: [userLocation.longitude, userLocation.latitude],
                    duration: 1000,
                    zoom: mapOptions.zoomLevel
                });
            }
            return;
        }
        // Priority 3: Auto-center for all participants
        if (autoCenter && locations.length) {
            if (locations.length > 1) {
                var bounds = calculateParticipantBounds(locations);
                if (bounds) {
                    fitMapToBounds(bounds);
                }
            }
            else {
                var location_1 = locations[0].location;
                centerOnLocation(location_1.longitude, location_1.latitude);
            }
        }
    }, [
        locations,
        followUserId,
        autoCenter,
        selectedParticipantId,
        participantHistory
    ]);
    return (_jsx(Paper, __assign({ sx: {
            height: '100%',
            width: '100%',
            position: 'relative',
            overflow: 'hidden',
            '& .maplibregl-canvas': {
                borderRadius: 1
            }
        } }, { children: _jsx("div", { ref: mapContainer, style: { width: '100%', height: '100%' } }) })));
};
export default EventMap;
