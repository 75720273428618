var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// src/hooks/useProfileForm.ts
import { useState, useEffect, useCallback } from 'react';
import { profileUtils } from '../types/profile.schema';
export var useProfileForm = function (initialProfile) {
    var _a = useState(profileUtils.normalizeProfile(initialProfile)), formData = _a[0], setFormData = _a[1];
    // Add a flag to track if the form is being edited
    var _b = useState(false), isEditing = _b[0], setIsEditing = _b[1];
    useEffect(function () {
        // Only update if we're not currently editing and the data actually changed
        if (!isEditing &&
            initialProfile &&
            JSON.stringify(formData) !== JSON.stringify(profileUtils.normalizeProfile(initialProfile))) {
            setFormData(profileUtils.normalizeProfile(initialProfile));
        }
    }, [initialProfile, isEditing]);
    var handleInputChange = useCallback(function (e) {
        // logger.log('handleInputChange', e.target.name, e.target.value)
        var _a = e.target, name = _a.name, value = _a.value;
        var _b = name.includes('.') ? name.split('.') : ['basic', name], sectionKey = _b[0], fieldKey = _b[1];
        var section = sectionKey;
        setIsEditing(true); // Set editing flag when user starts typing
        setFormData(function (prev) {
            var _a, _b;
            return (__assign(__assign({}, prev), (_a = {}, _a[section] = __assign(__assign({}, prev[section]), (_b = {}, _b[fieldKey] = value, _b)), _a)));
        });
    }, []);
    // Add a function to reset the editing state
    var resetEditingState = useCallback(function () {
        setIsEditing(false);
    }, []);
    var handleSwitchChange = useCallback(function (section, field) { return function (e) {
        setFormData(function (prev) {
            var _a, _b;
            return (__assign(__assign({}, prev), (_a = {}, _a[section] = __assign(__assign({}, prev[section]), (_b = {}, _b[field] = (e.target.checked ? 'true' : 'false'), _b)), _a)));
        });
    }; }, []);
    var handleSelectChange = useCallback(function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        var _b = name.includes('.') ? name.split('.') : ['preferences', name], sectionKey = _b[0], fieldKey = _b[1];
        var section = sectionKey;
        setFormData(function (prev) {
            var _a, _b;
            return (__assign(__assign({}, prev), (_a = {}, _a[section] = __assign(__assign({}, prev[section]), (_b = {}, _b[fieldKey] = value, _b)), _a)));
        });
    }, []);
    return {
        formData: formData,
        setFormData: setFormData,
        handleInputChange: handleInputChange,
        handleSwitchChange: handleSwitchChange,
        handleSelectChange: handleSelectChange,
        resetEditingState: resetEditingState,
        isEditing: isEditing
    };
};
