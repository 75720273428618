var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// website/src/components/events/EventDetails.tsx
import { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Box, Paper, } from '@mui/material';
import EventMap from './EventMap';
import { initializeEventParticipants, updateEventParticipantLocation, initializeEventSpectators } from '@store/eventSlice';
import { fetchUserProfileAsync } from '@utils/eventUtils';
import { selectCachedProfiles, makeSelectEventParticipants, makeSelectEventSpectators } from '@store/selectors/eventSelectors';
import IconButton from '@mui/material/IconButton';
import ParticipantList from './ParticipantList';
import SpectatorList from './SpectatorList';
import { useTheme } from '@mui/material/styles';
import { eventStyles } from './styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { getParticipantData } from '@utils/eventUtils';
import { fetchAdventureHistory } from '../../store/adventureSlice';
var EventDetails = function (_a) {
    var event = _a.event, locations = _a.locations, socket = _a.socket;
    var theme = useTheme();
    var styles = eventStyles(theme);
    var dispatch = useDispatch();
    var _b = useState(Date.now()), currentTime = _b[0], setCurrentTime = _b[1];
    var _c = useSelector(function (state) { return state.auth; }), cognitoUserId = _c.cognitoUserId, isAuthenticated = _c.isAuthenticated;
    var _d = useState(false), isSidebarCollapsed = _d[0], setIsSidebarCollapsed = _d[1];
    var _e = useState(true), isParticipantsExpanded = _e[0], setIsParticipantsExpanded = _e[1];
    var _f = useState(false), isSpectatorsExpanded = _f[0], setIsSpectatorsExpanded = _f[1];
    // Memoized selectors
    var selectEventParticipants = useMemo(function () { return makeSelectEventParticipants(); }, []);
    var selectEventSpectators = useMemo(function () { return makeSelectEventSpectators(); }, []);
    // Selectors
    var cachedProfiles = useSelector(selectCachedProfiles);
    var eventParticipants = useSelector(function (state) {
        return selectEventParticipants(state, event.eventId);
    });
    var eventSpectators = useSelector(function (state) {
        return selectEventSpectators(state, event.eventId);
    });
    // console.log('Event eventParticipants:', eventParticipants); 
    // Transform cached profiles to the format EventMap expects
    var profiles = {};
    Object.entries(cachedProfiles).forEach(function (_a) {
        var userId = _a[0], profileTypes = _a[1];
        // Prefer AUTHENTICATED profile, fall back to PUBLIC
        var profile = profileTypes.AUTHENTICATED || profileTypes.PUBLIC;
        if (profile) {
            profiles[userId] = profile;
        }
    });
    var participantCount = Object.values(event.participants).filter(function (p) { return p.status === 'ACCEPTED'; }).length;
    //    const isSpectator = useMemo(() => 
    //     cognitoUserId ? Object.keys(event.spectators || {}).includes(cognitoUserId) : false,
    //     [event.spectators, cognitoUserId]
    // );
    // useSpectatorHeartbeat(
    //     isSpectator,
    //     socket,
    //     event.eventId,
    //     cognitoUserId
    // ); 
    // Initialize participants when event loads
    useEffect(function () {
        console.log('called useEffect init');
        dispatch(initializeEventParticipants({
            eventId: event.eventId,
            participants: event.participants,
            cachedProfiles: cachedProfiles
        }));
        dispatch(initializeEventSpectators({
            eventId: event.eventId,
            spectators: event.spectators,
            cachedProfiles: cachedProfiles
        }));
        // Get all unique userIds from both participants and spectators
        var participantIds = Object.keys(event.participants);
        var spectatorIds = Object.keys(event.spectators || {});
        var allUserIds = Array.from(new Set(participantIds.concat(spectatorIds)));
        // console.log('All unique user IDs in the event:', allUserIds);
        // Fetch missing profiles
        allUserIds.forEach(function (userId) {
            var _a, _b;
            var thisUserId = userId.replace('USER#', '');
            if (!((_a = cachedProfiles[thisUserId]) === null || _a === void 0 ? void 0 : _a.AUTHENTICATED) && !((_b = cachedProfiles[thisUserId]) === null || _b === void 0 ? void 0 : _b.PUBLIC)) {
                fetchUserProfileAsync(dispatch, thisUserId);
            }
        });
    }, [event.eventId, event.participants, event.spectators, cachedProfiles, dispatch]);
    // Handle location updates
    useEffect(function () {
        var _a, _b;
        if (locations.length > 0) {
            var latestLocation = locations[locations.length - 1];
            // Check if we have the profile cached before updating
            if (((_a = cachedProfiles[latestLocation.userId]) === null || _a === void 0 ? void 0 : _a.AUTHENTICATED) ||
                ((_b = cachedProfiles[latestLocation.userId]) === null || _b === void 0 ? void 0 : _b.PUBLIC)) {
                var enhancedLocation = __assign(__assign({}, latestLocation.location), { timestamp: latestLocation.timestamp, adventureId: latestLocation.adventureId, routeId: latestLocation.routeId, maxSpeed: latestLocation.maxSpeed, maxAltitude: latestLocation.maxAltitude, minAltitude: latestLocation.minAltitude, odometer: latestLocation.odometer });
                dispatch(updateEventParticipantLocation({
                    eventId: event.eventId,
                    userId: latestLocation.userId,
                    location: enhancedLocation,
                    cachedProfiles: cachedProfiles
                }));
            }
            else {
                // console.log('Skipping location update - no cached profile for:', latestLocation.userId);
            }
        }
    }, [locations, event.eventId, cachedProfiles, dispatch]);
    useEffect(function () {
        // we're introducing some randomness to the update timestamp if there is no activity
        // this is betweeen 17 and 10 seconds - to change adjust 17000 - 10000, first number is larger than the second
        var getRandomInterval = function () { return Math.floor(Math.random() * (17000 - 10000 + 1) + 10000); };
        var timer = setInterval(function () {
            setCurrentTime(Date.now());
        }, getRandomInterval()); // Update every ten seconds
        return function () { return clearInterval(timer); };
    }, []);
    useEffect(function () {
        // Only proceed if we have an active socket connection
        if (!socket || socket.readyState !== WebSocket.OPEN)
            return;
        // Only run this effect if we're a spectator
        // console.log('Spectator effect triggered:', cognitoUserId)    
        if (!cognitoUserId)
            return;
        // Check if user is a spectator without depending on the entire eventSpectators object
        var isSpectator = Object.keys(event.spectators || {}).includes(cognitoUserId);
        if (!isSpectator)
            return;
        // Random interval between 10-17 seconds for heartbeat
        var getRandomInterval = function () { return Math.floor(Math.random() * (17000 - 10000 + 1) + 10000); };
        var sendHeartbeat = function () {
            if (socket.readyState === WebSocket.OPEN) {
                socket.send(JSON.stringify({
                    action: 'message',
                    data: {
                        type: 'SPECTATOR_HEARTBEAT',
                        eventId: event.eventId,
                        payload: {
                            userId: cognitoUserId,
                            timestamp: new Date().toISOString()
                        }
                    }
                }));
            }
        };
        var timer = setInterval(sendHeartbeat, getRandomInterval());
        // Send initial heartbeat
        sendHeartbeat();
        return function () { return clearInterval(timer); };
    }, [event.eventId, cognitoUserId, socket]);
    var _g = useState(null), selectedParticipant = _g[0], setSelectedParticipant = _g[1];
    var handleParticipantClick = function (userId) { return __awaiter(void 0, void 0, void 0, function () {
        var participantData, historyResult, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    // If userId is null, we're deselecting
                    if (!userId) {
                        setSelectedParticipant(null);
                        return [2 /*return*/];
                    }
                    // console.log('Participant clicked:', userId);
                    // If clicking the already selected participant, deselect them
                    if ((selectedParticipant === null || selectedParticipant === void 0 ? void 0 : selectedParticipant.userId) === userId) {
                        setSelectedParticipant(null);
                        return [2 /*return*/];
                    }
                    participantData = getParticipantData(locations, userId);
                    // console.log('Participant data:', participantData);
                    if (!participantData) {
                        console.error('No participant data found for user:', userId);
                        return [2 /*return*/];
                    }
                    if (!participantData.adventureId) {
                        console.error('No active adventure found for participant');
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, dispatch(fetchAdventureHistory({
                            adventureId: participantData.adventureId,
                            userId: userId,
                            eventId: event.eventId
                        })).unwrap()];
                case 2:
                    historyResult = _a.sent();
                    setSelectedParticipant({
                        userId: userId,
                        adventureHistory: {
                            locations: historyResult.locations
                        }
                    });
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.error('Failed to fetch adventure history:', error_1);
                    // We might want to show an error message to the user here
                    setSelectedParticipant(null);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleToggleParticipants = function () {
        if (!isParticipantsExpanded) { // Only allow expanding
            setIsParticipantsExpanded(true);
            setIsSpectatorsExpanded(false);
        }
    };
    var handleToggleSpectators = function () {
        if (!isSpectatorsExpanded) { // Only allow expanding
            setIsSpectatorsExpanded(true);
            setIsParticipantsExpanded(false);
        }
    };
    return (_jsxs(Box, __assign({ sx: styles.container }, { children: [_jsxs(Paper, __assign({ elevation: 2, sx: styles.header }, { children: [_jsx(Typography, __assign({ variant: "h6" }, { children: event.name })), _jsx(IconButton, __assign({ onClick: function () { return setIsSidebarCollapsed(!isSidebarCollapsed); }, size: "small" }, { children: isSidebarCollapsed ? _jsx(ChevronLeftIcon, {}) : _jsx(ChevronRightIcon, {}) }))] })), _jsxs(Box, __assign({ sx: styles.mapContainer }, { children: [_jsx(Box, __assign({ sx: styles.map(isSidebarCollapsed) }, { children: _jsx(EventMap, { locations: locations, profiles: profiles, onMarkerClick: handleParticipantClick, selectedParticipantId: selectedParticipant === null || selectedParticipant === void 0 ? void 0 : selectedParticipant.userId, participantHistory: selectedParticipant === null || selectedParticipant === void 0 ? void 0 : selectedParticipant.adventureHistory }) })), _jsxs(Box, __assign({ sx: styles.sidebarContainer(isSidebarCollapsed) }, { children: [_jsx(ParticipantList, { participants: eventParticipants, locations: locations, onParticipantClick: handleParticipantClick, isCollapsed: isSidebarCollapsed, isExpanded: isParticipantsExpanded, onToggleExpand: handleToggleParticipants }), _jsx(SpectatorList, { spectators: eventSpectators, isCollapsed: isSidebarCollapsed, isExpanded: isSpectatorsExpanded, onToggleExpand: handleToggleSpectators, cognitoUserId: cognitoUserId ? cognitoUserId : '' })] }))] }))] })));
};
export default EventDetails;
