// website/src/middleware/activityLoggerMiddleware.ts
import { logActivity } from '../utils/activityLogger';
import { setIsAuthenticated, resetToken, setCognitoUserId } from '@store/authSlice';
import { clearUserData, updateCache, invalidateCache } from '@store/userSlice';
var isActionWithType = function (action) {
    return typeof action === 'object' && action !== null && 'type' in action;
};
export var activityLoggerMiddleware = function (store) { return function (next) { return function (action) {
    var prevState = store.getState();
    var prevAuth = prevState.auth;
    var result = next(action);
    var currentState = store.getState();
    var currentAuth = currentState.auth;
    if (!isActionWithType(action))
        return result;
    switch (action.type) {
        // Auth-related actions
        case setIsAuthenticated.type:
            // console.log('setIsAuthenticated action detected', action)
            if ('payload' in action && action.payload === true) {
                logActivity(currentAuth.cognitoUserId || null, {
                    action: 'user_authenticated',
                    activitynotes: 'User authenticated successfully'
                });
            }
            break;
        case resetToken.type:
            // console.log('resetToken action detected', action)
            logActivity(prevAuth.cognitoUserId || null, {
                action: 'user_logout',
                activitynotes: 'User logged out'
            });
            break;
        case setCognitoUserId.type:
            // console.log('setCognitoUserId action detected', action)
            if ('payload' in action && typeof action.payload === 'string') {
                logActivity(action.payload, {
                    action: 'user_identified',
                    activitynotes: 'Cognito user ID set'
                });
            }
            break;
        // User-related actions
        case updateCache.type:
            // console.log('updateCache action detected', action)
            if ('payload' in action) {
                var payload = action.payload;
                logActivity(currentAuth.cognitoUserId || null, {
                    action: 'profile_update',
                    activitynotes: "User profile updated - ".concat(payload.endpointType),
                    contentDataType: 'profile',
                    contentDataID: payload.userId,
                    modalAction: payload.endpointType
                });
            }
            break;
        case clearUserData.type:
            // console.log('clearUserData action detected', action)
            logActivity(prevAuth.cognitoUserId || null, {
                action: 'clear_user_data',
                activitynotes: 'User data cleared'
            });
            break;
        case invalidateCache.type:
            // console.log('invalidateCache action detected', action)
            if ('payload' in action) {
                var payload = action.payload;
                logActivity(currentAuth.cognitoUserId || null, {
                    action: 'cache_invalidated',
                    activitynotes: "Cache invalidated for ".concat(payload.endpointType || 'all endpoints'),
                    contentDataID: payload.userId
                });
            }
            break;
    }
    return result;
}; }; };
