var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { withLayout } from '@components/withLayout';
import { Box, Container, Typography, Button, Paper, Alert, keyframes, styled, } from '@mui/material';
import { SentimentVeryDissatisfied, Home, Login, Search } from '@mui/icons-material';
// Animation keyframes
var bounce = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0%, 100% { transform: translateY(0); }\n  50% { transform: translateY(-20px); }\n"], ["\n  0%, 100% { transform: translateY(0); }\n  50% { transform: translateY(-20px); }\n"])));
var float = keyframes(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  0%, 100% { transform: translateX(0); }\n  50% { transform: translateX(15px); }\n"], ["\n  0%, 100% { transform: translateX(0); }\n  50% { transform: translateX(15px); }\n"])));
var StyledNumber = styled(Typography)(function (_a) {
    var theme = _a.theme;
    return ({
        fontSize: '12rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        textShadow: '4px 4px 0px rgba(0,0,0,0.1)',
        animation: "".concat(bounce, " 2s infinite ease-in-out"),
        '@media (max-width: 600px)': {
            fontSize: '8rem',
        },
    });
});
var StyledIcon = styled(SentimentVeryDissatisfied)(function (_a) {
    var theme = _a.theme;
    return ({
        fontSize: '5rem',
        color: theme.palette.secondary.main,
        animation: "".concat(float, " 3s infinite ease-in-out"),
    });
});
var NotFound = function () {
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var isAuthenticated = useSelector(function (state) { return state.auth; }).isAuthenticated;
    var isDevServer = useSelector(function (state) { return state.app.isDevServer; });
    var randomMessages = [
        t('notFound.messages.vacation'),
        t('notFound.messages.houston'),
        t('notFound.messages.void'),
        t('notFound.messages.hideSeek'),
        t('notFound.messages.matrix')
    ];
    var getRandomMessage = function () {
        return randomMessages[Math.floor(Math.random() * randomMessages.length)];
    };
    return (_jsx(Container, __assign({ maxWidth: "md" }, { children: _jsxs(Box, __assign({ sx: {
                minHeight: '80vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                gap: 3,
            } }, { children: [isDevServer && (_jsx(Alert, __assign({ severity: "info", sx: {
                        position: 'absolute',
                        top: 20,
                        left: '50%',
                        transform: 'translateX(-50%)'
                    } }, { children: t('common.devEnvironment') }))), _jsxs(Paper, __assign({ elevation: 3, sx: {
                        p: 5,
                        borderRadius: 4,
                        backgroundColor: 'rgba(255,255,255,0.9)',
                        backdropFilter: 'blur(10px)',
                        maxWidth: 600,
                        width: '100%',
                    } }, { children: [_jsx(StyledIcon, {}), _jsx(StyledNumber, __assign({ variant: "h1" }, { children: "404" })), _jsx(Typography, __assign({ variant: "h4", gutterBottom: true, sx: {
                                fontWeight: 'bold',
                                mb: 3,
                            } }, { children: getRandomMessage() })), _jsx(Typography, __assign({ variant: "body1", color: "text.secondary", sx: { mb: 4 } }, { children: t('notFound.supportText') })), _jsxs(Box, __assign({ sx: {
                                display: 'flex',
                                gap: 2,
                                justifyContent: 'center',
                                flexWrap: 'wrap'
                            } }, { children: [_jsx(Button, __assign({ variant: "contained", color: "primary", size: "large", startIcon: _jsx(Home, {}), component: RouterLink, to: isAuthenticated ? "/dashboard" : "/" }, { children: isAuthenticated ? t('common.buttons.dashboard') : t('common.home') })), !isAuthenticated && (_jsx(Button, __assign({ variant: "outlined", color: "secondary", size: "large", startIcon: _jsx(Login, {}), component: RouterLink, to: "/login" }, { children: t('common.buttons.signIn') }))), _jsx(Button, __assign({ variant: "outlined", color: "info", size: "large", startIcon: _jsx(Search, {}), onClick: function () { return window.history.back(); } }, { children: t('common.buttons.goBack') }))] }))] }))] })) })));
};
export default withLayout(NotFound);
var templateObject_1, templateObject_2;
