var _a;
// website/src/store/settingsSlice.ts
import { createSlice } from '@reduxjs/toolkit';
import { login } from './authSlice';
import { LanguageManager } from '../i18n/languageManager';
import { logger } from '@utils/logger';
// Type guard to check if response is a LoginPayload
function isLoginPayload(response) {
    return 'userProfile' in response && !('challengeName' in response);
}
var initialState = {
    language: LanguageManager.getStoredLanguage() || 'en',
    theme: 'light',
    timezone: 'UTC',
    isLoading: false
};
var settingsSlice = createSlice({
    name: 'settings',
    initialState: initialState,
    reducers: {
        setLanguage: function (state, action) {
            state.language = action.payload;
            LanguageManager.updateLanguage(action.payload);
        },
        setTheme: function (state, action) {
            state.theme = action.payload;
        },
        setTimezone: function (state, action) {
            state.timezone = action.payload;
        },
        setSettings: function (state, action) {
            if (action.payload.language) {
                state.language = action.payload.language;
                LanguageManager.updateLanguage(action.payload.language);
            }
            if (action.payload.theme) {
                state.theme = action.payload.theme;
            }
            if (action.payload.timezone) {
                state.timezone = action.payload.timezone;
            }
        }
    },
    extraReducers: function (builder) {
        builder
            .addCase(login.fulfilled, function (state, action) {
            var _a;
            if (isLoginPayload(action.payload)) {
                // Handle the metadata structure
                var userMetadata = (_a = action.payload.userProfile) === null || _a === void 0 ? void 0 : _a.metadata;
                if (userMetadata) {
                    var language = userMetadata.language || 'en';
                    var theme = userMetadata.theme || 'light';
                    var timezone = userMetadata.timezone || 'UTC';
                    logger.log("Setting user preferences from profile - Language: ".concat(language, ", Theme: ").concat(theme, ", Timezone: ").concat(timezone));
                    state.language = language;
                    state.theme = theme;
                    state.timezone = timezone;
                    LanguageManager.updateLanguage(language);
                }
            }
            state.isLoading = false;
        })
            .addCase(login.pending, function (state) {
            state.isLoading = true;
        })
            .addCase(login.rejected, function (state) {
            state.isLoading = false;
        });
    }
});
export var setLanguage = (_a = settingsSlice.actions, _a.setLanguage), setTheme = _a.setTheme, setTimezone = _a.setTimezone, setSettings = _a.setSettings;
export default settingsSlice.reducer;
