var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardActions, Typography, Button, Chip, Box, IconButton, Tooltip, } from '@mui/material';
import { Person as PersonIcon, Visibility as ViewerIcon, Schedule as ScheduleIcon, Public as PublicIcon, Lock as PrivateIcon, } from '@mui/icons-material';
import { joinEvent } from '@store/eventSlice';
import { formatDistanceToNow } from 'date-fns';
var EventCard = function (_a) {
    var event = _a.event, onEventSelect = _a.onEventSelect;
    var dispatch = useDispatch(); // Use AppDispatch type
    var navigate = useNavigate();
    var participantCount = Object.values(event.participants).filter(function (p) { return p.status === 'ACCEPTED'; }).length;
    var handleJoinAsSpectator = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: // Make async
                return [4 /*yield*/, dispatch(joinEvent({ eventId: event.PK, userId: 'Spectator', role: 'SPECTATOR' }))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleViewEvent = function () {
        // console.log('Viewing event:', JSON.stringify(event,null,2));
        onEventSelect(event);
        // navigate(`/adventures/${event.PK.split('#')[1]}`);
    };
    return (_jsxs(Card, __assign({ sx: { height: '100%', display: 'flex', flexDirection: 'column' } }, { children: [_jsxs(CardContent, __assign({ sx: { flexGrow: 1 } }, { children: [_jsxs(Box, __assign({ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1 }, { children: [_jsx(Typography, __assign({ variant: "h6", component: "div" }, { children: event.name })), _jsx(Tooltip, __assign({ title: event.settings.visibility }, { children: _jsx(IconButton, __assign({ size: "small" }, { children: event.settings.visibility === 'PUBLIC' ? _jsx(PublicIcon, {}) : _jsx(PrivateIcon, {}) })) }))] })), _jsxs(Box, __assign({ display: "flex", alignItems: "center", mb: 1 }, { children: [_jsx(ScheduleIcon, { fontSize: "small", sx: { mr: 1 } }), _jsxs(Typography, __assign({ variant: "body2", color: "text.secondary" }, { children: ["Started ", formatDistanceToNow(new Date(event.startTime)), " ago"] }))] })), _jsxs(Box, __assign({ display: "flex", gap: 1, mb: 2 }, { children: [_jsx(Chip, { icon: _jsx(PersonIcon, {}), label: "".concat(participantCount, " ").concat(event.type === 'SOLO' ? 'Participant' : 'Participants'), size: "small", color: "primary", variant: "outlined" }), event.settings.allowSpectators === 'true' && (_jsx(Chip, { icon: _jsx(ViewerIcon, {}), label: "Spectators Allowed", size: "small", color: "secondary", variant: "outlined" }))] })), event.description && (_jsx(Typography, __assign({ variant: "body2", color: "text.secondary" }, { children: event.description })))] })), _jsxs(CardActions, { children: [_jsx(Button, __assign({ size: "small", color: "primary", onClick: handleViewEvent }, { children: "View Event" })), event.settings.allowSpectators === 'true' && (_jsx(Button, __assign({ size: "small", onClick: handleJoinAsSpectator }, { children: "Join as Spectator" })))] })] })));
};
export default EventCard;
