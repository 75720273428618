var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// website/src/App.tsx
import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AuthProvider } from '@contexts/AuthContext';
import PrivateRoute from '@components/PrivateRoute';
import PendingDeleteRoute from '@components/PendingDeleteRoute';
import ActivityLoggerProvider from '@components/ActivityLoggerProvider';
import { logger } from '@utils/logger';
import { logActivity } from '@utils/activityLogger';
import './styles/fonts.css';
import './styles/App.css';
import AuthGuard from '@components/AuthGuard';
import { Snackbar } from '@components/feedback/Snackbar';
// Updated imports
import { Login, SignUp, VerifyEmail, ForgotPassword } from '@pages/auth';
import { Dashboard, Profile, SecurePage, PendingDelete, ViewAdventures } from '@pages/protected';
import { Home, OpenPage } from '@pages/public';
import NotFound from '@pages/NotFound';
// Import MUI components
import ThemeWrapper from './theme/ThemeWrapper';
import { fetchUserData } from '@store/userSlice';
import { useDispatch } from 'react-redux';
import { clearExpiredSession } from './utils/sessionManager';
var App = function (_a) {
    var isDevServer = _a.isDevServer;
    var user = useSelector(function (state) { return state.user; }).user;
    var _b = useSelector(function (state) { return state.auth; }), cognitoUserId = _b.cognitoUserId, isAuthenticated = _b.isAuthenticated;
    var dispatch = useDispatch();
    useEffect(function () {
        // Check for expired session on load
        clearExpiredSession();
        // Set up visibility change listener
        var handleVisibilityChange = function () {
            if (document.visibilityState === 'visible') {
                clearExpiredSession();
            }
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return function () {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);
    useEffect(function () {
        // logger.log('App: isAuthenticated updated:', isAuthenticated);
        var getUserProfile = function () { return __awaiter(void 0, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                logger.log('App: getUserProfile called');
                if (isAuthenticated && cognitoUserId) {
                    dispatch(fetchUserData({
                        userId: cognitoUserId,
                        endpointType: 'AUTHENTICATED'
                    })).then(function (action) {
                        if (action.type === 'user/fetchUserData/fulfilled') {
                            var payload = action.payload;
                            // logger.log('App payload:', payload);
                        }
                    });
                }
                else {
                    // logger.log('App: getUserProfile - not authenticated');
                    logActivity(((_a = user === null || user === void 0 ? void 0 : user.basic) === null || _a === void 0 ? void 0 : _a.userID) || null, {
                        action: 'pageView',
                        activitynotes: 'User landed on homepage',
                        authenticated: !!((_b = user === null || user === void 0 ? void 0 : user.basic) === null || _b === void 0 ? void 0 : _b.userID)
                    });
                }
                ;
                return [2 /*return*/];
            });
        }); };
        if (isAuthenticated) {
            getUserProfile();
        }
    }, [isAuthenticated]);
    return (_jsx(ThemeWrapper, { children: _jsx(Router, __assign({ future: { v7_startTransition: true, v7_relativeSplatPath: true } }, { children: _jsx(AuthProvider, { children: _jsxs(ActivityLoggerProvider, { children: [_jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(Home, {}) }), _jsx(Route, { path: "/open", element: _jsx(OpenPage, {}) }), _jsxs(Route, __assign({ element: _jsx(AuthGuard, {}) }, { children: [_jsx(Route, { path: "/login", element: _jsx(Login, {}) }), _jsx(Route, { path: "/signup", element: _jsx(SignUp, {}) }), _jsx(Route, { path: "/verify", element: _jsx(VerifyEmail, {}) }), _jsx(Route, { path: "/forgot-password", element: _jsx(ForgotPassword, {}) })] })), _jsx(Route, __assign({ element: _jsx(PendingDeleteRoute, {}) }, { children: _jsx(Route, { path: "/account-deletion", element: _jsx(PendingDelete, {}) }) })), _jsxs(Route, __assign({ element: _jsx(PrivateRoute, {}) }, { children: [_jsx(Route, { path: "/dashboard", element: _jsx(Dashboard, {}) }), _jsx(Route, { path: "/secure", element: _jsx(SecurePage, {}) }), _jsx(Route, { path: "/profile", element: _jsx(Profile, {}) }), _jsx(Route, { path: "/adventures", element: _jsx(ViewAdventures, {}) })] })), _jsx(Route, { path: "*", element: _jsx(NotFound, {}) })] }), _jsx(Snackbar, {})] }) }) })) }));
};
export default App;
