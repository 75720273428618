var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AppBar, Toolbar, Typography, Container, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@contexts/AuthContext';
import { useDispatch } from 'react-redux';
import { logout } from '@store/authSlice';
import ThemeToggle from './ThemeToggle';
import Button from '@mui/material/Button';
var Layout = function (_a) {
    var children = _a.children;
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var isAuthenticated = useAuth().isAuthenticated;
    var currentStatus = sessionStorage.getItem('currentStatus');
    var isPendingDelete = currentStatus === 'PENDING_DELETE';
    var handleLogout = function () {
        dispatch(logout());
        navigate('/login');
    };
    return (_jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', minHeight: '100vh' } }, { children: [_jsx(AppBar, __assign({ position: "static", color: "secondary" }, { children: _jsxs(Toolbar, { children: [_jsx(Typography, __assign({ variant: "h6", component: "div", sx: { flexGrow: 1 } }, { children: _jsx(Link, __assign({ to: "/" }, { children: "Your App Name" })) })), _jsx(Link, __assign({ to: "/open", style: { color: 'white', marginRight: '1rem' } }, { children: "unsecured page" })), isAuthenticated ? (isPendingDelete ? (_jsx(Button, __assign({ variant: "contained", onClick: handleLogout }, { children: "Logout" }))) :
                            _jsxs(_Fragment, { children: [_jsx(Link, __assign({ to: "/dashboard", style: { color: 'white', marginRight: '1rem' } }, { children: "Dashboard" })), _jsx(Link, __assign({ to: "/adventures", style: { color: 'white', marginRight: '1rem' } }, { children: "View Adventures" })), _jsx(Link, __assign({ to: "/profile", style: { color: 'white', marginRight: '1rem' } }, { children: "My Profile" })), _jsx(Button, __assign({ variant: "contained", onClick: handleLogout }, { children: "Logout" }))] })) : (_jsxs(_Fragment, { children: [_jsx(Link, __assign({ to: "/login", style: { color: 'white', marginRight: '1rem' } }, { children: "Login" })), _jsx(Link, __assign({ to: "/signup", style: { color: 'white' } }, { children: "Sign Up" }))] })), _jsx(ThemeToggle, {})] }) })), _jsx(Container, __assign({ component: "main", sx: { mt: 4, mb: 4, flexGrow: 1 } }, { children: children })), _jsx(Box, __assign({ component: "footer", sx: { py: 3, px: 2, mt: 'auto', backgroundColor: 'background.paper' } }, { children: _jsx(Container, __assign({ maxWidth: "sm" }, { children: _jsxs(Typography, __assign({ variant: "body2", color: "text.secondary", align: "center" }, { children: ["\u00A9 ", new Date().getFullYear(), " Your Company Name"] })) })) }))] })));
};
export default Layout;
