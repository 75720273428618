// website/src/utils/mapUtils.ts
import defaultAvatar from '../assets/avatars/default.svg';
export var calculateParticipantBounds = function (locations) {
    if (locations.length === 0)
        return null;
    // console.log('Calculating bounds for locations:', JSON.stringify(locations, null, 2));
    var bounds = {
        north: locations[0].location.latitude,
        south: locations[0].location.latitude,
        east: locations[0].location.longitude,
        west: locations[0].location.longitude
    };
    locations.forEach(function (_a) {
        var location = _a.location;
        bounds.north = Math.max(bounds.north, location.latitude);
        bounds.south = Math.min(bounds.south, location.latitude);
        bounds.east = Math.max(bounds.east, location.longitude);
        bounds.west = Math.min(bounds.west, location.longitude);
    });
    return bounds;
};
export var isLocationInView = function (map, lat, lng) {
    var bounds = map.getBounds();
    return bounds.contains([lng, lat]);
};
export function createAvatarMarker(heading, avatarUrl, userId) {
    // console.log('Creating avatar marker for user:', userId)
    // console.log('using avatar: ', avatarUrl)
    var markerElement = document.createElement('div');
    markerElement.className = 'avatar-marker';
    // Create avatar container
    var avatarContainer = document.createElement('div');
    avatarContainer.style.cssText = "\n        width: 40px;\n        height: 40px;\n        border-radius: 50%;\n        border: 2px solid white;\n        box-shadow: 0 2px 4px rgba(0,0,0,0.3);\n        overflow: hidden;\n        background-color: #cccccc; /* Fallback color */\n        cursor: pointer;\n    ";
    // Create avatar image
    var avatarImg = document.createElement('img');
    avatarImg.style.cssText = "\n        width: 100%;\n        height: 100%;\n        object-fit: cover;\n    ";
    avatarImg.src = avatarUrl || defaultAvatar;
    avatarImg.alt = 'User avatar';
    // Add click handler
    markerElement.addEventListener('click', function () {
        // console.log('Marker clicked for user:', userId);
    });
    // Append elements
    avatarContainer.appendChild(avatarImg);
    markerElement.appendChild(avatarContainer);
    return markerElement;
}
