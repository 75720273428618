var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { checkScreenName, updateScreenName } from '@store/userSlice';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Box, Alert, Typography, CircularProgress } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { logger } from '@utils/logger';
var MIN_LENGTH = 4;
var MAX_LENGTH = 25;
export var ScreenNameChangeModal = function (_a) {
    var open = _a.open, onClose = _a.onClose, currentScreenName = _a.currentScreenName;
    var dispatch = useDispatch();
    // Local state
    var _b = useState(''), newScreenName = _b[0], setNewScreenName = _b[1];
    var _c = useState(null), validationError = _c[0], setValidationError = _c[1];
    var _d = useState(null), error = _d[0], setError = _d[1];
    var _e = useState(false), isSubmitting = _e[0], setIsSubmitting = _e[1];
    var _f = useState(false), isChecking = _f[0], setIsChecking = _f[1];
    var checkTimeoutRef = useRef();
    var _g = useState(null), isNewScreenNameAvailable = _g[0], setIsNewScreenNameAvailable = _g[1];
    // Update state when modal opens or currentScreenName changes
    useEffect(function () {
        if (open) {
            setNewScreenName(currentScreenName);
            setValidationError(null);
            setError(null);
        }
    }, [open, currentScreenName]);
    // Validation handler
    var validateScreenName = function (value) {
        if (value === currentScreenName) {
            setValidationError(null);
            return false;
        }
        if (value.length < MIN_LENGTH) {
            setValidationError("Screen name must be at least ".concat(MIN_LENGTH, " characters"));
            return false;
        }
        //   logger.log(`screen name is greater than ${MAX_LENGTH} characters: ${value.length}`)
        if (value.length > MAX_LENGTH) {
            setValidationError("Screen name cannot exceed ".concat(MAX_LENGTH, " characters"));
            return false;
        }
        // Check for lowercase alphanumeric characters only and no spaces
        var alphanumericRegex = /^[a-z0-9]+$/;
        if (!alphanumericRegex.test(value)) {
            setValidationError('Screen name can only contain lowercase letters and numbers (no spaces)');
            return false;
        }
        setValidationError(null);
        return true;
    };
    // Input change handler
    var handleInputChange = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var value, preValidation, valueBeingChecked;
        return __generator(this, function (_a) {
            value = e.target.value.toLowerCase();
            setNewScreenName(value);
            setIsNewScreenNameAvailable(null);
            preValidation = validateScreenName(value);
            if (!preValidation) {
                setIsChecking(false);
                return [2 /*return*/];
            }
            if (value === currentScreenName) {
                setIsChecking(false);
                return [2 /*return*/];
            }
            if (value.length < MIN_LENGTH) {
                setIsChecking(false);
                setIsNewScreenNameAvailable(null);
                setValidationError("Screen name must be at least ${MIN_LENGTH} characters");
                return [2 /*return*/];
            }
            setIsChecking(true);
            if (checkTimeoutRef.current) {
                clearTimeout(checkTimeoutRef.current);
            }
            valueBeingChecked = value;
            checkTimeoutRef.current = setTimeout(function () { return __awaiter(void 0, void 0, void 0, function () {
                var currentInputValue, response, finalInputValue, error_1, finalInputValue;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            currentInputValue = e.target.value.toLowerCase();
                            // If the current input value is different or below MIN_LENGTH, don't proceed
                            if (currentInputValue !== valueBeingChecked || currentInputValue.length < MIN_LENGTH) {
                                setIsChecking(false);
                                return [2 /*return*/];
                            }
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, checkScreenNameAvailability(valueBeingChecked)];
                        case 2:
                            response = _a.sent();
                            finalInputValue = e.target.value.toLowerCase();
                            if (finalInputValue === valueBeingChecked && finalInputValue.length >= MIN_LENGTH) {
                                setIsChecking(false);
                                setIsNewScreenNameAvailable(response.isAvailable);
                                setValidationError(response.isAvailable ? null : response.message);
                            }
                            return [3 /*break*/, 4];
                        case 3:
                            error_1 = _a.sent();
                            finalInputValue = e.target.value.toLowerCase();
                            if (finalInputValue === valueBeingChecked && finalInputValue.length >= MIN_LENGTH) {
                                setIsChecking(false);
                                setIsNewScreenNameAvailable(false);
                                setValidationError('Unable to check screen name availability');
                            }
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            }); }, 500);
            return [2 /*return*/];
        });
    }); };
    // Clean up timeout on unmount
    useEffect(function () {
        return function () {
            if (checkTimeoutRef.current) {
                clearTimeout(checkTimeoutRef.current);
            }
        };
    }, []);
    var checkScreenNameAvailability = function (screenName) { return __awaiter(void 0, void 0, void 0, function () {
        var result, error_2;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, dispatch(checkScreenName(screenName)).unwrap()];
                case 1:
                    result = _c.sent();
                    logger.log('checkScreenNameAvailability response:', JSON.stringify(result, null, 2));
                    return [2 /*return*/, {
                            isAvailable: result.isAvailable === true,
                            message: result.message || 'Screen name is not available'
                        }];
                case 2:
                    error_2 = _c.sent();
                    logger.log('checkScreenNameAvailability error:', error_2);
                    return [2 /*return*/, {
                            isAvailable: false,
                            message: ((_b = (_a = error_2.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) || 'Unable to verify screen name availability'
                        }];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var lastCheck, newScreenNameResponse;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    // ... rest of submit handler
                    setIsSubmitting(true);
                    setError(null);
                    logger.log('handleSubmit newScreenName:', newScreenName);
                    return [4 /*yield*/, checkScreenNameAvailability(newScreenName)];
                case 1:
                    lastCheck = _a.sent();
                    logger.log('lastCheck:', lastCheck);
                    if (!lastCheck.isAvailable) {
                        setIsSubmitting(false);
                        setError(lastCheck.message);
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, dispatch(updateScreenName(newScreenName)).unwrap()];
                case 2:
                    newScreenNameResponse = _a.sent();
                    setNewScreenName(newScreenName);
                    setValidationError(null);
                    setError(null);
                    setIsNewScreenNameAvailable(null);
                    setIsSubmitting(false);
                    onClose();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleClose = function () {
        setNewScreenName(currentScreenName);
        setValidationError(null);
        setError(null);
        setIsNewScreenNameAvailable(null);
        onClose();
    };
    // Determine if save button should be disabled
    var isSaveDisabled = isSubmitting ||
        newScreenName === currentScreenName ||
        !!validationError ||
        newScreenName.length < MIN_LENGTH ||
        newScreenName.length > MAX_LENGTH
        || isChecking
        || !isNewScreenNameAvailable;
    return (_jsxs(Dialog, __assign({ open: open, onClose: handleClose, maxWidth: "sm", fullWidth: true }, { children: [_jsx(DialogTitle, { children: "Change Screen Name" }), _jsxs("form", __assign({ onSubmit: handleSubmit }, { children: [_jsxs(DialogContent, { children: [error && (_jsx(Alert, __assign({ severity: "error", sx: { mb: 2 } }, { children: error }))), _jsxs(Alert, __assign({ severity: "warning", sx: { mb: 2 } }, { children: [_jsx("strong", { children: "Important:" }), " Changing your screen name will:", _jsxs("ul", { children: [_jsx("li", { children: "Break all existing links to your profile" }), _jsx("li", { children: "Make your content harder to find for users who know your current screen name" }), _jsx("li", { children: "Affect how others can mention or tag you in posts" }), _jsx("li", { children: "Impact your public profile URL if your profile is not private" }), _jsx("li", { children: "Once you change your Screen Name, another user could use your old Screen Name" })] })] })), _jsxs(Box, __assign({ sx: { mb: 3 } }, { children: [_jsx(Typography, __assign({ variant: "subtitle1", gutterBottom: true }, { children: "Screen Name Requirements:" })), _jsxs("ul", { children: [_jsxs("li", { children: ["Minimum ", MIN_LENGTH, " characters"] }), _jsxs("li", { children: ["Maximum ", MAX_LENGTH, " characters"] }), _jsx("li", { children: "Only letters and numbers (no spaces or special characters)" }), _jsx("li", { children: "Will be converted to lowercase" })] })] })), _jsx(Box, __assign({ sx: { mb: 2 } }, { children: _jsx(TextField, { autoFocus: true, fullWidth: true, label: "New Screen Name", name: "screenname-search", autoComplete: "off", value: newScreenName, onChange: handleInputChange, disabled: isSubmitting, error: !!validationError, inputProps: {
                                        maxLength: MAX_LENGTH,
                                        autoComplete: "off" // Ensures nested input element also has autoComplete="off"
                                    }, InputProps: {
                                        startAdornment: '@',
                                        endAdornment: isChecking ? (_jsx(CircularProgress, { size: 20 })) : isNewScreenNameAvailable ? (_jsx(CheckCircle, { color: "success" })) : null
                                    }, helperText: isChecking
                                        ? "Checking availability..."
                                        : isNewScreenNameAvailable
                                            ? null
                                            : validationError || "".concat(newScreenName.length, "/").concat(MAX_LENGTH, " characters") }) })), isNewScreenNameAvailable && (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ color: "success.main" }, { children: "This screen name is available" })), _jsx(Typography, __assign({ variant: "caption", color: "text.secondary", sx: { mt: 1, display: 'block' } }, { children: "By requesting a screen name change you acknowledge that screen names are subject to review. If your screen name is found to be in violation of our policies, it will be reset to a default screen name." }))] }))] }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ onClick: handleClose, disabled: isSubmitting }, { children: "Cancel" })), _jsx(Button, __assign({ type: "submit", variant: "contained", disabled: isSaveDisabled, startIcon: isSubmitting ? _jsx(CircularProgress, { size: 24 }) : null, onClick: handleSubmit }, { children: isSubmitting ? 'Requesting...' : 'Request Screen Name Change' }))] })] }))] })));
};
