var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
// website/src/store/userSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { transformApiResponseToUserProfile, transformUserProfileToApiRequest, } from '../types/user.types';
import { logger } from '@utils/logger';
import ApiConnector from '@utils/apiConnector';
var initialState = {
    user: null,
    cachedProfiles: {},
    isLoading: false,
    isChecking: false,
    error: null,
    emailChangeAttempts: {
        count: 0,
        lastAttempt: 0,
        isBlocked: false
    },
};
// const CACHE_DURATION = 30 * 60 * 1000; // 30 minutes
var repository = 'routy-web';
export var API_ENDPOINTS = {
    USER: {
        ADMIN: '/user-profiles-admin',
        AUTHENTICATED: '/user-profiles-authenticated',
        PUBLIC: '/user-profiles-public' // public - very limited information for public profile
    }
};
// 
var shouldFetchProfile = function (state, userId, endpointType) {
    var _a;
    var cachedProfile = (_a = state.cachedProfiles[userId]) === null || _a === void 0 ? void 0 : _a[endpointType];
    return true;
    // if (!cachedProfile) return true;
    // const now = Date.now();
    // return now - cachedProfile.lastFetched > CACHE_DURATION;
};
export var fetchUserData = createAsyncThunk('user/fetchUserData', function (_a, _b) {
    var userId = _a.userId, endpointType = _a.endpointType;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, apiConnector, endpoint, fetchUserEndpoint, response, profileData, error_1;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    state = getState();
                    // Check cache first
                    if (!shouldFetchProfile(state.user, userId, endpointType)) {
                        // logger.log('fetchUserData: Returning cached profile');
                        return [2 /*return*/, state.user.cachedProfiles[userId][endpointType]];
                    }
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, , 4]);
                    apiConnector = new ApiConnector(function () { return getState(); });
                    endpoint = API_ENDPOINTS.USER[endpointType];
                    // logger.log('fetchUserData endpoint:', endpoint);
                    if (!endpoint)
                        throw new Error('Invalid endpoint type');
                    fetchUserEndpoint = "".concat(endpoint, "?getthisuser=").concat(userId);
                    return [4 /*yield*/, apiConnector.get("".concat(endpoint, "?getthisuser=").concat(userId))];
                case 2:
                    response = _c.sent();
                    // logger.log('fetchUserData response:', response.data);
                    if (response.status !== 200) {
                        throw new Error("Failed to fetch user data: ".concat(response.statusText));
                    }
                    profileData = transformApiResponseToUserProfile(response.data.profile);
                    // logger.log('fetchUserData profileData:', JSON.stringify(profileData,null,2));
                    return [2 /*return*/, profileData];
                case 3:
                    error_1 = _c.sent();
                    return [2 /*return*/, rejectWithValue(error_1 instanceof Error ? error_1.message : 'Failed to fetch user data')];
                case 4: return [2 /*return*/];
            }
        });
    });
});
export var updateUserProfile = createAsyncThunk('user/updateProfile', function (updateRequest, _a) {
    var getState = _a.getState, rejectWithValue = _a.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var flattenedProfile, requestBody, apiConnector, response, error_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    flattenedProfile = transformUserProfileToApiRequest(updateRequest.payload);
                    requestBody = {
                        operation: updateRequest.operation,
                        payload: updateRequest.payload,
                        repository: 'routy-web'
                    };
                    apiConnector = new ApiConnector(function () { return getState(); });
                    return [4 /*yield*/, apiConnector.post('/user-profiles-update', requestBody)];
                case 1:
                    response = _b.sent();
                    // logger.log('updateUserProfile response:', response.data);
                    return [2 /*return*/, response.data];
                case 2:
                    error_2 = _b.sent();
                    return [2 /*return*/, rejectWithValue(error_2 instanceof Error ? error_2.message : 'Failed to update profile')];
                case 3: return [2 /*return*/];
            }
        });
    });
});
export var checkEmailAvailability = createAsyncThunk('user/checkEmailAvailability', function (email, _a) {
    var getState = _a.getState, rejectWithValue = _a.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var requestBody, apiConnector, response, error_3;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    requestBody = {
                        operation: 'CHECK_EMAIL_AVAILABILITY',
                        payload: {
                            email: email
                        },
                        repository: 'routy-web'
                    };
                    apiConnector = new ApiConnector(function () { return getState(); });
                    return [4 /*yield*/, apiConnector.post('/user-profiles-update', requestBody)];
                case 1:
                    response = _b.sent();
                    // logger.log('checkEmailAvailability response:', JSON.stringify(response.data,null,2));
                    return [2 /*return*/, response.data.isInUse];
                case 2:
                    error_3 = _b.sent();
                    return [2 /*return*/, rejectWithValue(error_3 instanceof Error ? error_3.message : 'Failed to check email')];
                case 3: return [2 /*return*/];
            }
        });
    });
});
export var sendEmailVerificationCode = createAsyncThunk('user/sendEmailVerificationCode', function (email, _a) {
    var getState = _a.getState, rejectWithValue = _a.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var requestBody, apiConnector, response, emailChangeVerificationData, error_4;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    requestBody = {
                        operation: 'SEND_VERIFICATION_CODE',
                        payload: {
                            email: email
                        },
                        repository: 'routy-web'
                    };
                    apiConnector = new ApiConnector(function () { return getState(); });
                    return [4 /*yield*/, apiConnector.post('/user-profiles-update', requestBody)];
                case 1:
                    response = _b.sent();
                    emailChangeVerificationData = {
                        responseCode: response.data.responseCode,
                        success: true,
                        message: response.data.message,
                        hash: response.data.hash,
                        timestamp: response.data.timestamp
                    };
                    return [2 /*return*/, emailChangeVerificationData];
                case 2:
                    error_4 = _b.sent();
                    return [2 /*return*/, rejectWithValue(error_4 instanceof Error ? error_4.message : 'Failed to send verification code')];
                case 3: return [2 /*return*/];
            }
        });
    });
});
export var verifyAndUpdateEmail = createAsyncThunk('user/verifyAndUpdateEmail', function (_a, _b) {
    var newEmail = _a.newEmail, verificationCode = _a.verificationCode;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var requestBody, apiConnector, response, error_5;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    requestBody = {
                        operation: 'VERIFY_AND_UPDATE_EMAIL',
                        payload: {
                            email: newEmail,
                            code: verificationCode
                        },
                        repository: 'routy-web'
                    };
                    apiConnector = new ApiConnector(function () { return getState(); });
                    return [4 /*yield*/, apiConnector.post('/user-profiles-update', requestBody)];
                case 1:
                    response = _c.sent();
                    // logger.log('verifyAndUpdateEmail response:', JSON.stringify(response.data,null,2));
                    return [2 /*return*/, response.data];
                case 2:
                    error_5 = _c.sent();
                    return [2 /*return*/, rejectWithValue(error_5 instanceof Error ? error_5.message : 'Failed to verify and update email')];
                case 3: return [2 /*return*/];
            }
        });
    });
});
export var sendAccountDeletionVerificationCode = createAsyncThunk('user/sendAccountDeletionVerificationCode', function (email, _a) {
    var getState = _a.getState, rejectWithValue = _a.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var requestBody, apiConnector, response, accountDeletionVerificationData, error_6;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    requestBody = {
                        operation: 'SEND_DELETION_VERIFICATION_CODE',
                        payload: {
                            email: email
                        },
                        repository: 'routy-web'
                    };
                    apiConnector = new ApiConnector(function () { return getState(); });
                    return [4 /*yield*/, apiConnector.post('/user-profiles-update', requestBody)];
                case 1:
                    response = _b.sent();
                    accountDeletionVerificationData = {
                        responseCode: response.data.responseCode,
                        success: true,
                        message: response.data.message,
                        hash: response.data.hash,
                        timestamp: response.data.timestamp
                    };
                    return [2 /*return*/, accountDeletionVerificationData];
                case 2:
                    error_6 = _b.sent();
                    return [2 /*return*/, rejectWithValue(error_6 instanceof Error ? error_6.message : 'Failed to send account deletion verification code')];
                case 3: return [2 /*return*/];
            }
        });
    });
});
export var verifyAndDeleteAccount = createAsyncThunk('user/verifyAndDeleteAccount', function (_a, _b) {
    var verificationCode = _a.verificationCode;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var requestBody, apiConnector, response, error_7;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    requestBody = {
                        operation: 'VERIFY_AND_DELETE_ACCOUNT',
                        payload: {
                            code: verificationCode
                        },
                        repository: 'routy-web'
                    };
                    apiConnector = new ApiConnector(function () { return getState(); });
                    return [4 /*yield*/, apiConnector.post('/user-profiles-update', requestBody)];
                case 1:
                    response = _c.sent();
                    // logger.log('verifyAndDeleteAccount response:', JSON.stringify(response.data,null,2));
                    return [2 /*return*/, response.data];
                case 2:
                    error_7 = _c.sent();
                    return [2 /*return*/, rejectWithValue(error_7 instanceof Error ? error_7.message : 'Failed to verify and delete account')];
                case 3: return [2 /*return*/];
            }
        });
    });
});
export var sendCancelAccountDeletionVerificationCode = createAsyncThunk('user/sendCancelAccountDeletionVerificationCode', function (email, _a) {
    var getState = _a.getState, rejectWithValue = _a.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var requestBody, apiConnector, response, accountDeletionVerificationData, error_8;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    requestBody = {
                        operation: 'SEND_CANCEL_DELETION_VERIFICATION_CODE',
                        payload: {
                            email: email
                        },
                        repository: 'routy-web'
                    };
                    apiConnector = new ApiConnector(function () { return getState(); });
                    return [4 /*yield*/, apiConnector.post('/user-profiles-update', requestBody)];
                case 1:
                    response = _b.sent();
                    accountDeletionVerificationData = {
                        responseCode: response.data.responseCode,
                        success: true,
                        message: response.data.message,
                        hash: response.data.hash,
                        timestamp: response.data.timestamp
                    };
                    return [2 /*return*/, accountDeletionVerificationData];
                case 2:
                    error_8 = _b.sent();
                    return [2 /*return*/, rejectWithValue(error_8 instanceof Error ? error_8.message : 'Failed to send the cancel account deletion verification code')];
                case 3: return [2 /*return*/];
            }
        });
    });
});
export var verifyAndCancelDeleteAccount = createAsyncThunk('user/verifyAndCancelDeleteAccount', function (_a, _b) {
    var verificationCode = _a.verificationCode;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var requestBody, apiConnector, response, error_9;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    requestBody = {
                        operation: 'VERIFY_AND_CANCEL_DELETE_ACCOUNT',
                        payload: {
                            code: verificationCode
                        },
                        repository: 'routy-web'
                    };
                    apiConnector = new ApiConnector(function () { return getState(); });
                    return [4 /*yield*/, apiConnector.post('/user-profiles-update', requestBody)];
                case 1:
                    response = _c.sent();
                    // logger.log('verifyAndCancelDeleteAccount response:', JSON.stringify(response.data,null,2));
                    if (response.data.responseCode === 'SUCCESS') {
                        sessionStorage.setItem('currentStatus', 'ACTIVE');
                    }
                    return [2 /*return*/, response.data];
                case 2:
                    error_9 = _c.sent();
                    return [2 /*return*/, rejectWithValue(error_9 instanceof Error ? error_9.message : 'Failed to verify and cancel the delete account request')];
                case 3: return [2 /*return*/];
            }
        });
    });
});
export var checkScreenName = createAsyncThunk('user/checkScreenName', function (screenName, _a) {
    var getState = _a.getState, rejectWithValue = _a.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var requestBody, apiConnector, response, error_10;
        var _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _d.trys.push([0, 2, , 3]);
                    requestBody = {
                        operation: 'CHECK_SCREEN_NAME',
                        payload: {
                            screenName: screenName
                        },
                        repository: 'routy-web'
                    };
                    apiConnector = new ApiConnector(function () { return getState(); });
                    return [4 /*yield*/, apiConnector.post('/user-profiles-update', __assign({}, requestBody))];
                case 1:
                    response = _d.sent();
                    logger.log('checkScreenName response:', response.data);
                    return [2 /*return*/, response.data];
                case 2:
                    error_10 = _d.sent();
                    logger.log('checkScreenName error:', error_10);
                    // Check for response data with message
                    if ((_c = (_b = error_10.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message) {
                        return [2 /*return*/, rejectWithValue(error_10.response.data.message)];
                    }
                    // Fallback error message
                    return [2 /*return*/, rejectWithValue('Failed to check screen name')];
                case 3: return [2 /*return*/];
            }
        });
    });
});
export var updateScreenName = createAsyncThunk('user/updateScreenName', function (screenName, _a) {
    var getState = _a.getState, rejectWithValue = _a.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var requestBody, apiConnector, response, error_11;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    requestBody = {
                        operation: 'UPDATE_SCREEN_NAME',
                        payload: {
                            screenName: screenName
                        },
                        repository: 'routy-web'
                    };
                    logger.log('updateScreenName requestBody:', JSON.stringify(requestBody, null, 2));
                    apiConnector = new ApiConnector(function () { return getState(); });
                    return [4 /*yield*/, apiConnector.post('/user-profiles-update', requestBody)];
                case 1:
                    response = _b.sent();
                    logger.log('updateScreenName response:', JSON.stringify(response.data, null, 2));
                    return [2 /*return*/, response.data];
                case 2:
                    error_11 = _b.sent();
                    return [2 /*return*/, rejectWithValue(error_11 instanceof Error ? error_11.message : 'Failed to update screen name')];
                case 3: return [2 /*return*/];
            }
        });
    });
});
var userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        clearUserData: function (state) {
            state.user = null;
            state.error = null;
            state.isLoading = false;
        },
        clearCache: function (state) {
            state.cachedProfiles = {};
        },
        invalidateCache: function (state, action) {
            var _a;
            logger.log('invalidateCache action:', JSON.stringify(action.payload, null, 2));
            var _b = action.payload, userId = _b.userId, endpointType = _b.endpointType;
            if (endpointType) {
                (_a = state.cachedProfiles[userId]) === null || _a === void 0 ? true : delete _a[endpointType];
            }
            else {
                delete state.cachedProfiles[userId];
            }
        },
        updateCache: function (state, action) {
            // logger.debug('updateCache action:', JSON.stringify(action.payload, null, 2));
            var _a = action.payload, userId = _a.userId, endpointType = _a.endpointType, profile = _a.profile;
            // Initialize if needed
            if (!state.cachedProfiles[userId]) {
                state.cachedProfiles[userId] = {};
            }
            // Get existing cached profile or create default structure
            var existingCache = state.cachedProfiles[userId][endpointType] || {
                basic: {
                    email: '',
                    firstName: '',
                    lastName: '',
                    screenName: '',
                    username: '',
                    emailVerified: false,
                    bio: '',
                    phone: '',
                    avatarUrl: '',
                    avatarFileType: '',
                },
                auth: {
                    authType: 'COGNITO',
                    authProvider: 'COGNITO',
                    currentPassword: '',
                    newPassword: '',
                    currentStatus: '',
                },
                settings: {
                    theme: 'light',
                    language: 'en',
                    timezone: 'UTC',
                },
                notifications: {
                    emailNotifications: '',
                    pushNotifications: '',
                },
                location: {
                    country: '',
                    state: '',
                    city: '',
                    latitude: '0',
                    longitude: '0',
                    locationPermission: 'prompt',
                    lastUpdated: '0'
                },
                privacy: {
                    shareCity: '',
                    shareState: '',
                    shareCountry: '',
                    shareCoordinates: '',
                    coordinatePrecision: 'city-level',
                    dataRetention: 'session',
                },
                lastFetched: 0
            };
            // Create updated cache entry with deep merge
            var updatedCache = __assign(__assign(__assign({}, existingCache), profile), { 
                // Deep merge for nested objects
                basic: __assign(__assign({}, existingCache.basic), profile.basic), auth: __assign(__assign({}, existingCache.auth), profile.auth), settings: __assign(__assign({}, existingCache.settings), profile.settings), notifications: __assign(__assign({}, existingCache.notifications), profile.notifications), location: __assign(__assign({}, existingCache.location), profile.location), lastFetched: Date.now() });
            // logger.log('updateCache updatedCache:', JSON.stringify(updatedCache, null, 2))
            // Update the cache
            state.cachedProfiles[userId][endpointType] = updatedCache;
        },
        incrementEmailAttempts: function (state) {
            state.emailChangeAttempts.count++;
            state.emailChangeAttempts.lastAttempt = Date.now();
            if (state.emailChangeAttempts.count >= 3) { // or whatever limit we choose
                state.emailChangeAttempts.isBlocked = true;
                state.emailChangeAttempts.blockEndTime = Date.now() + (30 * 1000); // 30 seconds
            }
        },
        resetEmailAttempts: function (state) {
            state.emailChangeAttempts = {
                count: 0,
                lastAttempt: 0,
                isBlocked: false
            };
        },
        checkBlockStatus: function (state) {
            if (state.emailChangeAttempts.isBlocked &&
                state.emailChangeAttempts.blockEndTime &&
                Date.now() >= state.emailChangeAttempts.blockEndTime) {
                state.emailChangeAttempts = {
                    count: 0,
                    lastAttempt: 0,
                    isBlocked: false
                };
            }
        }
    },
    extraReducers: function (builder) {
        builder
            // Fetch user data cases
            .addCase(fetchUserData.pending, function (state) {
            state.isLoading = true;
            state.error = null;
        })
            .addCase(fetchUserData.fulfilled, function (state, action) {
            var _a, _b;
            state.isLoading = false;
            var _c = action.meta.arg, userId = _c.userId, endpointType = _c.endpointType;
            // console.log('fetchUserData userId:', userId)
            // console.log('fetchUserData endpointType:', endpointType)
            // console.log('fetchUserData action.payload:', action)
            // Update the cache
            if (!state.cachedProfiles[userId]) {
                // console.log('fetchUserData creating new cache entry')
                state.cachedProfiles[userId] = {};
            }
            // console.log('fetchUserData updating cache entry')
            state.cachedProfiles[userId][endpointType] = __assign(__assign({}, action.payload), { lastFetched: Date.now() });
            // console.log('fetchUserData updated cache entry:', JSON.stringify(state.cachedProfiles[userId][endpointType], null, 2));
            //if the fetched userID = the state.user.userId then update the state.user
            if (userId === ((_b = (_a = state.user) === null || _a === void 0 ? void 0 : _a.basic) === null || _b === void 0 ? void 0 : _b.userID)) {
                // console.log('fetchUserData updating state.user')
                state.user = action.payload;
            }
            state.error = null;
        })
            .addCase(fetchUserData.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            // Update profile cases
            .addCase(updateUserProfile.pending, function (state) {
            state.isLoading = true;
            state.error = null;
        })
            .addCase(updateUserProfile.fulfilled, function (state, action) {
            var _a;
            state.isLoading = false;
            if (action.payload.responseCode === 'SUCCESS' && action.payload.profile) {
                // Update the user state
                state.user = __assign(__assign({}, state.user), action.payload.profile);
                // If we have a cognitoUserId, update the cache too
                if ((_a = state.user) === null || _a === void 0 ? void 0 : _a.cognitoUserID) {
                    if (!state.cachedProfiles[state.user.cognitoUserID]) {
                        state.cachedProfiles[state.user.cognitoUserID] = {};
                    }
                    if (state.cachedProfiles[state.user.cognitoUserID]['AUTHENTICATED']) {
                        state.cachedProfiles[state.user.cognitoUserID]['AUTHENTICATED'] = __assign(__assign(__assign({}, state.cachedProfiles[state.user.cognitoUserID]['AUTHENTICATED']), action.payload.profile), { lastFetched: Date.now() });
                    }
                }
            }
            state.error = null;
        })
            .addCase(updateUserProfile.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(checkScreenName.pending, function (state) {
            state.isChecking = true; // You might want a separate loading state for this operation
            state.error = null;
        })
            .addCase(checkScreenName.fulfilled, function (state) {
            state.isChecking = false;
            state.error = null;
        })
            .addCase(checkScreenName.rejected, function (state, action) {
            state.isChecking = false;
            state.error = action.payload;
        })
            .addCase(updateScreenName.pending, function (state) {
            state.isChecking = true; // You might want a separate loading state for this operation
            state.error = null;
        })
            .addCase(updateScreenName.fulfilled, function (state) {
            state.isChecking = false;
            state.error = null;
        })
            .addCase(updateScreenName.rejected, function (state, action) {
            state.isChecking = false;
            state.error = action.payload;
        });
    },
});
export var selectCachedProfile = function (state, userId, endpointType) {
    var _a, _b;
    // logger.log('selectCachedProfile userId:', userId);
    return (_b = (_a = state.user.cachedProfiles) === null || _a === void 0 ? void 0 : _a[userId]) === null || _b === void 0 ? void 0 : _b[endpointType];
    // const cachedData = state.user.cachedProfiles?.[userId]?.[endpointType];
    // if (!cachedData) return undefined;
    // // Check if cache is still valid
    // const now = Date.now();
    // if (now - cachedData.lastFetched > CACHE_DURATION) {
    //   return undefined;
    // }
    // return cachedData;
};
export var selectIsProfileCacheValid = function (state, userId, endpointType) {
    return !shouldFetchProfile(state.user, userId, endpointType);
};
export var clearUserData = (_a = userSlice.actions, _a.clearUserData), clearCache = _a.clearCache, invalidateCache = _a.invalidateCache, updateCache = _a.updateCache, checkBlockStatus = _a.checkBlockStatus;
export default userSlice.reducer;
