// website/src/config/timezoneConfig.ts
export var TIMEZONE_OPTIONS = [
    // UTC/GMT
    { code: 'UTC', label: 'UTC/GMT' },
    // North America
    { code: 'America/New_York', label: 'Eastern Time (ET)' },
    { code: 'America/Chicago', label: 'Central Time (CT)' },
    { code: 'America/Denver', label: 'Mountain Time (MT)' },
    { code: 'America/Phoenix', label: 'Mountain Time - Arizona (MT)' },
    { code: 'America/Los_Angeles', label: 'Pacific Time (PT)' },
    { code: 'America/Anchorage', label: 'Alaska Time (AKT)' },
    { code: 'Pacific/Honolulu', label: 'Hawaii Time (HT)' },
    // Europe
    { code: 'Europe/London', label: 'British Time (BST/GMT)' },
    { code: 'Europe/Paris', label: 'Central European Time (CET)' },
    { code: 'Europe/Helsinki', label: 'Eastern European Time (EET)' },
    // Asia/Pacific
    { code: 'Asia/Tokyo', label: 'Japan Time (JST)' },
    { code: 'Asia/Shanghai', label: 'China Time (CST)' },
    { code: 'Asia/Singapore', label: 'Singapore Time (SGT)' },
    { code: 'Australia/Sydney', label: 'Australian Eastern Time (AET)' },
    // Others
    { code: 'Asia/Dubai', label: 'Gulf Time (GT)' },
    { code: 'Asia/Kolkata', label: 'India Time (IST)' },
];
// Helper function to check if a timezone is supported
export var isSupportedTimezone = function (code) {
    return TIMEZONE_OPTIONS.map(function (tz) { return tz.code; }).includes(code);
};
