// src/i18n/locales/en/index.ts
export default {
    common: {
        buttons: {
            submit: 'Submit',
            cancel: 'Cancel',
            close: 'Close',
            login: 'Log In',
            setNewPassword: 'Set New Password',
            goBack: 'Go Back',
            dashboard: 'Dashboard',
            signIn: 'Sign In' // Added for NotFound        
        },
        navigation: {
            home: 'Home',
            profile: 'Profile'
        },
        devEnvironment: 'Development Environment',
        labels: {
            email: 'Email Address',
            password: 'Password',
            newPassword: 'New Password',
            confirmPassword: 'Confirm Password',
            rememberMe: 'Remember me'
        },
        validation: {
            required: {
                email: 'Email is required',
                password: 'Password is required'
            },
            invalid: {
                email: 'Please enter a valid email address',
                passwordLength: 'Password must be at least 8 characters long',
                passwordsMatch: 'Passwords do not match'
            }
        }
    },
    auth: {
        login: {
            title: 'Login',
            resetPassword: 'Reset Password',
            temporaryPasswordMessage: 'Your current password is temporary. Please create a new password to secure your account. Choose a strong password that you haven\'t used before.',
            forgotPassword: 'Forgot password?',
            noAccount: 'Don\'t have an account? Sign Up',
            or: 'OR',
            socialLogin: {
                facebook: 'Login with Facebook',
                google: 'Login with Google',
                apple: 'Login with Apple',
                amazon: 'Login with Amazon'
            }
        }
    },
    home: {
        title: 'routy',
        subtitle: 'share your adventures',
        logo: {
            alt: 'routy logo'
        }
    },
    preferences: {
        languageAndRegion: 'Language & Region',
        language: 'Language',
        timezone: 'Time Zone',
        timezones: {
            'UTC': 'UTC/GMT',
            'America/New_York': 'Eastern Time (ET)',
            'America/Chicago': 'Central Time (CT)',
            'America/Denver': 'Mountain Time (MT)',
            'America/Phoenix': 'Mountain Time - Arizona',
            'America/Los_Angeles': 'Pacific Time (PT)',
            'America/Anchorage': 'Alaska Time (AKT)',
            'Pacific/Honolulu': 'Hawaii Time (HT)',
            'Europe/London': 'British Time (BST/GMT)',
            'Europe/Paris': 'Central European Time (CET)',
            'Europe/Helsinki': 'Eastern European Time (EET)',
            'Asia/Tokyo': 'Japan Time (JST)',
            'Asia/Shanghai': 'China Time (CST)',
            'Asia/Singapore': 'Singapore Time (SGT)',
            'Australia/Sydney': 'Australian Eastern Time (AET)',
            'Asia/Dubai': 'Gulf Time (GT)',
            'Asia/Kolkata': 'India Time (IST)'
        }
    },
    notFound: {
        messages: {
            vacation: "Oops! Looks like this page took a vacation.",
            houston: "Houston, we have a problem - page not found!",
            void: "Looks like you've wandered into the void.",
            hideSeek: "This page is playing hide and seek (and winning).",
            matrix: "Error 404: Page got lost in the matrix."
        },
        supportText: "Don't worry, it happens to the best of us. Let's get you back on track!"
    }
};
