// src/i18n/locales/es/index.ts
export default {
    common: {
        buttons: {
            submit: 'Enviar',
            cancel: 'Cancelar',
            close: 'Cerrar'
        },
        navigation: {
            home: 'Inicio',
            profile: 'Perfil'
        }
    },
    home: {
        title: 'routy',
        subtitle: 'comparte tus aventuras',
        logo: {
            alt: 'logo de routy'
        }
    },
    preferences: {
        languageAndRegion: 'Idioma y Región',
        language: 'Idioma',
        timezone: 'Zona Horaria',
        timezones: {
            'UTC': 'UTC/GMT',
            'America/New_York': 'Hora del Este (ET)',
            'America/Chicago': 'Hora Central (CT)',
            'America/Denver': 'Hora de Montaña (MT)',
            'America/Phoenix': 'Hora de Montaña - Arizona',
            'America/Los_Angeles': 'Hora del Pacífico (PT)',
            'America/Anchorage': 'Hora de Alaska (AKT)',
            'Pacific/Honolulu': 'Hora de Hawái (HT)',
            'Europe/London': 'Hora Británica (BST/GMT)',
            'Europe/Paris': 'Hora Central Europea (CET)',
            'Europe/Helsinki': 'Hora del Este Europeo (EET)',
            'Asia/Tokyo': 'Hora de Japón (JST)',
            'Asia/Shanghai': 'Hora de China (CST)',
            'Asia/Singapore': 'Hora de Singapur (SGT)',
            'Australia/Sydney': 'Hora del Este de Australia (AET)',
            'Asia/Dubai': 'Hora del Golfo (GT)',
            'Asia/Kolkata': 'Hora de India (IST)'
        }
    }
};
