// src/utils/authUtils.ts
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { logger } from '@utils/logger';
import { AxiosError } from 'axios';
export var setSignupEmail = function (email) {
    localStorage.setItem('signupEmail', email);
};
export var getSignupEmail = function () {
    return localStorage.getItem('signupEmail');
};
export var clearSignupEmail = function () {
    localStorage.removeItem('signupEmail');
};
export var loginToApplication = function (userID, repository, apiConnector) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_1, _a, responseCode, message;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                logger.log('loginToApplication', userID, repository);
                _b.label = 1;
            case 1:
                _b.trys.push([1, 3, , 4]);
                return [4 /*yield*/, apiConnector.post('/auth', {
                        operation: 'LOGIN',
                        payload: {
                            ownerID: userID,
                        },
                        repository: repository
                    })];
            case 2:
                response = _b.sent();
                logger.log('loginToApplication response', JSON.stringify(response, null, 2));
                if (response.data.responseCode === 'SUCCESS') {
                    // Login successful
                    return [2 /*return*/, {
                            success: true,
                            currentStatus: 'ACTIVE',
                            message: response.data.message,
                            profile: response.data.profile
                        }];
                }
                else if (response.data.responseCode === 'USERSUSPENDED') {
                    // User is suspended
                    return [2 /*return*/, {
                            success: false,
                            currentStatus: 'ACCOUNT_SUSPENDED',
                            message: response.data.message,
                            isSuspended: true
                        }];
                }
                else if (response.data.responseCode === 'PENDINGDELETION') {
                    return [2 /*return*/, {
                            success: true,
                            currentStatus: 'PENDING_DELETE',
                            message: response.data.message,
                            profile: response.data.profile
                        }];
                }
                else {
                    // Other error scenarios
                    return [2 /*return*/, {
                            success: false,
                            currentStatus: response.data.responseCode || 'UNKNOWN_ERROR',
                            message: response.data.message || 'An error occurred during login'
                        }];
                }
                return [3 /*break*/, 4];
            case 3:
                error_1 = _b.sent();
                if (error_1 instanceof AxiosError && error_1.response) {
                    _a = error_1.response.data, responseCode = _a.responseCode, message = _a.message;
                    logger.log('loginToApplication responseCode', responseCode);
                    return [2 /*return*/, {
                            success: false,
                            responseCode: responseCode,
                            message: message
                        }];
                }
                else {
                    // Handle non-Axios errors
                    logger.error('Login error:', error_1);
                    return [2 /*return*/, {
                            success: false,
                            responseCode: 'UNKNOWN_ERROR',
                            message: error_1 instanceof Error ? error_1.message : 'An unexpected error occurred'
                        }];
                }
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); };
