// src/hooks/useApiConnector.js

import { useStore } from 'react-redux';
import ApiConnector from '../utils/apiConnector';

let apiConnectorInstance = null;

const useApiConnector = () => {
  const store = useStore();

  if (!apiConnectorInstance) {
    apiConnectorInstance = new ApiConnector(() => store.getState());
  }

  return apiConnectorInstance;
};

export default useApiConnector;