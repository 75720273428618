var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// website/src/pages/ViewAdventures.tsx
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { store } from '@store/index';
import { setActiveEvents, updateEventInList, removeEventFromList, joinEvent, leaveEvent, addEventSpectator, removeEventSpectator, updateSpectatorLastSeen, removeEventParticipant, addEventParticipant } from '@store/eventSlice';
import { fetchUserData } from '@store/userSlice';
import { logger } from '@utils/logger';
import { withLayout } from '@components/withLayout';
import EventsList from '@components/EventsList';
import EventDetails from '@components/events/EventDetails';
import { Box, Container, Typography, Paper, Grid, CircularProgress, Alert, Button, } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
var ViewAdventures = function () {
    var _a = useSelector(function (state) { return state.auth; }), cognitoUserId = _a.cognitoUserId, isAuthenticated = _a.isAuthenticated;
    var dispatch = useDispatch();
    var token = useSelector(function (state) { return state.auth; }).token;
    var _b = useSelector(function (state) { return state.event; }), activeEvents = _b.activeEvents, status = _b.status, error = _b.error;
    var _c = useState('disconnected'), socketStatus = _c[0], setSocketStatus = _c[1];
    var _d = useState(false), isConnected = _d[0], setIsConnected = _d[1];
    var _e = useState(null), socket = _e[0], setSocket = _e[1];
    var _f = useState(0), connectionAttempts = _f[0], setConnectionAttempts = _f[1];
    var _g = useState(null), lastPing = _g[0], setLastPing = _g[1];
    var _h = useState(null), connectionStartTime = _h[0], setConnectionStartTime = _h[1];
    var _j = useState(null), activeAdventureEvent = _j[0], setActiveAdventureEvent = _j[1];
    var _k = useState({}), locations = _k[0], setLocations = _k[1];
    var _l = useState([]), messages = _l[0], setMessages = _l[1];
    useEffect(function () {
        if (token) {
            // dispatch(fetchActiveEvents());
            connectWebSocket();
        }
        return function () {
            if (socket) {
                socket.close();
                setSocket(null);
                setIsConnected(false);
                setConnectionStartTime(null);
                setSocketStatus('disconnected');
            }
        };
    }, [token]);
    // Fetch initial active events
    useEffect(function () {
        // dispatch(fetchActiveEvents());
    }, [dispatch]);
    var connectWebSocket = function () {
        try {
            setConnectionAttempts(function (prev) { return prev + 1; });
            if ((socket === null || socket === void 0 ? void 0 : socket.readyState) === WebSocket.OPEN) {
                // logger.info('WebSocket is already connected.');
                return;
            }
            setSocketStatus('connecting');
            // logger.info('Initiating WebSocket connection...');
            var wsUrl = "wss://beta.sockets.routy.com";
            // logger.info('Connecting to:', wsUrl);
            var ws_1 = new WebSocket("".concat(wsUrl, "?repository=routy-web&Authorization=").concat(token));
            setSocket(ws_1);
            ws_1.onopen = function () {
                // logger.info('WebSocket Connected Successfully');
                setIsConnected(true);
                setConnectionStartTime(new Date());
                setSocketStatus('connected');
                // Subscribe to active-events channel after connection is established
                // logger.info('Subscribing to active-events channel');
                ws_1.send(JSON.stringify({
                    action: 'subscribe',
                    data: {
                        channel: 'active-events'
                    }
                }));
                // Re-subscribe to event channel if there's an active event
                if (activeAdventureEvent) {
                    // logger.info(`Resubscribing to event channel: event/${activeAdventureEvent.PK}`);
                    ws_1.send(JSON.stringify({
                        action: 'subscribe',
                        data: {
                            channel: "event/".concat(activeAdventureEvent.PK)
                        }
                    }));
                }
            };
            ws_1.onmessage = function (event) {
                var _a, _b, _c;
                try {
                    var data_1 = JSON.parse(event.data);
                    // logger.info('WebSocket message received:', data);
                    switch (data_1.type) {
                        case 'SPECTATOR_JOIN':
                            // Handle spectator join event
                            var spectatorJoinPayload = {
                                eventId: data_1.eventId,
                                userId: data_1.payload.userId,
                                joinedAt: data_1.payload.joinedAt,
                                cachedProfiles: store.getState().user.cachedProfiles
                            };
                            dispatch(addEventSpectator(spectatorJoinPayload));
                            break;
                        case 'SPECTATOR_LEAVE':
                            // Handle spectator leave event
                            var spectatorLeavePayload = {
                                eventId: data_1.eventId,
                                userId: data_1.payload.userId
                            };
                            dispatch(removeEventSpectator(spectatorLeavePayload));
                            break;
                        case 'SPECTATOR_HEARTBEAT':
                            // Handle spectator heartbeat event
                            var heartbeatPayload = {
                                eventId: data_1.eventId,
                                userId: data_1.payload.userId,
                                timestamp: new Date().toISOString()
                            };
                            dispatch(updateSpectatorLastSeen(heartbeatPayload));
                            break;
                        case 'SPECTATOR_UPDATE':
                            var spectatorUpdatePayload = {
                                eventId: data_1.eventId,
                                userId: data_1.payload.userId,
                                timestamp: data_1.payload.lastSeen
                            };
                            dispatch(updateSpectatorLastSeen(spectatorUpdatePayload));
                            break;
                        case 'PARTICIPANT_JOIN':
                            // Handle participant join event
                            var participantJoinPayload = {
                                eventId: data_1.eventId,
                                userId: data_1.payload.userId,
                                joinedAt: data_1.payload.joinedAt,
                                status: data_1.payload.status,
                                cachedProfiles: store.getState().user.cachedProfiles
                            };
                            dispatch(addEventParticipant(participantJoinPayload));
                            break;
                        case 'PARTICIPANT_LEAVE':
                            // Handle participant leave event
                            var participantLeavePayload = {
                                eventId: data_1.eventId,
                                userId: data_1.payload.userId
                            };
                            dispatch(removeEventParticipant(participantLeavePayload));
                            break;
                        case 'unsubscribe_confirmed':
                            logger.info('Unsubscribed from channel:', data_1.channel);
                            if (data_1.channel === 'active-events') {
                                logger.info('Unsubscribed from active-events channel');
                            }
                            else if (data_1.channel.startsWith('event/')) {
                                logger.info('Unsubscribed from event channel:', data_1.channel);
                            }
                            break;
                        case 'subscription_confirmed':
                            if (data_1.channel === 'active-events' && ((_a = data_1.data) === null || _a === void 0 ? void 0 : _a.events)) {
                                // logger.info('Received initial active events:', data.data.events);
                                dispatch(setActiveEvents(data_1.data.events));
                            }
                            else if (data_1.channel.startsWith('event/')) {
                                // logger.info('Successfully subscribed to event channel:', data.channel);
                                // setActiveAdventureEvent(data);
                            }
                            break;
                        case 'event_update':
                            dispatch(updateEventInList(data_1.event));
                            break;
                        case 'event_removed':
                            dispatch(removeEventFromList(data_1.eventId));
                            if ((activeAdventureEvent === null || activeAdventureEvent === void 0 ? void 0 : activeAdventureEvent.PK) === data_1.eventId) {
                                setActiveAdventureEvent(null);
                                setLocations({});
                            }
                            break;
                        case 'location_update':
                            // Handle location update
                            // place the most recent location first
                            setLocations(function (prev) {
                                var _a;
                                return (__assign(__assign({}, prev), (_a = {}, _a[data_1.userId] = {
                                    userId: data_1.userId,
                                    timestamp: data_1.timestamp,
                                    location: data_1.location,
                                    eventId: data_1.eventId,
                                    adventureId: data_1.adventureId,
                                    routeId: data_1.routeId,
                                    maxSpeed: data_1.maxSpeed,
                                    maxAltitude: data_1.maxAltitude,
                                    minAltitude: data_1.minAltitude,
                                    odometer: data_1.odometer
                                }, _a)));
                            });
                            // Check if we need to fetch the user's profile
                            var currentState = store.getState();
                            var userProfiles = currentState.user.cachedProfiles;
                            // console.log('data.userId: ', data.userId)
                            var thisUserId = data_1.userId.replace('USER#', '');
                            // console.log("checking for this userId: ", thisUserId)
                            if (!((_b = userProfiles[thisUserId]) === null || _b === void 0 ? void 0 : _b.AUTHENTICATED) && !((_c = userProfiles[thisUserId]) === null || _c === void 0 ? void 0 : _c.PUBLIC)) {
                                // Add a debug message
                                fetchUserProfileAsync(thisUserId);
                            }
                            // Send acknowledgment
                            // ws.send(JSON.stringify({
                            //     action: 'message',
                            //     data: {
                            //         type: 'location_update_ack',
                            //         userId: data.userId,
                            //         timestamp: new Date().toISOString()
                            //     }
                            // }));
                            break;
                        default:
                        // logger.info('Unhandled message type:', data.type);
                    }
                    setLastPing(new Date());
                    setMessages(function (prev) { return __spreadArray([{
                            text: JSON.stringify(data_1, null, 2),
                            timestamp: new Date()
                        }], prev, true); });
                }
                catch (e) {
                    logger.error('Error processing WebSocket message:', e);
                    setMessages(function (prev) { return __spreadArray([{
                            text: event.data,
                            timestamp: new Date()
                        }], prev, true); });
                }
            };
            ws_1.onclose = function (event) {
                // logger.info('WebSocket Connection Closed:', {
                //     code: event.code,
                //     reason: event.reason,
                //     wasClean: event.wasClean,
                //     timestamp: new Date().toISOString()
                // });
                setIsConnected(false);
                setSocketStatus('disconnected');
                if (event.code !== 1000) {
                    setTimeout(function () {
                        logger.info('Attempting to reconnect...');
                        connectWebSocket();
                    }, 5000);
                }
            };
            ws_1.onerror = function (event) {
                // logger.error('WebSocket Error:', {
                //     readyState: ws?.readyState,
                //     url: wsUrl,
                //     timestamp: new Date().toISOString()
                // });
                setIsConnected(false);
                setSocketStatus('error');
            };
        }
        catch (error) {
            // logger.error('WebSocket Connection Failed:', error);
            setIsConnected(false);
            setSocketStatus('error');
        }
    };
    var disconnect = function () {
        if (socket) {
            socket.close();
            setSocket(null);
            setIsConnected(false);
            setConnectionStartTime(null);
            setSocketStatus('disconnected');
            setLocations({});
            setMessages([]);
        }
    };
    var handleLeaveEvent = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!isAuthenticated || !cognitoUserId) {
                        console.error('User not authenticated');
                        return [2 /*return*/];
                    }
                    console.log('Leaving Event:', event);
                    if (!event) {
                        console.error('No active event to leave');
                        return [2 /*return*/];
                    }
                    if ((socket === null || socket === void 0 ? void 0 : socket.readyState) === WebSocket.OPEN) {
                        logger.info("Unsubscribing from event channel: event/".concat(event.PK));
                        socket.send(JSON.stringify({
                            action: 'unsubscribe',
                            data: {
                                channel: "event/".concat(event.PK)
                            }
                        }));
                    }
                    return [4 /*yield*/, dispatch(leaveEvent({
                            eventId: event.eventId,
                            userId: cognitoUserId,
                            role: 'SPECTATOR'
                        }))];
                case 1:
                    _a.sent();
                    setActiveAdventureEvent(null);
                    setLocations({});
                    return [2 /*return*/];
            }
        });
    }); };
    var handleJoinEvent = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var prevEventId;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!isAuthenticated || !cognitoUserId) {
                        console.error('User not authenticated');
                        return [2 /*return*/];
                    }
                    console.log('Joining Event:', event);
                    // add new message to beginning of setMessages instead of the end
                    setMessages(function (prev) { return __spreadArray([{
                            text: "new event selected: ".concat(event.eventId),
                            timestamp: new Date()
                        }], prev, true); });
                    if (!((socket === null || socket === void 0 ? void 0 : socket.readyState) === WebSocket.OPEN)) return [3 /*break*/, 4];
                    if (!activeAdventureEvent) return [3 /*break*/, 2];
                    prevEventId = activeAdventureEvent.PK.split('#')[1];
                    // First, leave the previous event as spectator
                    return [4 /*yield*/, dispatch(leaveEvent({
                            eventId: prevEventId,
                            userId: cognitoUserId,
                            role: 'SPECTATOR'
                        }))];
                case 1:
                    // First, leave the previous event as spectator
                    _a.sent();
                    // logger.info(`Unsubscribing from event channel: event/${prevEventId}`);
                    socket.send(JSON.stringify({
                        action: 'unsubscribe',
                        data: {
                            channel: "event/".concat(prevEventId)
                        }
                    }));
                    _a.label = 2;
                case 2: return [4 /*yield*/, dispatch(joinEvent({
                        eventId: event.eventId,
                        userId: cognitoUserId,
                        role: 'SPECTATOR'
                    }))];
                case 3:
                    _a.sent();
                    //log the new subscription
                    console.log('Subscribing to new event channel:', "event/".concat(event.eventId));
                    console.log('setActiveAdventureEvent: ', event);
                    setActiveAdventureEvent(event);
                    // Subscribe to new event
                    socket.send(JSON.stringify({
                        action: 'subscribe',
                        data: {
                            channel: "event/".concat(event.eventId)
                        }
                    }));
                    _a.label = 4;
                case 4:
                    // Clear previous locations when switching events
                    setLocations({});
                    return [2 /*return*/];
            }
        });
    }); };
    //useEffect to monitor the activeAdventure
    useEffect(function () {
        if (activeAdventureEvent) {
            console.log('activeAdventureEvent changed:', activeAdventureEvent);
        }
    }, [activeAdventureEvent]);
    var fetchUserProfileAsync = function (userId) { return __awaiter(void 0, void 0, void 0, function () {
        var result, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    console.log('Fetching profile for user:', userId);
                    return [4 /*yield*/, dispatch(fetchUserData({
                            userId: userId,
                            endpointType: 'PUBLIC'
                        })).unwrap()];
                case 1:
                    result = _a.sent();
                    // console.log(`Profile fetched successfully for user: ${userId}: `, result)
                    setMessages(function (prev) { return __spreadArray([{
                            text: "Profile fetched successfully for user: ".concat(userId),
                            timestamp: new Date()
                        }], prev, true); });
                    return [2 /*return*/, result];
                case 2:
                    error_1 = _a.sent();
                    setMessages(function (prev) { return __spreadArray([{
                            text: "Error fetching profile for user: ".concat(userId, " - ").concat(error_1),
                            timestamp: new Date()
                        }], prev, true); });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    if (status === 'loading' && !activeEvents.length) {
        return (_jsx(Box, __assign({ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "80vh" }, { children: _jsx(CircularProgress, {}) })));
    }
    return (_jsxs(Container, __assign({ maxWidth: "lg", sx: { mt: 4, mb: 4 } }, { children: [activeAdventureEvent ? (
            // Show event details view
            _jsxs(Box, { children: [_jsx(Button, __assign({ onClick: function () { return handleLeaveEvent(activeAdventureEvent); }, sx: { mb: 2 }, startIcon: _jsx(ArrowBackIcon, {}) }, { children: "Back to Events" })), _jsx(EventDetails, { event: activeAdventureEvent, locations: Object.values(locations), socket: socket })] })) : (_jsxs(_Fragment, { children: [_jsxs(Box, __assign({ sx: { mb: 4 } }, { children: [_jsxs(Grid, __assign({ container: true, spacing: 2, alignItems: "center", justifyContent: "space-between" }, { children: [_jsxs(Grid, __assign({ item: true }, { children: [_jsx(Typography, __assign({ variant: "h4", component: "h1", gutterBottom: true }, { children: "Active Adventures" })), _jsx(Typography, __assign({ variant: "subtitle1", color: "text.secondary" }, { children: "view ongoing adventures" }))] })), _jsx(Grid, __assign({ item: true }, { children: _jsxs(Box, { children: [_jsx(Button, __assign({ variant: "contained", onClick: isConnected ? disconnect : connectWebSocket, color: socketStatus === 'connecting' ? "warning" :
                                                        isConnected ? "error" :
                                                            "primary", disabled: socketStatus === 'connecting', startIcon: socketStatus === 'connecting' ?
                                                        _jsx(CircularProgress, { size: 20, color: "inherit" }) :
                                                        null, sx: { mb: 1 } }, { children: socketStatus === 'connecting' ? "Connecting to routy api..." :
                                                        isConnected ? "Disconnect" :
                                                            "Connect to Live Updates" })), socketStatus === 'connecting' && (_jsxs(Typography, __assign({ variant: "caption", display: "block", color: "text.secondary" }, { children: ["Attempt ", connectionAttempts, ": Establishing connection..."] })))] }) }))] })), error && (_jsx(Alert, __assign({ severity: "error", sx: { mt: 2 } }, { children: error }))), isConnected && (_jsxs(Alert, __assign({ severity: "info", sx: { mt: 2 } }, { children: ["Connected since: ", connectionStartTime === null || connectionStartTime === void 0 ? void 0 : connectionStartTime.toLocaleString(), lastPing && " \u2022 Last update: ".concat(lastPing.toLocaleString())] })))] })), _jsx(Paper, __assign({ sx: { p: 3 } }, { children: activeEvents.length > 0 ? (_jsx(EventsList, { onEventSelect: handleJoinEvent })) : (_jsxs(Box, __assign({ py: 4, textAlign: "center" }, { children: [_jsx(Typography, __assign({ variant: "h6", color: "text.secondary" }, { children: "No active adventures at the moment" })), _jsx(Typography, __assign({ variant: "body2", color: "text.secondary", sx: { mt: 1 } }, { children: "Check back later" }))] }))) }))] })), messages.length > 0 && (_jsxs(Box, __assign({ sx: { mt: 2 } }, { children: [_jsx(Typography, __assign({ variant: "h6" }, { children: "Messages:" })), _jsx(Paper, __assign({ sx: {
                            p: 2,
                            maxHeight: '300px',
                            overflow: 'auto',
                            bgcolor: 'background.paper',
                            border: 1,
                            borderColor: 'divider' // Use theme divider color
                        } }, { children: messages.map(function (msg, index) { return (_jsxs(Box, __assign({ sx: { mb: 1, '&:not(:last-child)': { borderBottom: 1, borderColor: 'divider', pb: 1 } } }, { children: [_jsx(Typography, __assign({ variant: "caption", sx: {
                                        color: 'text.secondary',
                                        display: 'block',
                                        mb: 0.5
                                    } }, { children: msg.timestamp.toLocaleTimeString() })), _jsx("pre", __assign({ style: {
                                        margin: 0,
                                        color: 'inherit',
                                        fontFamily: 'monospace',
                                        fontSize: '0.875rem',
                                        whiteSpace: 'pre-wrap',
                                        wordBreak: 'break-word'
                                    } }, { children: msg.text }))] }), index)); }) })), _jsxs(Box, __assign({ sx: { mt: 1, display: 'flex', gap: 1 } }, { children: [_jsx(Button, __assign({ variant: "outlined", size: "small", onClick: function () { return setMessages([]); } }, { children: "Clear Messages" })), _jsxs(Typography, __assign({ variant: "caption", sx: {
                                    alignSelf: 'center',
                                    color: 'text.secondary' // Use secondary text color
                                } }, { children: [messages.length, " message", messages.length !== 1 ? 's' : ''] }))] }))] })))] })));
};
export default withLayout(ViewAdventures);
