export var checkLocationPermissionStatus = function () {
    return new Promise(function (resolve) {
        if (!navigator.permissions || !navigator.permissions.query) {
            resolve(null);
            return;
        }
        navigator.permissions
            .query({ name: 'geolocation' })
            .then(function (permissionStatus) {
            resolve(permissionStatus.state);
        })
            .catch(function () {
            resolve(null);
        });
    });
};
export var getBrowserLocation = function () {
    return new Promise(function (resolve) {
        // Default values if geolocation fails
        var defaultResult = {
            latitude: 'null',
            longitude: 'null',
            accuracy: 'null'
        };
        // Check if geolocation is supported
        if (!navigator.geolocation) {
            resolve(defaultResult);
            return;
        }
        // Options for getCurrentPosition
        var options = {
            timeout: 5000,
            maximumAge: 0,
            enableHighAccuracy: false
        };
        // Get position
        navigator.geolocation.getCurrentPosition(function (position) {
            resolve({
                latitude: position.coords.latitude.toString(),
                longitude: position.coords.longitude.toString(),
                accuracy: position.coords.accuracy.toString()
            });
        }, 
        // On error, resolve with default values
        function () { return resolve(defaultResult); }, options);
        // Ensure we resolve after timeout
        setTimeout(function () { return resolve(defaultResult); }, options.timeout + 1000);
    });
};
