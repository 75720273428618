import { userPool } from './cognito';
import { logger } from './logger';
// export const refreshToken = (): Promise<string> => {
//   return new Promise((resolve, reject) => {
//     const cognitoUser = userPool.getCurrentUser();
//     if (!cognitoUser) {
//       reject(new Error('No current user'));
//       return;
//     }
//     cognitoUser.getSession((err: Error | null, session: CognitoUserSession | null) => {
//       if (err) {
//         reject(err);
//         return;
//       }
//       if (!session) {
//         reject(new Error('No valid session'));
//         return;
//       }
//       cognitoUser.refreshSession(session.getRefreshToken(), (err, newSession) => {
//         if (err) {
//           reject(err);
//         } else if (newSession) {
//           resolve(newSession.getIdToken().getJwtToken());
//         } else {
//           reject(new Error('Failed to refresh token'));
//         }
//       });
//     });
//   });
// };
export var refreshToken = function () {
    return new Promise(function (resolve, reject) {
        var cognitoUser = userPool.getCurrentUser();
        if (!cognitoUser) {
            logger.error('refreshTokenUtil: No current user');
            reject(new Error('No current user'));
            return;
        }
        cognitoUser.getSession(function (err, session) {
            if (err) {
                logger.error('refreshTokenUtil: getSession error:', err);
                reject(err);
                return;
            }
            if (!session) {
                logger.error('refreshTokenUtil: No valid session');
                reject(new Error('No valid session'));
                return;
            }
            var accessToken = session.getAccessToken();
            var expirationTime = accessToken.getExpiration(); // This is in seconds since epoch
            var now = Math.floor(Date.now() / 1000);
            var timeUntilExpiration = expirationTime - now;
            logger.log('Token expires in:', timeUntilExpiration, 'seconds');
            logger.log('refreshTokenUtil: Attempting to refresh session...');
            cognitoUser.refreshSession(session.getRefreshToken(), function (err, newSession) {
                if (err) {
                    logger.error('refreshTokenUtil: refreshSession error:', err);
                    reject(err);
                }
                else if (newSession) {
                    logger.log('refreshTokenUtil: Session refreshed successfully');
                    resolve(newSession.getIdToken().getJwtToken());
                }
                else {
                    logger.error('refreshTokenUtil: Failed to refresh token - no new session');
                    reject(new Error('Failed to refresh token'));
                }
            });
        });
    });
};
