var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
// website/src/store/authSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { LanguageManager } from '../i18n/languageManager';
import { userPool } from '@utils/cognito';
import { refreshToken as refreshTokenUtil } from '../utils/tokenRefresh';
import { CognitoUser, CognitoUserAttribute, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { logger } from '@utils/logger';
import { loginToApplication } from '@utils/authUtils';
import ApiConnector from '@utils/apiConnector';
import { formatTimeHMS } from '@utils/formatTimeHMS';
import { checkLocationPermissionStatus } from '@utils/geolocation';
var initialState = {
    isAuthenticated: false,
    token: null,
    cognitoUserId: null,
    user: null,
    signUpUsername: null,
    isLoading: false,
    isCheckingAuth: true,
    error: null,
    enableSignups: false,
    currentStatus: 'UNK',
};
export var login = createAsyncThunk('auth/login', function (_a, _b) {
    var email = _a.email, password = _a.password, rememberMe = _a.rememberMe;
    var rejectWithValue = _b.rejectWithValue, getState = _b.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_c) {
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    var authenticationData = {
                        Username: email,
                        Password: password,
                    };
                    var authenticationDetails = new AuthenticationDetails(authenticationData);
                    // logger.log('authSlice: Logging in', { email, rememberMe })
                    var userData = {
                        Username: email,
                        Pool: userPool
                    };
                    var cognitoUser = new CognitoUser(userData);
                    cognitoUser.authenticateUser(authenticationDetails, {
                        onSuccess: function (result) { return __awaiter(void 0, void 0, void 0, function () {
                            var idToken, payload, cognitoUserId, apiConnector, loginResult, userLanguage, error_1;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        idToken = result.getIdToken();
                                        payload = idToken.decodePayload();
                                        cognitoUserId = payload.sub;
                                        if (!cognitoUserId) return [3 /*break*/, 5];
                                        _a.label = 1;
                                    case 1:
                                        _a.trys.push([1, 3, , 4]);
                                        apiConnector = new ApiConnector(function () { return getState(); });
                                        return [4 /*yield*/, loginToApplication(cognitoUserId, 'routy-web', apiConnector)];
                                    case 2:
                                        loginResult = _a.sent();
                                        logger.log('loginResult: ' + JSON.stringify(loginResult, null, 2));
                                        if (loginResult.success) {
                                            userLanguage = LanguageManager.setUserLanguage(loginResult.profile);
                                            logger.log("Language set to: ".concat(userLanguage, " from profile"));
                                            if (rememberMe) {
                                                localStorage.setItem('rememberMe', 'true');
                                            }
                                            else {
                                                localStorage.removeItem('rememberMe');
                                                sessionStorage.setItem('isLoggedIn', 'true');
                                            }
                                            sessionStorage.setItem('currentStatus', loginResult.currentStatus);
                                            resolve({
                                                cognitoUserId: cognitoUserId,
                                                currentStatus: loginResult.currentStatus,
                                                token: idToken.getJwtToken(),
                                                userProfile: loginResult.profile,
                                                rememberMe: rememberMe
                                            });
                                        }
                                        else if (loginResult.isSuspended) {
                                            reject(rejectWithValue('Your account has been suspended. Please contact support.'));
                                        }
                                        else {
                                            reject(rejectWithValue(loginResult.message));
                                        }
                                        return [3 /*break*/, 4];
                                    case 3:
                                        error_1 = _a.sent();
                                        reject(rejectWithValue('An unexpected error occurred. Please try again.'));
                                        return [3 /*break*/, 4];
                                    case 4: return [3 /*break*/, 6];
                                    case 5:
                                        reject(rejectWithValue('Failed to get Cognito User ID'));
                                        _a.label = 6;
                                    case 6: return [2 /*return*/];
                                }
                            });
                        }); },
                        onFailure: function (err) {
                            reject(rejectWithValue('Invalid email or password'));
                        },
                        newPasswordRequired: function (userAttributes, requiredAttributes) {
                            resolve({
                                challengeName: 'NEW_PASSWORD_REQUIRED',
                                cognitoUser: cognitoUser,
                                userAttributes: userAttributes,
                                requiredAttributes: requiredAttributes
                            });
                        }
                    });
                })];
        });
    });
});
export var completeNewPasswordChallenge = createAsyncThunk('auth/completeNewPasswordChallenge', function (_a, _b) {
    var cognitoUser = _a.cognitoUser, newPassword = _a.newPassword;
    var rejectWithValue = _b.rejectWithValue, getState = _b.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_c) {
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    cognitoUser.completeNewPasswordChallenge(newPassword, {}, {
                        onSuccess: function (result) { return __awaiter(void 0, void 0, void 0, function () {
                            var idToken, payload, cognitoUserId, apiConnector, loginResult, error_2;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        idToken = result.getIdToken();
                                        payload = idToken.decodePayload();
                                        cognitoUserId = payload.sub;
                                        if (!cognitoUserId) return [3 /*break*/, 5];
                                        _a.label = 1;
                                    case 1:
                                        _a.trys.push([1, 3, , 4]);
                                        apiConnector = new ApiConnector(function () { return getState(); });
                                        return [4 /*yield*/, loginToApplication(cognitoUserId, 'routy-web', apiConnector)];
                                    case 2:
                                        loginResult = _a.sent();
                                        if (loginResult.success) {
                                            resolve({
                                                cognitoUserId: cognitoUserId,
                                                currentStatus: loginResult.currentStatus,
                                                token: idToken.getJwtToken(),
                                                userProfile: loginResult.profile,
                                                rememberMe: false // Set to false as this is a new login after password change
                                            });
                                        }
                                        else if (loginResult.isSuspended) {
                                            reject(rejectWithValue('Your account has been suspended. Please contact support.'));
                                        }
                                        else {
                                            reject(rejectWithValue(loginResult.message));
                                        }
                                        return [3 /*break*/, 4];
                                    case 3:
                                        error_2 = _a.sent();
                                        reject(rejectWithValue('An unexpected error occurred. Please try again.'));
                                        return [3 /*break*/, 4];
                                    case 4: return [3 /*break*/, 6];
                                    case 5:
                                        reject(rejectWithValue('Failed to get Cognito User ID'));
                                        _a.label = 6;
                                    case 6: return [2 /*return*/];
                                }
                            });
                        }); },
                        onFailure: function (err) {
                            reject(rejectWithValue('Failed to set new password. Please try again.'));
                        }
                    });
                })];
        });
    });
});
export var resendVerificationCode = createAsyncThunk('auth/resendVerificationCode', function (email, _a) {
    var rejectWithValue = _a.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var userData, cognitoUser_1;
        return __generator(this, function (_b) {
            try {
                userData = { Username: email, Pool: userPool };
                cognitoUser_1 = new CognitoUser(userData);
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        cognitoUser_1.resendConfirmationCode(function (err, result) {
                            if (err) {
                                reject(rejectWithValue(err.message || 'Failed to resend verification code'));
                            }
                            else {
                                resolve();
                            }
                        });
                    })];
            }
            catch (error) {
                return [2 /*return*/, rejectWithValue(error instanceof Error ? error.message : 'An unknown error occurred')];
            }
            return [2 /*return*/];
        });
    });
});
export var forgotPassword = createAsyncThunk('auth/forgotPassword', function (email, _a) {
    var rejectWithValue = _a.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_b) {
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    var userData = { Username: email, Pool: userPool };
                    var cognitoUser = new CognitoUser(userData);
                    cognitoUser.forgotPassword({
                        onSuccess: function () { return resolve(); },
                        onFailure: function (err) { return reject(rejectWithValue(err.message || 'An error occurred')); },
                    });
                })];
        });
    });
});
export var resetPassword = createAsyncThunk('auth/resetPassword', function (_a, _b) {
    var email = _a.email, code = _a.code, newPassword = _a.newPassword;
    var rejectWithValue = _b.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_c) {
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    var userData = { Username: email, Pool: userPool };
                    var cognitoUser = new CognitoUser(userData);
                    cognitoUser.confirmPassword(code, newPassword, {
                        onSuccess: function () { return resolve(); },
                        onFailure: function (err) { return reject(rejectWithValue(err.message || 'An error occurred')); },
                    });
                })];
        });
    });
});
export var signUp = createAsyncThunk('auth/signUp', function (_a, _b) {
    var email = _a.email, password = _a.password;
    var rejectWithValue = _b.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var attributeList_1;
        return __generator(this, function (_c) {
            try {
                attributeList_1 = [
                    new CognitoUserAttribute({ Name: 'email', Value: email }),
                    new CognitoUserAttribute({ Name: 'given_name', Value: 'routy' }),
                    new CognitoUserAttribute({ Name: 'family_name', Value: 'User' })
                ];
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        userPool.signUp(email, password, attributeList_1, [], function (err, result) {
                            if (err) {
                                // logger.log('signUp err: '+JSON.stringify(err,null,2))
                                reject(err);
                            }
                            else if (result) {
                                resolve(result.user.getUsername());
                            }
                            else {
                                reject(new Error('Sign up was successful, but no user was returned'));
                            }
                        });
                    })];
            }
            catch (error) {
                return [2 /*return*/, rejectWithValue(error instanceof Error ? error.message : 'An unknown error occurred during sign up')];
            }
            return [2 /*return*/];
        });
    });
});
export var checkAuthStatus = createAsyncThunk('auth/checkStatus', function (_, _a) {
    var rejectWithValue = _a.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_b) {
            //logger.log('authSlice: checkAuthStatus thunk called with stack:', new Error().stack);
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    var cognitoUser = userPool.getCurrentUser();
                    if (cognitoUser) {
                        cognitoUser.getSession(function (err, session) {
                            if (err) {
                                //logger.error('authSlice: Failed to get session:', err);
                                reject(rejectWithValue('Failed to get session'));
                            }
                            else if (session && session.isValid()) {
                                var token = session.getIdToken().getJwtToken();
                                var payload = session.getIdToken().decodePayload();
                                logger.log('authSlice: Valid session found. User is authenticated.');
                                var accessToken = session.getAccessToken();
                                var expirationTime = accessToken.getExpiration(); // This is in seconds since epoch
                                var now = Math.floor(Date.now() / 1000);
                                var timeUntilExpiration = expirationTime - now;
                                logger.log('Token expires in:', formatTimeHMS(timeUntilExpiration));
                                resolve({
                                    isAuthenticated: true,
                                    token: token,
                                    cognitoUserId: payload.sub || null,
                                });
                            }
                            else {
                                //logger.log('authSlice: Session invalid or not found.');
                                resolve({
                                    isAuthenticated: false,
                                    token: null,
                                    cognitoUserId: null,
                                });
                            }
                        });
                    }
                    else {
                        //logger.log('authSlice: No current user found.');
                        resolve({
                            isAuthenticated: false,
                            token: null,
                            cognitoUserId: null,
                        });
                    }
                })];
        });
    });
});
export var refreshToken = createAsyncThunk('auth/refreshToken', function (_, _a) {
    var rejectWithValue = _a.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var newToken, error_3;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, refreshTokenUtil()];
                case 1:
                    newToken = _b.sent();
                    return [2 /*return*/, newToken];
                case 2:
                    error_3 = _b.sent();
                    return [2 /*return*/, rejectWithValue('Failed to refresh token')];
                case 3: return [2 /*return*/];
            }
        });
    });
});
export var verifyEmail = createAsyncThunk('auth/verifyEmail', function (_a, _b) {
    var email = _a.email, code = _a.code;
    var rejectWithValue = _b.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_c) {
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    var userData = { Username: email, Pool: userPool };
                    var cognitoUser = new CognitoUser(userData);
                    cognitoUser.confirmRegistration(code, true, function (err, result) {
                        if (err) {
                            reject(rejectWithValue(err.message || 'Verification failed'));
                        }
                        else {
                            resolve(result);
                        }
                    });
                })];
        });
    });
});
export var logout = createAsyncThunk('auth/logout', function (_, _a) {
    var dispatch = _a.dispatch;
    return __awaiter(void 0, void 0, void 0, function () {
        var cognitoUser;
        return __generator(this, function (_b) {
            cognitoUser = userPool.getCurrentUser();
            if (cognitoUser) {
                cognitoUser.signOut();
            }
            localStorage.removeItem('rememberMe');
            sessionStorage.removeItem('isLoggedIn');
            dispatch(setIsAuthenticated(false));
            dispatch(setCognitoUserId(null));
            //reset the auth token:
            dispatch(resetToken());
            return [2 /*return*/];
        });
    });
});
var authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setIsAuthenticated: function (state, action) {
            state.isAuthenticated = action.payload;
            // logger.log('authSlice: setIsAuthenticated:', action.payload);
        },
        setCognitoUserId: function (state, action) {
            state.cognitoUserId = action.payload;
            //logger.log('authSlice: setCognitoUserId:', action.payload);
        },
        setSignupUsername: function (state, action) {
            state.signUpUsername = action.payload;
            //logger.log('authSlice: setSignupUsername:', action.payload);
        },
        resetToken: function (state) {
            state.token = null;
            //logger.log('authSlice: cleared auth token');
        },
        setAuthError: function (state, action) {
            state.error = action.payload;
        },
        clearAuthError: function (state) {
            state.error = null;
        },
        setEnableSignups: function (state, action) {
            state.enableSignups = action.payload;
        },
    },
    extraReducers: function (builder) {
        builder
            .addCase(login.pending, function (state) {
            state.isLoading = true;
            state.error = null;
        })
            .addCase(login.fulfilled, function (state, action) {
            state.isLoading = false;
            if ('challengeName' in action.payload) {
                // Handle NEW_PASSWORD_REQUIRED case
                state.error = null;
            }
            else {
                // Handle successful login case
                state.isAuthenticated = true;
                state.cognitoUserId = action.payload.cognitoUserId;
                state.token = action.payload.token;
                state.user = action.payload.userProfile;
                // Check and request location permission if needed
                checkLocationPermissionStatus().then(function (permissionStatus) {
                    // Only prompt if permission hasn't been granted or denied before
                    if (permissionStatus === 'prompt') {
                        // Just trigger the permission prompt
                        navigator.geolocation.getCurrentPosition(function () {
                            // Success - permission granted
                            localStorage.setItem('locationPermissionChecked', 'true');
                        }, function () {
                            // Error - permission denied
                            localStorage.setItem('locationPermissionChecked', 'true');
                        }, { timeout: 5000 });
                    }
                });
            }
        })
            .addCase(login.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(logout.fulfilled, function (state) {
            state.isAuthenticated = false;
            state.token = null;
            state.cognitoUserId = null;
            state.user = null;
        })
            .addCase(forgotPassword.pending, function (state) {
            state.isLoading = true;
            state.error = null;
        })
            .addCase(forgotPassword.fulfilled, function (state) {
            state.isLoading = false;
        })
            .addCase(forgotPassword.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(resetPassword.pending, function (state) {
            state.isLoading = true;
            state.error = null;
        })
            .addCase(resetPassword.fulfilled, function (state) {
            state.isLoading = false;
        })
            .addCase(resetPassword.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(signUp.pending, function (state) {
            state.isLoading = true;
            state.error = null;
        })
            .addCase(signUp.fulfilled, function (state, action) {
            state.isLoading = false;
            state.signUpUsername = action.payload;
            // might want to set other state properties here, 
            // such as a flag indicating that email verification is needed
        })
            .addCase(signUp.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(checkAuthStatus.pending, function (state) {
            state.isCheckingAuth = true;
            state.isLoading = true;
            //logger.log('authSlice: Checking auth status: pending');
        })
            .addCase(checkAuthStatus.fulfilled, function (state, action) {
            state.isCheckingAuth = false;
            state.isAuthenticated = action.payload.isAuthenticated;
            state.token = action.payload.token;
            state.cognitoUserId = action.payload.cognitoUserId;
            state.isLoading = false;
            //logger.log('authSlice: Auth status checked:', action.payload);
        })
            .addCase(checkAuthStatus.rejected, function (state, action) {
            state.isCheckingAuth = false;
            state.isAuthenticated = false;
            state.token = null;
            state.cognitoUserId = null;
            state.isLoading = false;
            state.error = action.payload;
            //logger.error('authSlice: Auth status check failed:', action.payload);
        })
            .addCase(refreshToken.fulfilled, function (state, action) {
            state.token = action.payload;
            logger.log('authSlice: Token refreshed. new token: ' + JSON.stringify(action.payload, null, 2));
        })
            .addCase(refreshToken.rejected, function (state) {
            state.isAuthenticated = false;
            state.token = null;
            state.cognitoUserId = null;
            //logger.error('authSlice: Token refresh failed');
        })
            .addCase(verifyEmail.pending, function (state) {
            state.isLoading = true;
            state.error = null;
        })
            .addCase(verifyEmail.fulfilled, function (state) {
            state.isLoading = false;
        })
            .addCase(verifyEmail.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(resendVerificationCode.pending, function (state) {
            state.isLoading = true;
            state.error = null;
        })
            .addCase(resendVerificationCode.fulfilled, function (state) {
            state.isLoading = false;
        })
            .addCase(resendVerificationCode.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(completeNewPasswordChallenge.pending, function (state) {
            state.isLoading = true;
            state.error = null;
        })
            .addCase(completeNewPasswordChallenge.fulfilled, function (state, action) {
            state.isLoading = false;
            state.isAuthenticated = true;
            state.cognitoUserId = action.payload.cognitoUserId;
            state.token = action.payload.token;
            state.user = action.payload.userProfile;
        })
            .addCase(completeNewPasswordChallenge.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});
export var setIsAuthenticated = (_a = authSlice.actions, _a.setIsAuthenticated), setCognitoUserId = _a.setCognitoUserId, setAuthError = _a.setAuthError, clearAuthError = _a.clearAuthError, setEnableSignups = _a.setEnableSignups, resetToken = _a.resetToken, setSignupUsername = _a.setSignupUsername;
export default authSlice.reducer;
