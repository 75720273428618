var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// website/src/pages/profile/tabs/SecurityTab.tsx
import { useState } from 'react';
import { Grid, Typography, TextField, Button, Divider, Paper, List, ListItem, Link, CircularProgress } from '@mui/material';
import { Lock, Delete, CheckCircle } from '@mui/icons-material';
import PasswordStrengthMeter from '@components/PasswordStrengthMeter';
import { validatePassword, getPasswordErrorMessage } from '@utils/passwordUtils';
import { logger } from '@utils/logger';
import { useDispatch } from 'react-redux';
export var SecurityTab = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var isLoading = _a.isLoading, formData = _a.formData, handleInputChange = _a.handleInputChange, handleChangePassword = _a.handleChangePassword, showDeleteAccountModal = _a.showDeleteAccountModal, setShowDeleteAccountModal = _a.setShowDeleteAccountModal;
    var dispatch = useDispatch();
    var _h = useState(false), isChangingPassword = _h[0], setIsChangingPassword = _h[1];
    var _j = useState(''), newPassword = _j[0], setNewPassword = _j[1];
    var _k = useState(false), newPasswordValid = _k[0], setNewPasswordValid = _k[1];
    var _l = useState(''), passwordError = _l[0], setPasswordError = _l[1];
    var _m = useState(''), confirmPassword = _m[0], setConfirmPassword = _m[1];
    var _o = useState(false), confirmPasswordValid = _o[0], setConfirmPasswordValid = _o[1];
    var _p = useState(''), confirmPasswordError = _p[0], setConfirmPasswordError = _p[1];
    // logger.log('formData:', JSON.stringify(formData, null, 2))
    var resetForm = function () {
        setNewPassword('');
        setConfirmPassword('');
        setNewPasswordValid(false);
        setConfirmPasswordValid(false);
        setPasswordError('');
        setConfirmPasswordError('');
        handleInputChange({
            target: { name: 'auth.currentPassword', value: '' }
        });
    };
    var handleNewPasswordValidation = function (e) {
        var thisPassword = e.target.value;
        setNewPassword(thisPassword);
        setNewPasswordValid(false);
        if (!validatePassword(thisPassword)) {
            setPasswordError(getPasswordErrorMessage());
            setConfirmPasswordError('');
            return;
        }
        else if (thisPassword === formData.auth.currentPassword) {
            setPasswordError('New password must be different from current password');
            setConfirmPasswordError('');
            return;
        }
        else {
            setPasswordError('');
            setNewPasswordValid(true);
            handleInputChange({
                target: { name: 'auth.newPassword', value: thisPassword }
            });
        }
        if (thisPassword !== confirmPassword && confirmPassword !== '') {
            setConfirmPasswordError('Passwords do not match');
            return;
        }
        else {
            setConfirmPasswordError('');
        }
    };
    var handleConfirmPasswordValidation = function (e) {
        var thisConfirmPassword = e.target.value;
        setConfirmPassword(thisConfirmPassword);
        setConfirmPasswordValid(false);
        if (thisConfirmPassword !== newPassword) {
            setConfirmPasswordError('Passwords do not match');
            return;
        }
        else {
            setConfirmPasswordError('');
            setConfirmPasswordValid(true);
        }
        if (!validatePassword(thisConfirmPassword)) {
            setConfirmPasswordError(getPasswordErrorMessage());
            setConfirmPasswordValid(false);
            return;
        }
        else {
            setConfirmPasswordError('');
        }
    };
    var handlePasswordUpdate = function () { return __awaiter(void 0, void 0, void 0, function () {
        var updateResponse, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    setIsChangingPassword(true);
                    return [4 /*yield*/, handleChangePassword()];
                case 1:
                    updateResponse = _a.sent();
                    resetForm();
                    return [3 /*break*/, 4];
                case 2:
                    error_1 = _a.sent();
                    logger.error('Error updating password:', error_1);
                    return [3 /*break*/, 4];
                case 3:
                    setIsChangingPassword(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Grid, __assign({ container: true, spacing: 3 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(Typography, __assign({ variant: "h6", gutterBottom: true }, { children: "Change Password" })) })), ((_b = formData === null || formData === void 0 ? void 0 : formData.auth) === null || _b === void 0 ? void 0 : _b.authType) === 'COGNITO' ? (_jsxs(_Fragment, { children: [_jsxs(Grid, __assign({ item: true, xs: 12 }, { children: [_jsx(TextField, { fullWidth: true, type: "password", label: "Current Password", name: "auth.currentPassword", value: formData.auth.currentPassword, onChange: handleInputChange }), _jsxs(_Fragment, { children: [_jsx(TextField, { margin: "normal", required: true, fullWidth: true, name: "newPassword", label: "New Password", type: "password", id: "newPassword", autoComplete: "new-password", value: newPassword, onChange: handleNewPasswordValidation, error: !!passwordError, helperText: passwordError, InputProps: {
                                            startAdornment: '',
                                            endAdornment: newPasswordValid ? (_jsx(CheckCircle, { color: "success" })) : null
                                        } }), newPassword === '' ? null : (newPasswordValid && confirmPasswordValid) ? null : (_jsx(PasswordStrengthMeter, { password: newPassword })), _jsx(TextField, { margin: "normal", required: true, fullWidth: true, name: "confirmPassword", label: "Confirm Password", type: "password", id: "confirmPassword", autoComplete: "new-password", value: confirmPassword, onChange: handleConfirmPasswordValidation, error: !!confirmPasswordError, helperText: confirmPasswordError, InputProps: {
                                            startAdornment: '',
                                            endAdornment: confirmPasswordValid ? (_jsx(CheckCircle, { color: "success" })) : null
                                        } })] })] })), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(Button, __assign({ variant: "contained", color: "primary", startIcon: isChangingPassword ? _jsx(CircularProgress, { size: 24 }) : _jsx(Lock, {}), onClick: handlePasswordUpdate, disabled: isLoading ||
                                !newPassword ||
                                !confirmPassword ||
                                newPassword !== confirmPassword ||
                                !!passwordError ||
                                !!confirmPasswordError }, { children: isChangingPassword ? 'Updating Password...' : 'Update Password' })) }))] })) : (_jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsxs(Paper, __assign({ sx: { p: 3, bgcolor: 'background.default' } }, { children: [_jsxs(Typography, __assign({ variant: "body1", gutterBottom: true }, { children: ["You are signed in using a third-party account (", ((_c = formData === null || formData === void 0 ? void 0 : formData.auth) === null || _c === void 0 ? void 0 : _c.authProvider) || 'Social Login', ")."] })), _jsx(Typography, __assign({ variant: "body2", color: "text.secondary" }, { children: "To change your password, please visit your account settings at your provider's website:" })), _jsx(List, { children: _jsx(ListItem, { children: _jsxs(Link, __assign({ href: ((_d = formData === null || formData === void 0 ? void 0 : formData.auth) === null || _d === void 0 ? void 0 : _d.authProvider) === 'Google' ? 'https://myaccount.google.com/security' :
                                        ((_e = formData === null || formData === void 0 ? void 0 : formData.auth) === null || _e === void 0 ? void 0 : _e.authProvider) === 'Facebook' ? 'https://www.facebook.com/settings?tab=security' :
                                            ((_f = formData === null || formData === void 0 ? void 0 : formData.auth) === null || _f === void 0 ? void 0 : _f.authProvider) === 'Apple' ? 'https://appleid.apple.com/account/manage' :
                                                '#', target: "_blank", rel: "noopener noreferrer" }, { children: ["Go to ", ((_g = formData === null || formData === void 0 ? void 0 : formData.auth) === null || _g === void 0 ? void 0 : _g.authProvider) || 'provider', "'s security settings"] })) }) })] })) }))), _jsxs(Grid, __assign({ item: true, xs: 12 }, { children: [_jsx(Divider, { sx: { my: 3 } }), _jsx(Typography, __assign({ variant: "h6", gutterBottom: true, color: "error" }, { children: "Danger Zone" })), _jsx(Button, __assign({ variant: "outlined", color: "error", startIcon: _jsx(Delete, {}), onClick: function (e) { return setShowDeleteAccountModal(true); }, disabled: isLoading || showDeleteAccountModal }, { children: "Delete Account" }))] }))] })));
};
