var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector, useDispatch } from 'react-redux';
import { Snackbar as MuiSnackbar, Alert } from '@mui/material';
import { hideSnackbar } from '@store/uiSlice';
export var Snackbar = function () {
    var dispatch = useDispatch();
    var _a = useSelector(function (state) { return state.ui.snackbar; }), open = _a.open, message = _a.message, severity = _a.severity, duration = _a.duration;
    var handleClose = function (event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(hideSnackbar());
    };
    return (_jsx(MuiSnackbar, __assign({ open: open, autoHideDuration: duration, onClose: handleClose, 
        //   anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        anchorOrigin: { vertical: 'top', horizontal: 'center' } }, { children: _jsx(Alert, __assign({ onClose: handleClose, severity: severity, sx: { width: '100%' } }, { children: message })) })));
};
