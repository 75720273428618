export var validateEmail = function (email) {
    // First, let's show exactly what we're testing
    // logger.log('Testing email:', email);
    // Break down the parts for debugging
    var localPart = email.split('@')[0];
    var domainPart = email.split('@')[1];
    // logger.log('Local part:', localPart);
    // logger.log('Domain part:', domainPart);
    // Simple regex test
    var re = new RegExp('^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$');
    var isValid = re.test(email);
    // logger.log('Regex pattern:', re.source);
    // logger.log('Is valid:', isValid);
    return isValid;
};
