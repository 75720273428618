var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// website/src/i18n/languageManager.ts
import i18n from 'i18next';
import { logger } from '@utils/logger';
import { isSupportedLanguage } from '../config/languageConfig';
export var LanguageManager = {
    getCurrentLanguage: function () {
        return i18n.language || 'en';
    },
    getBrowserLanguage: function () {
        var browserLang = navigator.language.split('-')[0];
        return isSupportedLanguage(browserLang) ? browserLang : 'en';
    },
    getStoredLanguage: function () {
        try {
            var stored = localStorage.getItem('i18nextLng');
            return isSupportedLanguage(stored || '') ? stored : null;
        }
        catch (error) {
            // Handle incognito mode where localStorage might not be available
            logger.log('Unable to access localStorage, possibly in incognito mode');
            return null;
        }
    },
    setUserLanguage: function (profile) {
        var _a;
        var profileLanguage = (_a = profile === null || profile === void 0 ? void 0 : profile.metadata) === null || _a === void 0 ? void 0 : _a.language;
        var storedLanguage = null;
        try {
            storedLanguage = LanguageManager.getStoredLanguage();
        }
        catch (error) {
            logger.log('Unable to access stored language');
        }
        var browserLanguage = LanguageManager.getBrowserLanguage();
        var languageToUse = profileLanguage || storedLanguage || browserLanguage || 'en';
        logger.log("Setting language: ".concat(languageToUse, " (Profile: ").concat(profileLanguage, ", Stored: ").concat(storedLanguage, ", Browser: ").concat(browserLanguage, ")"));
        if (languageToUse !== i18n.language) {
            i18n.changeLanguage(languageToUse);
        }
        return languageToUse;
    },
    updateLanguage: function (language) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!isSupportedLanguage(language)) {
                        logger.warn("Unsupported language: ".concat(language, ", defaulting to English"));
                        language = 'en';
                    }
                    return [4 /*yield*/, i18n.changeLanguage(language)];
                case 1:
                    _a.sent();
                    try {
                        localStorage.setItem('i18nextLng', language);
                    }
                    catch (error) {
                        logger.log('Unable to store language preference, possibly in incognito mode');
                    }
                    return [2 /*return*/, language];
            }
        });
    }); }
};
