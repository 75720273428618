var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// src/pages/SecurePage.tsx
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserData } from '@store/userSlice';
import { logger } from '@utils/logger';
import { withLayout } from '@components/withLayout';
import { Box, Container, Typography, Paper, Grid, CircularProgress, Alert, Card, CardContent, useTheme } from '@mui/material';
import { BarChart, LineChart, PieChart, SparkLineChart } from '@mui/x-charts';
var SecurePage = function () {
    var theme = useTheme();
    var dispatch = useDispatch();
    var _a = useSelector(function (state) { return state.user; }), user = _a.user, isLoading = _a.isLoading, error = _a.error;
    var token = useSelector(function (state) { return state.auth; }).token;
    // Sample data for charts
    var monthlyData = [
        { month: 'Jan', value: 2400 },
        { month: 'Feb', value: 1398 },
        { month: 'Mar', value: 9800 },
        { month: 'Apr', value: 3908 },
        { month: 'May', value: 4800 },
        { month: 'Jun', value: 3800 },
    ];
    var pieData = [
        { id: 0, value: 35, label: 'Series A' },
        { id: 1, value: 25, label: 'Series B' },
        { id: 2, value: 20, label: 'Series C' },
        { id: 3, value: 20, label: 'Series D' },
    ];
    var sparklineData = [1, 4, 2, 5, 7, 2, 4, 6];
    useEffect(function () {
        if (token && (user === null || user === void 0 ? void 0 : user.id)) {
            logger.info('SecurePage', "Fetching user data for user ID: ".concat(user.id));
            dispatch(fetchUserData({ userId: user.id, endpointType: 'AUTHENTICATED' }));
        }
    }, [dispatch, token, user === null || user === void 0 ? void 0 : user.id]);
    if (isLoading) {
        return (_jsx(Box, __assign({ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "80vh" }, { children: _jsx(CircularProgress, {}) })));
    }
    if (error) {
        return (_jsx(Container, __assign({ maxWidth: "lg", sx: { mt: 4 } }, { children: _jsx(Alert, __assign({ severity: "error" }, { children: error })) })));
    }
    return (_jsxs(Container, __assign({ maxWidth: "lg", sx: { mt: 4, mb: 4 } }, { children: [_jsxs(Paper, __assign({ sx: { p: 3, mb: 3 } }, { children: [_jsx(Typography, __assign({ variant: "h4", gutterBottom: true }, { children: "Secure Analytics Dashboard" })), _jsx(Typography, __assign({ variant: "subtitle1", color: "text.secondary", gutterBottom: true }, { children: "Welcome," }))] })), _jsxs(Grid, __assign({ container: true, spacing: 3 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, md: 8 }, { children: _jsx(Card, { children: _jsxs(CardContent, { children: [_jsx(Typography, __assign({ variant: "h6", gutterBottom: true }, { children: "Monthly Trends" })), _jsx(Box, __assign({ sx: { width: '100%', height: 300 } }, { children: _jsx(LineChart, { xAxis: [{ data: monthlyData.map(function (d) { return d.month; }) }], series: [
                                                {
                                                    data: monthlyData.map(function (d) { return d.value; }),
                                                    area: true,
                                                },
                                            ] }) }))] }) }) })), _jsx(Grid, __assign({ item: true, xs: 12, md: 4 }, { children: _jsx(Card, { children: _jsxs(CardContent, { children: [_jsx(Typography, __assign({ variant: "h6", gutterBottom: true }, { children: "Distribution" })), _jsx(Box, __assign({ sx: { width: '100%', height: 300 } }, { children: _jsx(PieChart, { series: [
                                                {
                                                    data: pieData,
                                                    highlightScope: { faded: 'global', highlighted: 'item' },
                                                    faded: { innerRadius: 30, additionalRadius: -30 },
                                                },
                                            ], height: 300 }) }))] }) }) })), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(Grid, __assign({ container: true, spacing: 3 }, { children: ['Daily Users', 'Conversion Rate', 'Engagement', 'Revenue'].map(function (stat, index) { return (_jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 3 }, { children: _jsx(Card, { children: _jsxs(CardContent, { children: [_jsx(Typography, __assign({ variant: "h6", gutterBottom: true }, { children: stat })), _jsx(Box, __assign({ sx: { width: '100%', height: 60 } }, { children: _jsx(SparkLineChart, { data: sparklineData.map(function (v) { return v * (index + 1); }), height: 60, showTooltip: true, showHighlight: true }) })), _jsxs(Typography, __assign({ variant: "h4", sx: { mt: 1 } }, { children: [Math.floor(Math.random() * 100), "%"] }))] }) }) }), stat)); }) })) })), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(Card, { children: _jsxs(CardContent, { children: [_jsx(Typography, __assign({ variant: "h6", gutterBottom: true }, { children: "Comparative Analysis" })), _jsx(Box, __assign({ sx: { width: '100%', height: 300 } }, { children: _jsx(BarChart, { xAxis: [{ scaleType: 'band', data: monthlyData.map(function (d) { return d.month; }) }], series: [
                                                { data: monthlyData.map(function (d) { return d.value; }) },
                                                { data: monthlyData.map(function (d) { return d.value * 0.7; }) },
                                            ] }) }))] }) }) }))] }))] })));
};
export default withLayout(SecurePage);
