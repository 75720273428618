var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// src/components/AvatarEditorModal.tsx
import React, { useState, useRef, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, IconButton, Stack, Slider } from '@mui/material';
import { PhotoCamera, RotateLeft, RotateRight, ZoomIn, ZoomOut, Delete as DeleteIcon } from '@mui/icons-material';
import AvatarEditor from 'react-avatar-editor';
var AvatarEditorModal = function (_a) {
    var open = _a.open, onClose = _a.onClose, onDelete = _a.onDelete, onSave = _a.onSave, currentAvatarData = _a.currentAvatarData;
    var _b = useState(null), selectedFile = _b[0], setSelectedFile = _b[1];
    var _c = useState(0), rotation = _c[0], setRotation = _c[1];
    var _d = useState(1), scale = _d[0], setScale = _d[1];
    var _e = useState(false), isUploading = _e[0], setIsUploading = _e[1];
    var editorRef = useRef(null);
    var _f = useState(false), isDragging = _f[0], setIsDragging = _f[1];
    // Convert base64 avatar data to blob when component mounts or currentAvatarData changes
    useEffect(function () {
        if ((currentAvatarData === null || currentAvatarData === void 0 ? void 0 : currentAvatarData.data) && !selectedFile) {
            var byteCharacters = atob(currentAvatarData.data);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var blob = new Blob([byteArray], { type: currentAvatarData.contentType });
            var file = new File([blob], 'current-avatar', { type: currentAvatarData.contentType });
            setSelectedFile(file);
        }
    }, [currentAvatarData, open]);
    useEffect(function () {
        var handleKeyPress = function (e) {
            if (!open)
                return; // Only handle events when modal is open
            switch (e.key) {
                case 'ArrowLeft':
                    handleRotateLeft();
                    break;
                case 'ArrowRight':
                    handleRotateRight();
                    break;
                case 'ArrowUp':
                case '+':
                    handleZoomIn();
                    break;
                case 'ArrowDown':
                case '-':
                    handleZoomOut();
                    break;
            }
        };
        window.addEventListener('keydown', handleKeyPress);
        return function () { return window.removeEventListener('keydown', handleKeyPress); };
    }, [open]);
    var handleFileSelect = function (event) {
        var _a;
        var file = (_a = event.target.files) === null || _a === void 0 ? void 0 : _a[0];
        if (file) {
            setSelectedFile(file);
            setRotation(0); // Reset rotation when new file is selected
        }
    };
    var handleRotateLeft = function () {
        setRotation(function (prev) { return prev - 90; });
    };
    var handleRotateRight = function () {
        setRotation(function (prev) { return prev + 90; });
    };
    var handleSave = function () { return __awaiter(void 0, void 0, void 0, function () {
        var canvas_1, blob, editedFile, input, dataTransfer, event_1, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!selectedFile || !editorRef.current)
                        return [2 /*return*/];
                    setIsUploading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, 5, 6]);
                    canvas_1 = editorRef.current.getImageScaledToCanvas();
                    return [4 /*yield*/, new Promise(function (resolve) {
                            canvas_1.toBlob(function (blob) {
                                if (blob)
                                    resolve(blob);
                            }, 'image/jpeg');
                        })];
                case 2:
                    blob = _a.sent();
                    editedFile = new File([blob], selectedFile.name, {
                        type: 'image/jpeg',
                    });
                    input = document.createElement('input');
                    input.type = 'file';
                    dataTransfer = new DataTransfer();
                    dataTransfer.items.add(editedFile);
                    input.files = dataTransfer.files;
                    event_1 = {
                        target: input,
                        currentTarget: input,
                    };
                    return [4 /*yield*/, onSave(event_1)];
                case 3:
                    _a.sent();
                    onClose();
                    return [3 /*break*/, 6];
                case 4:
                    error_1 = _a.sent();
                    console.error('Error uploading avatar:', error_1);
                    return [3 /*break*/, 6];
                case 5:
                    setIsUploading(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var handleZoomChange = function (_event, newValue) {
        setScale(newValue);
    };
    var handleZoomIn = function () {
        setScale(function (prev) { return Math.min(prev + 0.1, 2); });
    };
    var handleZoomOut = function () {
        setScale(function (prev) { return Math.max(prev - 0.1, 1); });
    };
    var handleDragOver = function (e) {
        e.preventDefault();
        e.stopPropagation();
    };
    var handleDrop = function (e) {
        var _a;
        e.preventDefault();
        e.stopPropagation();
        var file = (_a = e.dataTransfer.files) === null || _a === void 0 ? void 0 : _a[0];
        if (file) {
            // Validate file type
            var validTypes = ['image/jpeg', 'image/png', 'image/gif'];
            if (validTypes.includes(file.type)) {
                setSelectedFile(file);
                setRotation(0);
            }
        }
    };
    React.useEffect(function () {
        if (!open) {
            setSelectedFile(null);
            setIsUploading(false);
            setRotation(0);
            setScale(1);
        }
    }, [open]);
    return (_jsxs(Dialog, __assign({ open: open, onClose: onClose, PaperProps: {
            sx: { minWidth: '300px' }
        }, "aria-labelledby": "avatar-editor-title" }, { children: [_jsx(DialogTitle, __assign({ id: "avatar-editor-title" }, { children: "Update Profile Picture" })), _jsx(DialogContent, { children: _jsxs(Box, __assign({ sx: {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2,
                        p: 2
                    }, onDragOver: handleDragOver, onDrop: handleDrop, onDragEnter: function () { return setIsDragging(true); }, onDragLeave: function () { return setIsDragging(false); } }, { children: [_jsx(Box, __assign({ sx: {
                                border: isDragging ? '2px dashed primary.main' : '2px dashed transparent',
                                borderRadius: '8px',
                                p: 2,
                                transition: 'border-color 0.2s ease'
                            } }, { children: selectedFile ? (_jsxs(_Fragment, { children: [_jsx(AvatarEditor, { ref: editorRef, image: selectedFile || "data:".concat(currentAvatarData === null || currentAvatarData === void 0 ? void 0 : currentAvatarData.contentType, ";base64,").concat(currentAvatarData === null || currentAvatarData === void 0 ? void 0 : currentAvatarData.data), width: 200, height: 200, border: 0, borderRadius: 100, rotate: rotation, scale: scale, "aria-label": "Avatar editor" }), _jsxs(Stack, __assign({ direction: "row", spacing: 1, alignItems: "center", sx: { width: '100%', justifyContent: 'center' } }, { children: [_jsx(IconButton, __assign({ onClick: handleRotateLeft, disabled: isUploading, "aria-label": "Rotate left" }, { children: _jsx(RotateLeft, {}) })), _jsx(IconButton, __assign({ onClick: handleRotateRight, disabled: isUploading, "aria-label": "Rotate right" }, { children: _jsx(RotateRight, {}) }))] })), _jsxs(Stack, __assign({ direction: "row", spacing: 2, alignItems: "center", sx: { width: '100%', maxWidth: 250 } }, { children: [_jsx(IconButton, __assign({ onClick: handleZoomOut, disabled: isUploading || scale <= 1, "aria-label": "Zoom out" }, { children: _jsx(ZoomOut, {}) })), _jsx(Slider, { value: scale, min: 1, max: 2, step: 0.1, onChange: handleZoomChange, disabled: isUploading, "aria-label": "Zoom level" }), _jsx(IconButton, __assign({ onClick: handleZoomIn, disabled: isUploading || scale >= 2, "aria-label": "Zoom in" }, { children: _jsx(ZoomIn, {}) }))] }))] })) : (_jsxs(Box, __assign({ sx: {
                                    width: 200,
                                    height: 200,
                                    bgcolor: 'grey.100',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '50%',
                                    mb: 2,
                                    flexDirection: 'column',
                                    gap: 1
                                } }, { children: [_jsx(PhotoCamera, { sx: { color: 'text.secondary', mb: 1 } }), isDragging ?
                                        'Drop image here' :
                                        'Drag an image here or click to browse'] }))) })), _jsx("input", { type: "file", accept: "image/jpeg,image/png,image/gif", style: { display: 'none' }, id: "avatar-input", onChange: handleFileSelect, disabled: isUploading, "aria-label": "Choose profile picture" }), _jsx("label", __assign({ htmlFor: "avatar-input" }, { children: _jsx(Button, __assign({ variant: "contained", component: "span", startIcon: _jsx(PhotoCamera, {}), disabled: isUploading }, { children: "Choose File" })) }))] })) }), _jsxs(DialogActions, { children: [currentAvatarData && (_jsx(Button, __assign({ color: "error", onClick: onDelete, disabled: isUploading, startIcon: _jsx(DeleteIcon, {}) }, { children: "Remove" }))), _jsx(Box, { sx: { flex: 1 } }), " ", _jsx(Button, __assign({ onClick: onClose, disabled: isUploading }, { children: "Cancel" })), _jsx(Button, __assign({ onClick: handleSave, disabled: !selectedFile || isUploading, variant: "contained" }, { children: isUploading ? 'Uploading...' : 'Save' }))] })] })));
};
export default AvatarEditorModal;
