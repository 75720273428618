var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// website/src/pages/protected/profile/tabs/PreferencesTab.tsx
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { Grid, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { logger } from '@utils/logger';
import { SUPPORTED_LANGUAGES } from '../../../../config/languageConfig';
import { TIMEZONE_OPTIONS } from '../../../../config/timezoneConfig';
export var PreferencesTab = function (_a) {
    var _b;
    var formData = _a.formData, handlePreferencesChange = _a.handlePreferencesChange, _c = _a.isSaving, isSaving = _c === void 0 ? false : _c;
    var t = useTranslation().t;
    // logger.log('PreferencesTab', JSON.stringify(formData, null, 2));
    useEffect(function () {
        var _a;
        var currentLanguage = i18n.language;
        var profileLanguage = (_a = formData.settings) === null || _a === void 0 ? void 0 : _a.language;
        if (currentLanguage !== profileLanguage && profileLanguage) {
            logger.log("Initializing language from profile settings: ".concat(profileLanguage));
            i18n.changeLanguage(profileLanguage);
        }
    }, [(_b = formData.settings) === null || _b === void 0 ? void 0 : _b.language]);
    var formHandler = function (field, event) {
        if (field === 'language') {
            i18n.changeLanguage(event);
        }
        handlePreferencesChange(field, event);
    };
    return (_jsxs(Grid, __assign({ container: true, spacing: 3 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(Typography, __assign({ variant: "h6", gutterBottom: true }, { children: t('preferences.languageAndRegion') })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6 }, { children: _jsxs(FormControl, __assign({ fullWidth: true }, { children: [_jsx(InputLabel, { children: t('preferences.language') }), _jsx(Select, __assign({ value: formData.settings.language, onChange: function (e) { return formHandler('language', e.target.value); }, name: "settings.language", disabled: isSaving }, { children: SUPPORTED_LANGUAGES.map(function (lang) { return (_jsx(MenuItem, __assign({ value: lang.code }, { children: lang.nativeName }), lang.code)); }) }))] })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6 }, { children: _jsxs(FormControl, __assign({ fullWidth: true }, { children: [_jsx(InputLabel, { children: "Timezone" }), _jsx(Select, __assign({ value: formData.settings.timezone, onChange: function (e) { return formHandler('timezone', e.target.value); }, name: "settings.timezone", disabled: isSaving }, { children: TIMEZONE_OPTIONS.map(function (tz) { return (_jsx(MenuItem, __assign({ value: tz.code }, { children: t("preferences.timezones.".concat(tz.code), { defaultValue: tz.label }) }), tz.code)); }) }))] })) }))] })));
};
