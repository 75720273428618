// website/src/utils/formatTimeAgo.ts
export function formatTimeAgo(timestamp) {
    var seconds = Math.floor((Date.now() - timestamp) / 1000);
    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor((seconds % 3600) / 60);
    var remainingSeconds = seconds % 60;
    var parts = [];
    if (hours > 0) {
        parts.push("".concat(hours, "h"));
    }
    if (minutes > 0 || hours > 0) {
        parts.push("".concat(minutes, "m"));
    }
    parts.push("".concat(remainingSeconds, "s"));
    return "".concat(parts.join(' '), " ago");
}
