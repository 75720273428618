export var eventStyles = function (theme) { return ({
    container: {
        position: 'relative',
        height: '80vh',
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        p: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 1100,
        backgroundColor: theme.palette.background.paper,
    },
    mapContainer: {
        position: 'relative',
        flex: 1,
        overflow: 'hidden',
        display: 'flex',
    },
    map: function (isCollapsed) { return ({
        height: '100%',
        width: isCollapsed ? '100%' : 'calc(100% - 250px)',
        transition: 'width 0.3s ease',
    }); },
    sidebarContainer: function (isCollapsed) { return ({
        position: 'absolute',
        right: 0,
        top: 0,
        height: '100%',
        width: '250px',
        display: 'flex',
        flexDirection: 'column',
        transform: isCollapsed ? 'translateX(100%)' : 'translateX(0)',
        transition: 'transform 0.3s ease',
        zIndex: 1000,
    }); },
    participantList: function (isExpanded) { return ({
        backgroundColor: theme.palette.background.paper,
        borderLeft: "1px solid ".concat(theme.palette.divider),
        transition: 'all 0.3s ease',
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%',
        height: isExpanded ? 'calc(100% - 48px)' : '48px',
        overflow: 'hidden',
    }); },
    spectatorList: function (isExpanded) { return ({
        backgroundColor: theme.custom.spectatorBackground,
        borderLeft: "1px solid ".concat(theme.palette.divider),
        transition: 'all 0.3s ease',
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        bottom: 0,
        right: 0,
        width: '100%',
        height: isExpanded ? 'calc(100% - 48px)' : '48px',
        overflow: 'hidden',
    }); },
    sectionHeader: {
        height: '48px',
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 2),
        borderBottom: "1px solid ".concat(theme.palette.divider),
        backgroundColor: 'inherit',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    sectionContent: {
        flex: 1,
        overflow: 'auto',
    },
    participantItem: {
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    participantDetail: {
        backgroundColor: theme.palette.background.default,
        borderRadius: 1,
        margin: 1,
        '& .MuiListItem-root': {
            display: 'flex',
            justifyContent: 'space-between',
            py: 1,
        },
        '& .MuiDivider-root': {
            margin: '0 16px',
        },
        '& .MuiTypography-body2': {
            fontSize: '0.75rem',
        },
        '& .MuiTypography-body1': {
            fontWeight: 500,
        },
    },
}); };
