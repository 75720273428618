var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, Typography, FormControlLabel, Switch, } from '@mui/material';
export var NotificationsTab = function (_a) {
    // logger.log('NotificationsTab', JSON.stringify(formData, null, 2));
    var formData = _a.formData, handleNotificationsChange = _a.handleNotificationsChange, isSaving = _a.isSaving;
    var formHandler = function (field, event) {
        handleNotificationsChange(field, (event ?
            'true' : 'false'));
    };
    return (_jsxs(Grid, __assign({ container: true, spacing: 3 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(Typography, __assign({ variant: "h6", gutterBottom: true }, { children: "Notification Settings" })) })), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(FormControlLabel, { control: _jsx(Switch, { checked: formData.notifications.emailNotifications === 'true' ? true : false, onChange: function (e) { return formHandler('emailNotifications', e.target.checked); }, disabled: isSaving }), label: "Email Notifications" }) })), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(FormControlLabel, { control: _jsx(Switch, { checked: formData.notifications.pushNotifications === 'true' ? true : false, onChange: function (e) { return formHandler('pushNotifications', e.target.checked); }, disabled: isSaving }), label: "Push Notifications" }) }))] })));
};
