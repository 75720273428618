var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CircularProgress, Box, List, ListItem, ListItemAvatar, ListItemText, ListItemButton, Paper, Typography } from '@mui/material';
import { getLastSeenTime } from '@utils/eventUtils';
import { useTheme } from '@mui/material/styles';
import { DisplayUserAvatar } from '../DisplayUserAvatar';
import { eventStyles } from './styles';
import { formatTimeAgo } from '@utils/formatTimeAgo';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
var SpectatorList = function (_a) {
    var spectators = _a.spectators, isCollapsed = _a.isCollapsed, isExpanded = _a.isExpanded, onToggleExpand = _a.onToggleExpand, cognitoUserId = _a.cognitoUserId;
    var theme = useTheme();
    var styles = eventStyles(theme);
    var spectatorCount = ((Object.keys(spectators).length) - 1); // we exclude the current user from the count
    // console.log('SpectatorList spectators:', JSON.stringify(spectators,null,2));
    return (_jsxs(Paper, __assign({ sx: styles.spectatorList(isExpanded) }, { children: [_jsxs(Box, __assign({ sx: styles.sectionHeader, onClick: onToggleExpand }, { children: [isExpanded ?
                        _jsx(KeyboardArrowRightIcon, {}) :
                        _jsx(KeyboardArrowUpIcon, {}), _jsxs(Typography, __assign({ variant: "subtitle1" }, { children: ["Spectators (", spectatorCount, ")"] }))] })), _jsx(Box, __assign({ sx: styles.sectionContent }, { children: _jsx(List, { children: Object.entries(spectators)
                        .filter(function (_a) {
                        var userId = _a[0];
                        return userId !== cognitoUserId;
                    })
                        .map(function (_a) {
                        var userId = _a[0], spectator = _a[1];
                        var lastSeenTime = getLastSeenTime(spectator);
                        var isLoading = !spectator.screenName && !spectator.profileData;
                        return (_jsx(ListItem, __assign({ disablePadding: true }, { children: isLoading || spectator.screenName === 'Unknown Spectator' ? (_jsxs(Box, __assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(CircularProgress, { size: 20 }), _jsx(Typography, __assign({ variant: "body2", sx: { ml: 1 } }, { children: "Loading profile..." }))] }))) : (_jsxs(ListItemButton, __assign({ sx: styles.participantItem }, { children: [_jsx(ListItemAvatar, { children: _jsx(DisplayUserAvatar, { profileData: spectator.profileData, size: 30, maxCacheSize: 100, maxCacheAge: 10 }) }), _jsx(ListItemText, { primary: spectator.screenName || 'Unknown Spectator', secondary: "Last seen: ".concat(formatTimeAgo(lastSeenTime)) })] }))) }), userId));
                    }) }) }))] })));
};
export default SpectatorList;
