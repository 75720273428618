var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// src/components/ThemeToggle.tsx
import { IconButton, Tooltip } from '@mui/material';
import { DarkMode, LightMode } from '@mui/icons-material';
import { useColorMode } from '../theme/ColorModeContext';
var ThemeToggle = function () {
    var _a = useColorMode(), mode = _a.mode, toggleColorMode = _a.toggleColorMode;
    return (_jsx(Tooltip, __assign({ title: "Switch to ".concat(mode === 'dark' ? 'light' : 'dark', " mode") }, { children: _jsx(IconButton, __assign({ onClick: toggleColorMode, color: "inherit", sx: { ml: 1 } }, { children: mode === 'dark' ? _jsx(DarkMode, {}) : _jsx(LightMode, {}) })) })));
};
export default ThemeToggle;
