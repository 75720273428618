var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, Typography, FormControlLabel, Switch, Box, Paper, } from '@mui/material';
import { DarkMode, LightMode } from '@mui/icons-material';
import { useColorMode } from '@theme/ColorModeContext';
export var AppearanceTab = function (_a) {
    var isSaving = _a.isSaving, onThemeChange = _a.onThemeChange;
    var mode = useColorMode().mode; // Only get mode, not toggleColorMode
    var isDarkMode = mode === 'dark';
    // Simple handler that only calls the passed function
    var handleThemeToggle = function () {
        onThemeChange(!isDarkMode);
    };
    return (_jsxs(Grid, __assign({ container: true, spacing: 3 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(Typography, __assign({ variant: "h6", gutterBottom: true }, { children: "Theme Settings" })) })), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsxs(Box, __assign({ display: "flex", alignItems: "center", gap: 2 }, { children: [_jsx(LightMode, { color: isDarkMode ? 'disabled' : 'primary' }), _jsx(FormControlLabel, { control: _jsx(Switch, { checked: isDarkMode, onChange: handleThemeToggle, disabled: isSaving }), label: isDarkMode ? "Dark Mode" : "Light Mode" }), _jsx(DarkMode, { color: isDarkMode ? 'primary' : 'disabled' })] })) })), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(Paper, __assign({ elevation: 3, sx: {
                        p: 2,
                        mt: 2,
                        bgcolor: 'background.paper',
                        color: 'text.primary',
                        border: 1,
                        borderColor: 'divider',
                    } }, { children: _jsxs(Typography, __assign({ variant: "body2" }, { children: ["Current theme: ", mode, " mode"] })) })) }))] })));
};
