var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography, LinearProgress, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { getPasswordStrength } from '@utils/passwordUtils';
var PasswordStrengthMeter = function (_a) {
    var password = _a.password;
    var strength = getPasswordStrength(password);
    var color = 'error';
    if (strength === 100)
        color = 'success';
    else if (strength >= 75)
        color = 'warning';
    var requirements = [
        { label: 'At least 8 characters', met: password.length >= 8 },
        { label: 'Contains a number', met: /\d/.test(password) },
        { label: 'Contains an uppercase letter', met: /[A-Z]/.test(password) },
        { label: 'Contains a lowercase letter', met: /[a-z]/.test(password) },
        { label: 'Contains a special character', met: /[!@#\$%^&*(),.?":{}|<>]/.test(password) },
    ];
    return (_jsxs(Box, __assign({ sx: { width: '100%', mt: 1 } }, { children: [_jsx(LinearProgress, { variant: "determinate", value: strength, color: color }), _jsxs(Typography, __assign({ variant: "caption", color: "text.secondary" }, { children: ["Password strength: ", strength === 0 ? 'Very Weak' : strength < 75 ? 'Weak' : strength < 100 ? 'Moderate' : 'Strong'] })), _jsx(List, __assign({ dense: true }, { children: requirements.map(function (req, index) { return (_jsxs(ListItem, { children: [_jsx(ListItemIcon, { children: req.met ? _jsx(CheckIcon, { color: "success" }) : _jsx(CloseIcon, { color: "error" }) }), _jsx(ListItemText, { primary: req.label })] }, index)); }) }))] })));
};
export default PasswordStrengthMeter;
