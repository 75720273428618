// website/src/theme/theme.ts
import { createTheme } from '@mui/material/styles';
export var createAppTheme = function (mode) {
    var palette = {
        mode: mode,
        primary: {
            main: '#1976d2', // stays consistent in both modes
        },
        secondary: {
            main: mode === 'light' ? '#0d3654' : '#90caf9',
        },
        background: {
            default: mode === 'light' ? '#f5f5f5' : '#121212',
            paper: mode === 'light' ? '#ffffff' : '#1e1e1e',
        },
        text: {
            primary: mode === 'light' ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.87)',
            secondary: mode === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
        },
        action: {
            active: mode === 'light' ? 'rgba(0, 0, 0, 0.54)' : 'rgba(255, 255, 255, 0.7)',
            hover: mode === 'light' ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.08)',
            selected: mode === 'light' ? 'rgba(0, 0, 0, 0.08)' : 'rgba(255, 255, 255, 0.16)',
        },
        divider: mode === 'light' ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.12)',
    };
    return createTheme({
        palette: palette,
        custom: {
            spectatorBackground: mode === 'light' ? '#f8fafd' : '#242424',
        },
        components: {
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        backgroundColor: mode === 'light' ? '#0d3654' : '#0d3654',
                    },
                },
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        color: mode === 'light' ? '#1976d2' : '#90caf9',
                    },
                },
            },
        },
    });
};
