export var convertDistanceUnits = function (isMetric, values) {
    // Helper function to safely parse numbers
    var safeParseFloat = function (value) {
        if (typeof value === 'number')
            return value;
        if (!value)
            return 0;
        var parsed = parseFloat(value);
        return isNaN(parsed) ? 0 : parsed;
    };
    // Convert meters to kilometers first
    var distanceKm = safeParseFloat(values.distanceMeters) / 1000;
    if (isMetric) {
        return {
            speed: {
                value: values.speedMs ? values.speedMs * 3.6 : 0,
                unit: 'km/h'
            },
            maxSpeed: {
                value: safeParseFloat(values.maxSpeedMs) * 3.6,
                unit: 'km/h'
            },
            distance: {
                value: distanceKm,
                unit: 'km'
            }
        };
    }
    else {
        return {
            speed: {
                value: values.speedMs ? values.speedMs * 2.237 : 0,
                unit: 'mph'
            },
            maxSpeed: {
                value: safeParseFloat(values.maxSpeedMs) * 2.237,
                unit: 'mph'
            },
            distance: {
                value: distanceKm * 0.621371,
                unit: 'mi'
            }
        };
    }
};
