var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// website/src/components/events/ParticipantList.tsx
import React, { useState } from 'react';
import { CircularProgress, Box, List, ListItem, ListItemAvatar, ListItemText, ListItemButton, Paper, Collapse, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DisplayUserAvatar } from '../DisplayUserAvatar';
import { eventStyles } from './styles';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedParticipant } from '@store/eventSlice';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ParticipantDetail from './ParticipantDetail';
var ParticipantList = function (_a) {
    var participants = _a.participants, locations = _a.locations, onParticipantClick = _a.onParticipantClick, isCollapsed = _a.isCollapsed, isExpanded = _a.isExpanded, onToggleExpand = _a.onToggleExpand;
    var theme = useTheme();
    var styles = eventStyles(theme);
    var dispatch = useDispatch();
    var _b = useState(false), isMetric = _b[0], setIsMetric = _b[1];
    var selectedParticipantId = useSelector(function (state) { return state.event.selectedParticipantId; });
    var participantCount = Object.entries(participants)
        .filter(function (_a) {
        var _ = _a[0], participant = _a[1];
        return participant.status === 'ACCEPTED';
    })
        .length;
    var handleParticipantClick = function (userId) {
        // If clicking the currently selected participant, deselect them
        if (userId === selectedParticipantId) {
            dispatch(setSelectedParticipant(null));
            onParticipantClick(null);
        }
        else {
            dispatch(setSelectedParticipant(userId));
            onParticipantClick(userId);
        }
    };
    return (_jsxs(Paper, __assign({ sx: styles.participantList(isExpanded) }, { children: [_jsxs(Box, __assign({ sx: styles.sectionHeader, onClick: onToggleExpand }, { children: [isExpanded ?
                        _jsx(KeyboardArrowRightIcon, {}) :
                        _jsx(KeyboardArrowDownIcon, {}), _jsxs(Typography, __assign({ variant: "subtitle1" }, { children: ["Participants (", participantCount, ")"] }))] })), _jsx(Box, __assign({ sx: styles.sectionContent }, { children: _jsx(List, { children: Object.entries(participants)
                        .filter(function (_a) {
                        var _ = _a[0], participant = _a[1];
                        return participant.status === 'ACCEPTED';
                    })
                        .map(function (_a) {
                        var userId = _a[0], participant = _a[1];
                        var isLoading = !participant.screenName && !participant.profileData;
                        return (_jsx(React.Fragment, { children: isLoading || participant.screenName === 'Unknown Participant' ? (_jsxs(Box, __assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(CircularProgress, { size: 20 }), _jsx(Typography, __assign({ variant: "body2", sx: { ml: 1 } }, { children: "Loading profile..." }))] }))) : (_jsxs(_Fragment, { children: [_jsx(ListItem, __assign({ disablePadding: true }, { children: _jsxs(ListItemButton, __assign({ onClick: function () { return handleParticipantClick(userId); }, selected: selectedParticipantId === userId, sx: styles.participantItem }, { children: [_jsx(ListItemAvatar, { children: _jsx(DisplayUserAvatar, { profileData: participant.profileData, size: 30, maxCacheSize: 100, maxCacheAge: 10 }) }), _jsx(ListItemText, { primary: participant.screenName || 'Unknown Participant' })] })) })), _jsx(Collapse, __assign({ in: selectedParticipantId === userId, timeout: "auto", unmountOnExit: true }, { children: _jsx(ParticipantDetail, { participant: participant, locations: locations, isMetric: isMetric }) }))] })) }, userId));
                    }) }) }))] })));
};
export default ParticipantList;
