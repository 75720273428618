var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, Typography, FormControl, FormGroup, FormControlLabel, Switch, Select, MenuItem, FormHelperText, } from '@mui/material';
export var PrivacyControls = function (_a) {
    var _b, _c, _d, _e, _f;
    var formData = _a.formData, onChange = _a.onChange, disabled = _a.disabled;
    var handleSwitchChange = function (fieldName) { return function (e) {
        var syntheticEvent = {
            target: {
                name: "privacy.${fieldName}",
                value: e.target.checked ? 'true' : 'false'
            }
        };
        onChange(syntheticEvent);
    }; };
    return (_jsxs(Grid, __assign({ container: true, spacing: 3 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(Typography, __assign({ variant: "h6", gutterBottom: true }, { children: "Location Privacy Settings" })) })), _jsx(Grid, __assign({ item: true, xs: 12, md: 6 }, { children: _jsxs(FormGroup, { children: [_jsx(FormControlLabel, { control: _jsx(Switch, { checked: ((_b = formData.privacy) === null || _b === void 0 ? void 0 : _b.shareCity) === 'true', onChange: handleSwitchChange('shareCity'), disabled: disabled }), label: "Share City" }), _jsx(FormControlLabel, { control: _jsx(Switch, { checked: ((_c = formData.privacy) === null || _c === void 0 ? void 0 : _c.shareState) === 'true', onChange: handleSwitchChange('shareState'), disabled: disabled }), label: "Share State/Province" }), _jsx(FormControlLabel, { control: _jsx(Switch, { checked: ((_d = formData.privacy) === null || _d === void 0 ? void 0 : _d.shareCountry) === 'true', onChange: handleSwitchChange('shareCountry'), disabled: disabled }), label: "Share Country" }), _jsx(FormControlLabel, { control: _jsx(Switch, { checked: ((_e = formData.privacy) === null || _e === void 0 ? void 0 : _e.shareCoordinates) === 'true', onChange: handleSwitchChange('shareCoordinates'), disabled: disabled }), label: "Share Exact Location" })] }) })), _jsx(Grid, __assign({ item: true, xs: 12, md: 6 }, { children: _jsxs(FormControl, __assign({ fullWidth: true, margin: "normal" }, { children: [_jsx(Typography, __assign({ variant: "subtitle2", gutterBottom: true }, { children: "Coordinate Precision" })), _jsxs(Select, __assign({ value: ((_f = formData.privacy) === null || _f === void 0 ? void 0 : _f.coordinatePrecision) || 'city-level', onChange: function (e) {
                                var syntheticEvent = {
                                    target: {
                                        name: 'privacy.coordinatePrecision',
                                        value: e.target.value
                                    }
                                };
                                onChange(syntheticEvent);
                            }, disabled: disabled }, { children: [_jsx(MenuItem, __assign({ value: "exact" }, { children: "Exact Location" })), _jsx(MenuItem, __assign({ value: "approximate" }, { children: "Approximate (1km radius)" })), _jsx(MenuItem, __assign({ value: "city-level" }, { children: "City Level Only" }))] })), _jsx(FormHelperText, { children: "Control how precisely your location is shared with others" })] })) }))] })));
};
