var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
// website/src/store/eventSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logger } from '@utils/logger';
import ApiConnector from '@utils/apiConnector';
// Fetch event details
export var fetchEventDetails = createAsyncThunk('event/fetchDetails', function (eventId, _a) {
    var getState = _a.getState, rejectWithValue = _a.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var apiConnector, response, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    logger.log('fetchEventDetails', eventId);
                    apiConnector = new ApiConnector(function () { return getState(); });
                    return [4 /*yield*/, apiConnector.post('/events', {
                            operation: 'GET_EVENT',
                            payload: { eventId: eventId },
                            repository: 'routy-web'
                        })];
                case 1:
                    response = _b.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_1 = _b.sent();
                    if (error_1 instanceof Error) {
                        return [2 /*return*/, rejectWithValue(error_1.message)];
                    }
                    return [2 /*return*/, rejectWithValue('Failed to fetch event details')];
                case 3: return [2 /*return*/];
            }
        });
    });
});
// Fetch active events
export var fetchActiveEvents = createAsyncThunk('event/fetchActiveEvents', function (_, _a) {
    var getState = _a.getState, rejectWithValue = _a.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var apiConnector, response, error_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    apiConnector = new ApiConnector(function () { return getState(); });
                    return [4 /*yield*/, apiConnector.post('/events', {
                            operation: 'GET_ACTIVE_EVENTS',
                            payload: {},
                            repository: 'routy-web'
                        })];
                case 1:
                    response = _b.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_2 = _b.sent();
                    if (error_2 instanceof Error) {
                        return [2 /*return*/, rejectWithValue(error_2.message)];
                    }
                    return [2 /*return*/, rejectWithValue('Failed to fetch active events')];
                case 3: return [2 /*return*/];
            }
        });
    });
});
// Join event (as participant or spectator)
export var joinEvent = createAsyncThunk('event/join', function (_a, _b) {
    var eventId = _a.eventId, userId = _a.userId, role = _a.role;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var requestBody, apiConnector, response, error_3;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    requestBody = {
                        operation: 'JOIN_EVENT',
                        payload: {
                            eventId: eventId,
                            userId: userId,
                            role: role
                        },
                        repository: 'routy-web'
                    };
                    apiConnector = new ApiConnector(function () { return getState(); });
                    return [4 /*yield*/, apiConnector.post('/events', requestBody, {
                            params: { eventId: eventId, userId: userId }
                        })];
                case 1:
                    response = _c.sent();
                    // console.log('joinEvent response', response.data)
                    return [2 /*return*/, response.data];
                case 2:
                    error_3 = _c.sent();
                    if (error_3 instanceof Error) {
                        return [2 /*return*/, rejectWithValue(error_3.message)];
                    }
                    return [2 /*return*/, rejectWithValue('Failed to join event')];
                case 3: return [2 /*return*/];
            }
        });
    });
});
// Leave event
export var leaveEvent = createAsyncThunk('event/leave', function (_a, _b) {
    var eventId = _a.eventId, userId = _a.userId, role = _a.role;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var apiConnector, response, error_4;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    apiConnector = new ApiConnector(function () { return getState(); });
                    return [4 /*yield*/, apiConnector.post('/events', {
                            operation: 'LEAVE_EVENT',
                            payload: {
                                eventId: eventId,
                                userId: userId,
                                role: role
                            },
                            repository: 'routy-web'
                        })];
                case 1:
                    response = _c.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_4 = _c.sent();
                    if (error_4 instanceof Error) {
                        return [2 /*return*/, rejectWithValue(error_4.message)];
                    }
                    return [2 /*return*/, rejectWithValue('Failed to leave event')];
                case 3: return [2 /*return*/];
            }
        });
    });
});
// Update event status
export var updateEventStatus = createAsyncThunk('event/updateStatus', function (_a, _b) {
    var eventId = _a.eventId, status = _a.status;
    var getState = _b.getState, rejectWithValue = _b.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var apiConnector, response, error_5;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    apiConnector = new ApiConnector(function () { return getState(); });
                    return [4 /*yield*/, apiConnector.post('/events', {
                            operation: 'UPDATE_EVENT_STATUS',
                            payload: {
                                eventId: eventId,
                                status: status
                            },
                            repository: 'routy-web'
                        })];
                case 1:
                    response = _c.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_5 = _c.sent();
                    if (error_5 instanceof Error) {
                        return [2 /*return*/, rejectWithValue(error_5.message)];
                    }
                    return [2 /*return*/, rejectWithValue('Failed to update event status')];
                case 3: return [2 /*return*/];
            }
        });
    });
});
var initialState = {
    currentEvent: null,
    activeEvents: [],
    participants: {},
    spectators: {},
    statistics: {
        totalUpdates: 0,
        updatesByUser: {},
        lastUpdateTimestamp: null,
    },
    status: 'idle',
    error: null,
    eventParticipants: {},
    eventSpectators: {},
    selectedParticipantId: null,
};
var eventSlice = createSlice({
    name: 'event',
    initialState: initialState,
    reducers: {
        setSelectedParticipant: function (state, action) {
            state.selectedParticipantId = action.payload;
        },
        setActiveEvents: function (state, action) {
            state.activeEvents = action.payload;
        },
        setCurrentEvent: function (state, action) {
            state.currentEvent = action.payload;
            // Initialize participants from event data
            Object.entries(action.payload.participants).forEach(function (_a) {
                var userId = _a[0], data = _a[1];
                state.participants[userId] = __assign(__assign({}, data), { totalUpdates: 0 });
            });
        },
        addLocationUpdate: function (state, action) {
            var _a = action.payload, userId = _a.userId, timestamp = _a.timestamp, location = _a.location;
            // Update statistics
            state.statistics.totalUpdates++;
            state.statistics.updatesByUser[userId] =
                (state.statistics.updatesByUser[userId] || 0) + 1;
            state.statistics.lastUpdateTimestamp = timestamp;
        },
        updateEventInList: function (state, action) {
            var index = state.activeEvents.findIndex(function (event) { return event.PK === action.payload.PK; });
            if (index !== -1) {
                state.activeEvents[index] = action.payload;
            }
            else {
                state.activeEvents.push(action.payload);
            }
        },
        removeEventFromList: function (state, action) {
            state.activeEvents = state.activeEvents.filter(function (event) { return event.PK !== action.payload; });
        },
        resetEvent: function (state) {
            return initialState;
        },
        initializeEventParticipants: function (state, action) {
            var _a = action.payload, eventId = _a.eventId, participants = _a.participants, cachedProfiles = _a.cachedProfiles;
            // Initialize if doesn't exist, but preserve existing state
            state.eventParticipants[eventId] = state.eventParticipants[eventId] || {};
            // Add only new participants from event.participants
            Object.entries(participants).forEach(function (_a) {
                var _b, _c;
                var userId = _a[0], participant = _a[1];
                if (!state.eventParticipants[eventId][userId]) {
                    var cachedProfile = ((_b = cachedProfiles[userId]) === null || _b === void 0 ? void 0 : _b.AUTHENTICATED) ||
                        ((_c = cachedProfiles[userId]) === null || _c === void 0 ? void 0 : _c.PUBLIC);
                    state.eventParticipants[eventId][userId] = __assign(__assign({}, participant), { screenName: (cachedProfile === null || cachedProfile === void 0 ? void 0 : cachedProfile.basic.screenName) || participant.screenName, profileData: cachedProfile });
                }
            });
        },
        initializeEventSpectators: function (state, action) {
            var _a = action.payload, eventId = _a.eventId, spectators = _a.spectators, cachedProfiles = _a.cachedProfiles;
            state.eventSpectators[eventId] = state.eventSpectators[eventId] || {};
            Object.entries(spectators).forEach(function (_a) {
                var _b, _c;
                var userId = _a[0], spectator = _a[1];
                if (!state.eventSpectators[eventId][userId]) {
                    var cachedProfile = ((_b = cachedProfiles[userId]) === null || _b === void 0 ? void 0 : _b.AUTHENTICATED) ||
                        ((_c = cachedProfiles[userId]) === null || _c === void 0 ? void 0 : _c.PUBLIC);
                    state.eventSpectators[eventId][userId] = {
                        joinedAt: spectator.joinedAt,
                        lastSeen: spectator.lastSeen,
                        screenName: (cachedProfile === null || cachedProfile === void 0 ? void 0 : cachedProfile.basic.screenName) || 'Unknown Spectator',
                        profileData: cachedProfile
                    };
                }
            });
        },
        addEventSpectator: function (state, action) {
            var _a, _b;
            var _c = action.payload, eventId = _c.eventId, userId = _c.userId, joinedAt = _c.joinedAt, cachedProfiles = _c.cachedProfiles;
            // Initialize the eventSpectators object for this event if it doesn't exist
            state.eventSpectators[eventId] = state.eventSpectators[eventId] || {};
            // Get the cached profile for this user
            var normalizedUserId = userId.replace('USER#', '');
            var cachedProfile = ((_a = cachedProfiles[normalizedUserId]) === null || _a === void 0 ? void 0 : _a.AUTHENTICATED) ||
                ((_b = cachedProfiles[normalizedUserId]) === null || _b === void 0 ? void 0 : _b.PUBLIC);
            // Add or update the spectator
            state.eventSpectators[eventId][normalizedUserId] = {
                joinedAt: joinedAt,
                lastSeen: new Date().toISOString(),
                screenName: (cachedProfile === null || cachedProfile === void 0 ? void 0 : cachedProfile.basic.screenName) || 'Unknown Spectator',
                profileData: cachedProfile
            };
        },
        removeEventSpectator: function (state, action) {
            var _a = action.payload, eventId = _a.eventId, userId = _a.userId;
            var normalizedUserId = userId.replace('USER#', '');
            // Check if the event and user exist before attempting removal
            if (state.eventSpectators[eventId] && state.eventSpectators[eventId][normalizedUserId]) {
                delete state.eventSpectators[eventId][normalizedUserId];
            }
        },
        addEventParticipant: function (state, action) {
            var _a, _b;
            var _c = action.payload, eventId = _c.eventId, userId = _c.userId, joinedAt = _c.joinedAt, status = _c.status, cachedProfiles = _c.cachedProfiles;
            // Initialize the eventParticipants object for this event if it doesn't exist
            state.eventParticipants[eventId] = state.eventParticipants[eventId] || {};
            // Get the cached profile for this user
            var normalizedUserId = userId.replace('USER#', '');
            var cachedProfile = ((_a = cachedProfiles[normalizedUserId]) === null || _a === void 0 ? void 0 : _a.AUTHENTICATED) ||
                ((_b = cachedProfiles[normalizedUserId]) === null || _b === void 0 ? void 0 : _b.PUBLIC);
            // Add or update the participant
            state.eventParticipants[eventId][normalizedUserId] = {
                joinedAt: joinedAt,
                status: status,
                screenName: (cachedProfile === null || cachedProfile === void 0 ? void 0 : cachedProfile.basic.screenName) || 'Unknown Participant',
                profileData: cachedProfile
            };
        },
        removeEventParticipant: function (state, action) {
            var _a = action.payload, eventId = _a.eventId, userId = _a.userId;
            var normalizedUserId = userId.replace('USER#', '');
            // Check if the event and user exist before attempting removal
            if (state.eventParticipants[eventId] && state.eventParticipants[eventId][normalizedUserId]) {
                delete state.eventParticipants[eventId][normalizedUserId];
            }
        },
        updateSpectatorLastSeen: function (state, action) {
            var _a = action.payload, eventId = _a.eventId, userId = _a.userId, timestamp = _a.timestamp;
            var normalizedUserId = userId.replace('USER#', '');
            // Check if the spectator exists before updating
            if (state.eventSpectators[eventId] && state.eventSpectators[eventId][normalizedUserId]) {
                state.eventSpectators[eventId][normalizedUserId] = __assign(__assign({}, state.eventSpectators[eventId][normalizedUserId]), { lastSeen: timestamp });
            }
        },
        updateEventParticipantLocation: function (state, action) {
            var _a, _b;
            var _c = action.payload, eventId = _c.eventId, userId = _c.userId, location = _c.location, additionalData = __rest(_c, ["eventId", "userId", "location"]);
            if (!state.eventParticipants[eventId]) {
                state.eventParticipants[eventId] = {};
            }
            var cachedProfile = ((_a = action.payload.cachedProfiles[userId]) === null || _a === void 0 ? void 0 : _a.AUTHENTICATED) ||
                ((_b = action.payload.cachedProfiles[userId]) === null || _b === void 0 ? void 0 : _b.PUBLIC);
            if (!state.eventParticipants[eventId][userId]) {
                state.eventParticipants[eventId][userId] = {
                    status: 'ACCEPTED',
                    joinedAt: new Date().toISOString(),
                    screenName: cachedProfile === null || cachedProfile === void 0 ? void 0 : cachedProfile.basic.screenName,
                    profileData: cachedProfile
                };
            }
            // Only update if the data has actually changed
            var currentLocation = state.eventParticipants[eventId][userId].lastLocation;
            if (!currentLocation ||
                currentLocation.latitude !== location.latitude ||
                currentLocation.longitude !== location.longitude ||
                currentLocation.heading !== location.heading ||
                currentLocation.speed !== location.speed ||
                currentLocation.altitude !== location.altitude ||
                currentLocation.accuracy !== location.accuracy ||
                currentLocation.timestamp !== additionalData.timestamp ||
                currentLocation.adventureId !== additionalData.adventureId) {
                state.eventParticipants[eventId][userId].lastLocation = __assign(__assign({}, location), { timestamp: additionalData.timestamp, adventureId: additionalData.adventureId, routeId: additionalData.routeId, maxSpeed: additionalData.maxSpeed, maxAltitude: additionalData.maxAltitude, minAltitude: additionalData.minAltitude, odometer: additionalData.odometer });
            }
        }
        // state.eventParticipants[eventId][userId].lastLocation = location;
    },
    extraReducers: function (builder) {
        builder
            // Fetch Event Details
            .addCase(fetchEventDetails.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(fetchEventDetails.fulfilled, function (state, action) {
            state.status = 'succeeded';
            state.currentEvent = action.payload.event;
            // Initialize participants with proper typing
            Object.entries(action.payload.event.participants).forEach(function (_a) {
                var userId = _a[0], participantData = _a[1];
                state.participants[userId] = {
                    status: participantData.status,
                    joinedAt: participantData.joinedAt,
                    completedAt: participantData.completedAt,
                    totalUpdates: 0,
                };
            });
        })
            .addCase(fetchEventDetails.rejected, function (state, action) {
            state.status = 'failed';
            state.error = action.payload;
        })
            // Fetch Active Events
            .addCase(fetchActiveEvents.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(fetchActiveEvents.fulfilled, function (state, action) {
            state.status = 'succeeded';
            state.activeEvents = action.payload.events;
        })
            .addCase(fetchActiveEvents.rejected, function (state, action) {
            state.status = 'failed';
            state.error = action.payload;
        })
            // Join Event
            .addCase(joinEvent.fulfilled, function (state, action) {
            var _a = action.payload, userId = _a.userId, role = _a.role, timestamp = _a.timestamp, participantData = _a.participantData;
            if (role === 'VIEWER') {
                state.spectators[userId] = {
                    joinedAt: timestamp,
                    lastSeen: timestamp,
                };
            }
            else if (participantData && state.currentEvent) {
                state.participants[userId] = __assign(__assign({}, participantData), { totalUpdates: 0 });
                state.currentEvent.participants[userId] = participantData;
            }
        })
            // Leave Event
            .addCase(leaveEvent.fulfilled, function (state, action) {
            var _a;
            var _b = action.payload, userId = _b.userId, role = _b.role;
            if (role === 'VIEWER') {
                delete state.spectators[userId];
            }
            else if ((_a = state.currentEvent) === null || _a === void 0 ? void 0 : _a.participants[userId]) {
                state.currentEvent.participants[userId].status = 'DECLINED';
                if (state.participants[userId]) {
                    state.participants[userId].status = 'DECLINED';
                }
            }
        })
            // Update Event Status
            .addCase(updateEventStatus.fulfilled, function (state, action) {
            if (state.currentEvent) {
                state.currentEvent.status = action.payload.status;
                state.currentEvent.updatedAt = action.payload.timestamp;
            }
        });
    },
});
export var setActiveEvents = (_a = eventSlice.actions, _a.setActiveEvents), setCurrentEvent = _a.setCurrentEvent, addLocationUpdate = _a.addLocationUpdate, updateEventInList = _a.updateEventInList, removeEventFromList = _a.removeEventFromList, resetEvent = _a.resetEvent, initializeEventParticipants = _a.initializeEventParticipants, updateEventParticipantLocation = _a.updateEventParticipantLocation, initializeEventSpectators = _a.initializeEventSpectators, addEventSpectator = _a.addEventSpectator, removeEventSpectator = _a.removeEventSpectator, updateSpectatorLastSeen = _a.updateSpectatorLastSeen, removeEventParticipant = _a.removeEventParticipant, addEventParticipant = _a.addEventParticipant, setSelectedParticipant = _a.setSelectedParticipant;
export default eventSlice.reducer;
