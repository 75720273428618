var SESSION_KEY = 'activitySession';
var SESSION_EXPIRY = 30 * 60 * 1000; // 30 minutes in milliseconds
var generateSessionId = function () {
    return "".concat(Date.now(), "-").concat(Math.random().toString(36).substring(2, 15));
};
var createNewSession = function () {
    var now = Date.now();
    return {
        id: generateSessionId(),
        createdAt: now,
        lastAccessed: now
    };
};
export var getOrCreateSessionId = function () {
    try {
        var storedSession = localStorage.getItem(SESSION_KEY);
        var sessionData = void 0;
        if (storedSession) {
            sessionData = JSON.parse(storedSession);
            var now = Date.now();
            // Check if session has expired
            if (now - sessionData.lastAccessed > SESSION_EXPIRY) {
                // Create new session if expired
                sessionData = createNewSession();
            }
            else {
                // Update last accessed time
                sessionData.lastAccessed = now;
            }
        }
        else {
            // Create new session if none exists
            sessionData = createNewSession();
        }
        // Save updated session data
        localStorage.setItem(SESSION_KEY, JSON.stringify(sessionData));
        return sessionData.id;
    }
    catch (error) {
        // If there's any error (e.g., localStorage unavailable), generate a temporary session
        console.error('Session management error:', error);
        return generateSessionId();
    }
};
export var clearExpiredSession = function () {
    try {
        var storedSession = localStorage.getItem(SESSION_KEY);
        if (storedSession) {
            var sessionData = JSON.parse(storedSession);
            var now = Date.now();
            if (now - sessionData.lastAccessed > SESSION_EXPIRY) {
                localStorage.removeItem(SESSION_KEY);
            }
        }
    }
    catch (error) {
        console.error('Error clearing session:', error);
    }
};
