import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
var PrivateRoute = function () {
    var isAuthenticated = useSelector(function (state) { return state.auth; }).isAuthenticated;
    var location = useLocation();
    var currentStatus = sessionStorage.getItem('currentStatus');
    var isPendingDelete = currentStatus === 'PENDING_DELETE';
    //   logger.log('PrivateRoute: isAuthenticated:', isAuthenticated);
    //   logger.log('PrivateRoute: user:', JSON.stringify(user.profile.auth.currentStatus, null, 2));
    //   logger.log('PrivateRoute: isPendingDelete:', JSON.stringify(isPendingDelete, null, 2));
    //   logger.log('PrivateRoute: sessionStorage.currentStatus:', JSON.stringify(currentStatus, null, 2));
    if (!isAuthenticated) {
        return _jsx(Navigate, { to: "/login", state: { from: location }, replace: true });
    }
    // If user is pending delete, redirect to pending delete page
    if (isPendingDelete) {
        return _jsx(Navigate, { to: "/account-deletion", replace: true });
    }
    return _jsx(Outlet, {});
};
export default PrivateRoute;
