// website/src/utils/passwordUtils.ts
export var validatePassword = function (password) {
    var minLength = 8;
    var hasNumber = /\d/.test(password);
    var hasSpecialChar = /[!@#\$%^&*(),.?":{}|<>]/.test(password);
    var hasUpperCase = /[A-Z]/.test(password);
    var hasLowerCase = /[a-z]/.test(password);
    return (password.length >= minLength &&
        hasNumber &&
        hasSpecialChar &&
        hasUpperCase &&
        hasLowerCase);
};
export var getPasswordErrorMessage = function () {
    return "Password must be at least 8 characters long and contain at least 1 number, 1 special character, 1 uppercase letter, and 1 lowercase letter.";
};
export var getPasswordStrength = function (password) {
    var strength = 0;
    if (password.length >= 8)
        strength += 20;
    if (/\d/.test(password))
        strength += 20;
    if (/[A-Z]/.test(password))
        strength += 20;
    if (/[a-z]/.test(password))
        strength += 20;
    if (/[!@#\$%^&*(),.?":{}|<>]/.test(password))
        strength += 20;
    return strength;
};
