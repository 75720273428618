var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import EventCard from './EventCard';
var EventsList = function (_a) {
    var onEventSelect = _a.onEventSelect;
    var _b = useSelector(function (state) { return state.event; }), activeEvents = _b.activeEvents, status = _b.status, error = _b.error;
    if (status === 'loading') {
        return _jsx("div", { children: "Loading events..." });
    }
    if (error) {
        return _jsxs("div", { children: ["Error loading events: ", error] });
    }
    return (_jsx(Grid, __assign({ container: true, spacing: 2 }, { children: activeEvents.map(function (event) { return (_jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(EventCard, { event: event, onEventSelect: onEventSelect }) }), event.PK)); }) })));
};
export default EventsList;
