var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// src/components/modals/EmailChangeModal.tsx
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../hooks';
import { logger } from '@utils/logger';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { validateEmail } from '@utils/validateEmail';
import { checkEmailAvailability, sendEmailVerificationCode, verifyAndUpdateEmail } from '@store/userSlice';
import { showSnackbar } from '@store/uiSlice';
import { logout } from '@store/authSlice';
import crypto from 'crypto-js';
import { selectCachedProfile, checkBlockStatus } from '@store/userSlice';
export var EmailChangeModal = function (_a) {
    var open = _a.open, onClose = _a.onClose, currentEmail = _a.currentEmail;
    var dispatch = useAppDispatch();
    var _b = useState(''), newEmail = _b[0], setNewEmail = _b[1];
    var _c = useState(false), isChecking = _c[0], setIsChecking = _c[1];
    var _d = useState(''), emailError = _d[0], setEmailError = _d[1];
    var emailAttempts = useSelector(function (state) { return state.user.emailChangeAttempts; });
    var _e = useState(0), timeLeft = _e[0], setTimeLeft = _e[1];
    var _f = useState(), debounceTimeout = _f[0], setDebounceTimeout = _f[1];
    var user = useSelector(function (state) { return state.user; }).user;
    var cognitoUserId = useSelector(function (state) { return state.auth; }).cognitoUserId;
    // Cached profile selector
    var cachedProfile = useSelector(function (state) {
        return cognitoUserId ? selectCachedProfile(state, cognitoUserId, 'AUTHENTICATED') : undefined;
    });
    var profileData = cachedProfile || user;
    // logger.log('profileData:', JSON.stringify(profileData, null, 2));
    // logger.log('cognitoUserId:', cognitoUserId);
    // Verification state
    var _g = useState({
        verificationCode: '',
        verificationSent: false,
        verificationError: null,
        isVerifying: false,
        hash: '',
        timestamp: 0,
    }), verificationState = _g[0], setVerificationState = _g[1];
    // Check and update block status
    useEffect(function () {
        if (emailAttempts.isBlocked) {
            var interval_1 = setInterval(function () {
                dispatch(checkBlockStatus());
                if (emailAttempts.blockEndTime) {
                    var remaining = Math.ceil((emailAttempts.blockEndTime - Date.now()) / 1000);
                    setTimeLeft(remaining > 0 ? remaining : 0);
                }
            }, 1000);
            return function () { return clearInterval(interval_1); };
        }
    }, [emailAttempts.isBlocked, emailAttempts.blockEndTime]);
    useEffect(function () {
        return function () {
            if (debounceTimeout)
                clearTimeout(debounceTimeout);
        };
    }, [debounceTimeout]);
    var handleSendVerification = function () { return __awaiter(void 0, void 0, void 0, function () {
        var availabilityResult, verificationResult_1, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsChecking(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, 5, 6]);
                    setEmailError('');
                    return [4 /*yield*/, dispatch(checkEmailAvailability(newEmail)).unwrap()];
                case 2:
                    availabilityResult = _a.sent();
                    //   logger.log('handleSendVerification availabilityResult:', JSON.stringify(availabilityResult));
                    if (availabilityResult) {
                        setEmailError('This email address is already in use');
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, dispatch(sendEmailVerificationCode(newEmail)).unwrap()];
                case 3:
                    verificationResult_1 = _a.sent();
                    //   logger.log('handleSendVerification verificationResult:', JSON.stringify(verificationResult));
                    if (verificationResult_1.responseCode === 'SUCCESS') {
                        setVerificationState(function (prev) { return (__assign(__assign({}, prev), { verificationSent: true, verificationError: null, hash: verificationResult_1.hash, timestamp: verificationResult_1.timestamp })); });
                    }
                    else {
                        setEmailError('Failed to send verification code. Try again.');
                    }
                    return [3 /*break*/, 6];
                case 4:
                    error_1 = _a.sent();
                    setEmailError(error_1 instanceof Error ? error_1.message : 'An error occurred');
                    return [3 /*break*/, 6];
                case 5:
                    setIsChecking(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var verifyHashCode = function (inputCode, originalHash, timestamp, thisCognitoID) {
        // logger.log('verifyHashCode inputCode:', inputCode);
        // logger.log('verifyHashCode originalHash:', originalHash);
        // logger.log('verifyHashCode timestamp:', timestamp);
        // logger.log('verifyHashCode thisCognitoID:', thisCognitoID);
        var calculatedHash = crypto
            .HmacSHA256("".concat(inputCode, "-").concat(timestamp), thisCognitoID)
            .toString();
        return calculatedHash === originalHash;
    };
    // Handle verification code submission
    var handleVerifyAndUpdate = function () { return __awaiter(void 0, void 0, void 0, function () {
        var isInUse, verificationCode, result, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setVerificationState(function (prev) { return (__assign(__assign({}, prev), { isVerifying: true, verificationError: null })); });
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, 5, 6]);
                    return [4 /*yield*/, dispatch(checkEmailAvailability(newEmail)).unwrap()];
                case 2:
                    isInUse = _a.sent();
                    if (isInUse) {
                        setVerificationState(function (prev) { return (__assign(__assign({}, prev), { verificationError: 'This email address is no longer available. Try a different email.' })); });
                        return [2 /*return*/];
                    }
                    // logger.log('handleVerifyAndUpdate isInUse:', isInUse);
                    // logger.log('handleVerifyAndUpdate verificationState:', JSON.stringify(verificationState, null, 2));
                    if (!verifyHashCode(verificationState.verificationCode.toUpperCase(), //all verification codes are hashed using only uppercase characters
                    verificationState.hash, verificationState.timestamp, cognitoUserId || '')) {
                        setVerificationState(function (prev) { return (__assign(__assign({}, prev), { verificationError: 'Verification code does not match. Try again.' })); });
                        return [2 /*return*/];
                    }
                    verificationCode = verificationState.verificationCode;
                    return [4 /*yield*/, dispatch(verifyAndUpdateEmail({
                            newEmail: newEmail,
                            verificationCode: verificationCode
                        })).unwrap()];
                case 3:
                    result = _a.sent();
                    logger.log('handleVerifyAndUpdate result:', JSON.stringify(result, null, 2));
                    if (result.emailUpdated) {
                        dispatch(showSnackbar({
                            message: 'Email updated successfully. Log in with your new email address.',
                            severity: 'success'
                        }));
                        onClose();
                        dispatch(logout());
                    }
                    return [3 /*break*/, 6];
                case 4:
                    error_2 = _a.sent();
                    setVerificationState(function (prev) { return (__assign(__assign({}, prev), { verificationError: error_2 instanceof Error ? error_2.message : 'Verification failed' })); });
                    return [3 /*break*/, 6];
                case 5:
                    setVerificationState(function (prev) { return (__assign(__assign({}, prev), { isVerifying: false })); });
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    // Reset state when modal closes
    var handleClose = function () {
        if (!verificationState.isVerifying) {
            setNewEmail('');
            setEmailError('');
            setVerificationState({
                verificationCode: '',
                verificationSent: false,
                verificationError: null,
                isVerifying: false,
                hash: '',
                timestamp: 0,
            });
            onClose();
        }
    };
    var validateNewEmail = function (thisNewEmail) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setNewEmail(thisNewEmail);
            setEmailError('');
            // Basic validation first
            if (!validateEmail(thisNewEmail)) {
                setEmailError('Invalid email address');
                return [2 /*return*/, false];
            }
            // Compare with current email when we uncomment that section
            if (thisNewEmail === (profileData === null || profileData === void 0 ? void 0 : profileData.basic.email)) {
                setEmailError('New email must be different from current email');
                return [2 /*return*/, false];
            }
            return [2 /*return*/, true];
        });
    }); };
    return (_jsxs(Dialog, __assign({ open: open, onClose: handleClose, maxWidth: "sm", fullWidth: true }, { children: [_jsx(DialogTitle, { children: "Change Email Address" }), _jsx(DialogContent, { children: verificationState.verificationSent ? (_jsxs(_Fragment, { children: [_jsxs(DialogContentText, __assign({ sx: { mb: 2 } }, { children: ["Enter the 6 character verification code sent to ", newEmail, ". Do not leave this page before you enter the code or you will have to restart this process."] })), _jsx(TextField, { inputProps: { maxLength: 6 }, fullWidth: true, label: "Verification Code", value: verificationState.verificationCode, onChange: function (e) { return setVerificationState(function (prev) { return (__assign(__assign({}, prev), { verificationCode: e.target.value, verificationError: null })); }); }, error: !!verificationState.verificationError, helperText: verificationState.verificationError, disabled: verificationState.isVerifying, sx: { mb: 2 } })] })) : (_jsxs(_Fragment, { children: [_jsx(DialogContentText, __assign({ sx: { mb: 2 } }, { children: "Enter your new email address. A verification code will be sent to this address." })), _jsx(TextField, { fullWidth: true, label: "New Email Address", value: newEmail, onChange: function (e) {
                                validateNewEmail(e.target.value);
                            }, error: !!emailError, helperText: emailError, disabled: isChecking, sx: { mb: 2 } })] })) }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ onClick: handleClose, disabled: verificationState.isVerifying }, { children: "Cancel" })), verificationState.verificationSent ? (_jsx(LoadingButton, __assign({ onClick: handleVerifyAndUpdate, loading: verificationState.isVerifying, disabled: !verificationState.verificationCode || verificationState.isVerifying || verificationState.verificationCode.length < 6, variant: "contained" }, { children: "Verify & Update Email" }))) : (_jsx(LoadingButton, __assign({ onClick: handleSendVerification, loading: isChecking, disabled: !newEmail || !!emailError || isChecking, variant: "contained" }, { children: "Send Verification" })))] })] })));
};
