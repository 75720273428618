var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// website/src/hooks/useActivityLog.ts
import React from 'react';
import { useDispatch } from 'react-redux';
import { logUserActivity } from '@store/appSlice';
export var useActivityLog = function () {
    var dispatch = useDispatch();
    var activityLogger = React.useCallback(function (action, params) {
        if (params === void 0) { params = {}; }
        // Fire and forget - don't await the dispatch
        dispatch(logUserActivity(__assign({ action: action }, params)));
    }, [dispatch]);
    // Predefined common logging patterns
    var logInteraction = {
        buttonClick: function (buttonId, context) {
            activityLogger('button_click', {
                contentDataType: 'button',
                contentDataID: buttonId,
                activitynotes: context
            });
        },
        formSubmit: function (formId, success) {
            activityLogger('form_submit', {
                contentDataType: 'form',
                contentDataID: formId,
                activitynotes: success ? 'success' : 'failed'
            });
        },
        modalOpen: function (modalId) {
            activityLogger('modal_open', {
                contentDataType: 'modal',
                contentDataID: modalId,
                modalAction: 'open'
            });
        },
        modalClose: function (modalId) {
            activityLogger('modal_close', {
                contentDataType: 'modal',
                contentDataID: modalId,
                modalAction: 'close'
            });
        },
        // Add more common patterns as needed
    };
    return {
        activityLogger: activityLogger,
        logInteraction: logInteraction
    };
};
