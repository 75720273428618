var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// website/src/index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'process';
import App from './App';
import { logger } from '@utils/logger';
import { Provider } from 'react-redux';
import { store } from './store';
import { setIsDevServer } from './store/appSlice';
import { isDevServer } from '@utils/isDevServer';
// Import i18n configuration
import './i18n/config';
var root = ReactDOM.createRoot(document.getElementById('root'));
var devServerStatus = isDevServer();
store.dispatch(setIsDevServer(devServerStatus));
function renderApp() {
    // logger.log('Rendering app with Redux store');
    root.render(_jsx(React.StrictMode, { children: _jsx(Provider, __assign({ store: store }, { children: _jsx(App, { isDevServer: devServerStatus }) })) }));
}
if (devServerStatus) {
    logger.log('index.tsx - loading dev environment');
}
renderApp();
