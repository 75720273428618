var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// website/src/utils/activityLogger.js
import { getOrCreateSessionId } from './sessionManager';
// Environment detection utility
var getOriginEnvironment = function () {
    var hostname = window.location.hostname;
    if (hostname.includes('localhost'))
        return 'dev';
    if (hostname.includes('dev.'))
        return 'dev';
    if (hostname.includes('beta.'))
        return 'beta';
    if (hostname.includes('gamma.'))
        return 'gamma';
    return 'prod'; // root hostname
};
var getBrowserInfo = function () {
    var userAgent = navigator.userAgent;
    var browserName = 'unknown';
    var browserVersion = 'unknown';
    var osName = 'unknown';
    var osVersion = 'unknown';
    var deviceType = 'unknown';
    // Browser detection
    if (userAgent.includes('Firefox/')) {
        browserName = 'Firefox';
        browserVersion = userAgent.split('Firefox/')[1];
    }
    else if (userAgent.includes('Chrome/')) {
        browserName = 'Chrome';
        browserVersion = userAgent.split('Chrome/')[1].split(' ')[0];
    }
    else if (userAgent.includes('Safari/') && !userAgent.includes('Chrome')) {
        browserName = 'Safari';
        browserVersion = userAgent.split('Version/')[1].split(' ')[0];
    }
    else if (userAgent.includes('Edge/')) {
        browserName = 'Edge';
        browserVersion = userAgent.split('Edge/')[1];
    }
    // OS detection
    if (userAgent.includes('Windows')) {
        osName = 'Windows';
        osVersion = userAgent.split('Windows ')[1].split(';')[0];
    }
    else if (userAgent.includes('Mac OS')) {
        osName = 'macOS';
        osVersion = userAgent.split('Mac OS X ')[1].split(')')[0];
    }
    else if (userAgent.includes('Linux')) {
        osName = 'Linux';
    }
    else if (userAgent.includes('Android')) {
        osName = 'Android';
        osVersion = userAgent.split('Android ')[1].split(';')[0];
    }
    else if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {
        osName = 'iOS';
        osVersion = userAgent.split('OS ')[1].split(' ')[0];
    }
    // Device type detection
    if (/Mobi|Android/i.test(userAgent)) {
        deviceType = 'mobile';
    }
    else if (/iPad|Tablet/i.test(userAgent)) {
        deviceType = 'tablet';
    }
    else {
        deviceType = 'desktop';
    }
    return {
        browserName: browserName,
        browserVersion: browserVersion,
        osName: osName,
        osVersion: osVersion,
        deviceType: deviceType
    };
};
var getBaseParams = function (userID) {
    var _a = getBrowserInfo(), browserName = _a.browserName, browserVersion = _a.browserVersion, osName = _a.osName, osVersion = _a.osVersion, deviceType = _a.deviceType;
    // Safely check for connection information
    var connection = navigator.connection;
    var connectionType = (connection === null || connection === void 0 ? void 0 : connection.effectiveType) || 'unknown';
    return {
        userID: userID,
        sessionId: getOrCreateSessionId(),
        timestamp: new Date().getTime(),
        localTime: new Date().toISOString(),
        pageName: window.location.pathname,
        requestMethod: 'GET',
        originEnvironment: getOriginEnvironment(),
        originPath: window.location.pathname,
        browserWidth: document.documentElement.clientWidth,
        browserHeight: document.documentElement.clientHeight,
        timezoneOffset: new Date().getTimezoneOffset(),
        action: 'default',
        modalAction: 'null',
        activitynotes: 'null',
        contentDataType: 'null',
        contentDataID: '0',
        contentDataUUID: '0',
        hideFromGeneralActivityReports: 'false',
        latitude: 'null',
        longitude: 'null',
        locationAccuracy: 'null',
        // New client environment fields
        browserName: browserName,
        browserVersion: browserVersion,
        osName: osName,
        osVersion: osVersion,
        deviceType: deviceType,
        screenWidth: window.screen.width.toString(),
        screenHeight: window.screen.height.toString(),
        colorDepth: window.screen.colorDepth.toString(),
        language: navigator.language || 'unknown',
        doNotTrack: navigator.doNotTrack || 'unknown',
        cookiesEnabled: navigator.cookieEnabled.toString(),
        online: navigator.onLine.toString(),
        connectionType: connectionType,
        referrer: document.referrer || 'null',
        platform: navigator.platform || 'unknown'
    };
};
// Main logging function
export var logActivity = function (userID, customParams) {
    if (customParams === void 0) { customParams = {}; }
    return __awaiter(void 0, void 0, void 0, function () {
        var baseParams, params;
        return __generator(this, function (_a) {
            try {
                // console.log('Logging activity with userID:', userID)
                // console.log('Custom params:', customParams)
                // set a default userID if none is provided
                if (!userID) {
                    userID = 'anonymous';
                }
                baseParams = getBaseParams(userID);
                params = __assign(__assign({}, baseParams), customParams);
                // Development logging
                if (getOriginEnvironment() === 'dev') {
                    // console.group('Activity Logger');
                    // console.log('Params:', params);
                    // console.log('Time:', new Date(params.timestamp).toLocaleString());
                    // console.log('Environment:', params.originEnvironment);
                    // console.groupEnd();
                }
                else if (getOriginEnvironment() === 'prod') {
                    // no console logging for production
                }
                else {
                    // Simple console log for non-dev environments
                    // console.log('Activity Log:', params);
                }
                // Return the formatted params
                return [2 /*return*/, params];
            }
            catch (error) {
                console.error('Error in logActivity:', error);
                throw error; // Re-throw to handle in the thunk
            }
            return [2 /*return*/];
        });
    });
};
