var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// src/theme/ThemeWrapper.tsx
import { useState, useMemo } from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { ColorModeContext } from './ColorModeContext';
import { createAppTheme } from './theme';
var ThemeWrapper = function (_a) {
    var children = _a.children;
    var _b = useState(function () {
        var savedMode = localStorage.getItem('theme-mode');
        return (savedMode === 'dark' || savedMode === 'light') ? savedMode : 'light';
    }), mode = _b[0], setMode = _b[1];
    var colorMode = useMemo(function () { return ({
        mode: mode,
        toggleColorMode: function () {
            setMode(function (prevMode) {
                var newMode = prevMode === 'light' ? 'dark' : 'light';
                localStorage.setItem('theme-mode', newMode);
                return newMode;
            });
        },
    }); }, [mode]);
    var theme = useMemo(function () { return createAppTheme(mode); }, [mode]);
    return (_jsx(ColorModeContext.Provider, __assign({ value: colorMode }, { children: _jsxs(ThemeProvider, __assign({ theme: theme }, { children: [_jsx(CssBaseline, {}), children] })) })));
};
export default ThemeWrapper;
