import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
// website/src/components/ActivityLoggerProfider.tsx
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useActivityLog } from '@hooks/useActivityLog';
var ActivityLoggerProvider = function (_a) {
    var children = _a.children;
    var auth = useSelector(function (state) { return state.auth; });
    var location = useLocation();
    var lastLoggedPath = useRef('');
    var activityLogger = useActivityLog().activityLogger;
    // console.log('ActivityLoggerProvider: auth:', JSON.stringify(auth, null, 2));
    useEffect(function () {
        if (lastLoggedPath.current !== location.pathname) {
            activityLogger('pageView', {
                pageName: location.pathname,
                activitynotes: 'Page view',
                authenticated: !!auth.cognitoUserId,
            });
            lastLoggedPath.current = location.pathname;
        }
    }, [location.pathname, auth.cognitoUserId, activityLogger]);
    return _jsx(_Fragment, { children: children });
};
export default ActivityLoggerProvider;
