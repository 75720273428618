var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// website/src/store/appSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logActivity } from '@utils/activityLogger';
import ApiConnector from '@utils/apiConnector';
import { checkLocationPermissionStatus, getBrowserLocation } from '@utils/geolocation';
export var logUserActivity = createAsyncThunk('app/logUserActivity', function (params, _a) {
    var getState = _a.getState, rejectWithValue = _a.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, userID, locationData, _b, paramsWithLocation, formattedParams, requestBody, apiConnector, error_1;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    state = getState();
                    userID = state.auth.cognitoUserId;
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 6, , 7]);
                    if (!userID) return [3 /*break*/, 3];
                    // Check permission before getting location
                    return [4 /*yield*/, checkLocationPermissionStatus().then(function (permissionStatus) { return __awaiter(void 0, void 0, void 0, function () {
                            var location_1;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(permissionStatus === 'granted')) return [3 /*break*/, 2];
                                        return [4 /*yield*/, getBrowserLocation()];
                                    case 1:
                                        location_1 = _a.sent();
                                        // console.log('3. Got browser location:', location);
                                        return [2 /*return*/, location_1];
                                    case 2: 
                                    // console.log('3. No permission, using null values');
                                    return [2 /*return*/, { latitude: 'null', longitude: 'null', accuracy: 'null' }];
                                }
                            });
                        }); })];
                case 2:
                    // Check permission before getting location
                    _b = _c.sent();
                    return [3 /*break*/, 4];
                case 3:
                    // Default values for non-authenticated users
                    _b = { latitude: 'null', longitude: 'null', accuracy: 'null' };
                    _c.label = 4;
                case 4:
                    locationData = _b;
                    paramsWithLocation = __assign(__assign({}, params), { latitude: locationData.latitude, longitude: locationData.longitude, locationAccuracy: locationData.accuracy });
                    return [4 /*yield*/, logActivity(userID || null, paramsWithLocation)];
                case 5:
                    formattedParams = _c.sent();
                    requestBody = {
                        operation: 'LOG_USER_ACTIVITY',
                        payload: formattedParams,
                        repository: 'routy-web'
                    };
                    apiConnector = new ApiConnector(function () { return getState(); });
                    // Fire and forget - don't await the response
                    Promise.resolve(apiConnector.post('/user-activity-log', requestBody));
                    return [3 /*break*/, 7];
                case 6:
                    error_1 = _c.sent();
                    if (error_1 instanceof Error) {
                        console.error('Logging failed:', error_1);
                        return [2 /*return*/, rejectWithValue(error_1.message)];
                    }
                    return [3 /*break*/, 7];
                case 7: 
                // Return immediately
                return [2 /*return*/, params];
            }
        });
    });
}, {
    condition: function () { return true; },
});
var appSlice = createSlice({
    name: 'app',
    initialState: {
        failedLogs: [],
        isDevServer: false
    },
    reducers: {
        setIsDevServer: function (state, action) {
            state.isDevServer = action.payload;
        },
    },
    extraReducers: function (builder) {
        builder
            .addCase(logUserActivity.rejected, function (state, action) {
            // Optionally store failed logs
            state.failedLogs.push(action.meta.arg);
        });
    },
});
export var setIsDevServer = appSlice.actions.setIsDevServer;
export default appSlice.reducer;
