// website/src/types/profile.schema.ts
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var PROFILE_SCHEMA = {
    basic: {
        firstName: { default: '', type: 'string' },
        lastName: { default: '', type: 'string' },
        email: { default: '', type: 'string', required: true },
        phone: { default: '', type: 'string' },
        bio: { default: '', type: 'string' },
        screenName: { default: '', type: 'string' },
        avatar: { default: '', type: 'string' },
    },
    auth: {
        currentPassword: { default: '', type: 'string', temporary: true },
        newPassword: { default: '', type: 'string', temporary: true },
        confirmPassword: { default: '', type: 'string', temporary: true },
        authType: { default: 'COGNITO', type: 'string' },
        authProvider: { default: 'COGNITO', type: 'string' },
        emailVerified: { default: 'false', type: 'string' },
    },
    settings: {
        theme: { default: 'light', type: 'theme' },
        language: { default: 'en', type: 'string' },
        timezone: { default: 'UTC', type: 'string' },
    },
    notifications: {
        emailNotifications: { default: 'false', type: 'string' },
        pushNotifications: { default: 'false', type: 'string' },
    },
    location: {
        country: { default: '', type: 'string' },
        state: { default: '', type: 'string' },
        city: { default: '', type: 'string' },
        latitude: { default: '', type: 'string', temporary: true },
        longitude: { default: '', type: 'string', temporary: true },
        locationPermission: { default: 'prompt', type: 'string' },
        lastUpdated: { default: '', type: 'string' }
    },
    privacy: {
        shareCity: { default: 'false', type: 'string' },
        shareState: { default: 'false', type: 'string' },
        shareCountry: { default: 'false', type: 'string' },
        shareCoordinates: { default: 'false', type: 'string' },
        coordinatePrecision: { default: 'city-level', type: 'string' },
        dataRetention: { default: 'session', type: 'string' },
    }
};
;
// Utility functions
export var profileUtils = {
    getDefaults: function () {
        var defaults = {
            basic: {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                bio: '',
                screenName: '',
                avatarUrl: '',
            },
            auth: {
                currentPassword: '',
                newPassword: '',
                confirmPassword: '',
                authType: 'COGNITO',
                authProvider: 'COGNITO',
                emailVerified: 'false',
                currentStatus: 'ACTIVE',
            },
            settings: {
                theme: 'light',
                language: 'en',
                timezone: 'UTC',
            },
            notifications: {
                emailNotifications: 'false',
                pushNotifications: 'false',
            },
            location: {
                country: '',
                state: '',
                city: '',
                latitude: '',
                longitude: '',
                locationPermission: 'prompt',
                lastUpdated: ''
            },
            privacy: {
                shareCity: 'false',
                shareState: 'false',
                shareCountry: 'false',
                shareCoordinates: 'false',
                coordinatePrecision: 'city-level',
                dataRetention: 'session',
            }
        };
        return defaults;
    },
    normalizeProfile: function (profile) {
        var defaults = this.getDefaults();
        return {
            basic: __assign(__assign({}, defaults.basic), profile.basic),
            auth: __assign(__assign({}, defaults.auth), profile.auth),
            settings: __assign(__assign({}, defaults.settings), profile.settings),
            notifications: __assign(__assign({}, defaults.notifications), profile.notifications),
            location: __assign(__assign({}, defaults.location), profile.location),
            privacy: __assign(__assign({}, defaults.privacy), profile.privacy)
        };
    },
    validateProfile: function (profile) {
        var _a;
        var errors = [];
        // Basic validation
        if (!((_a = profile.basic) === null || _a === void 0 ? void 0 : _a.email)) {
            errors.push('Email is required');
        }
        // Location validation
        if (profile.location) {
            // Coordinate validation
            if (profile.location.latitude || profile.location.longitude) {
                var lat = parseFloat(profile.location.latitude || '');
                var lng = parseFloat(profile.location.longitude || '');
                if (profile.location.latitude && (isNaN(lat) || lat < -90 || lat > 90)) {
                    errors.push('Invalid latitude value');
                }
                if (profile.location.longitude && (isNaN(lng) || lng < -180 || lng > 180)) {
                    errors.push('Invalid longitude value');
                }
            }
            // Permission validation
            if (profile.location.locationPermission &&
                !['granted', 'denied', 'prompt'].includes(profile.location.locationPermission)) {
                errors.push('Invalid location permission value');
            }
            // Date validation for lastUpdated
            if (profile.location.lastUpdated &&
                isNaN(Date.parse(profile.location.lastUpdated))) {
                errors.push('Invalid lastUpdated date format');
            }
        }
        return errors;
    }
};
