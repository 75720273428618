// website/src/store/index.ts
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import userReducer from './userSlice';
import appReducer from './appSlice';
import uiReducer from './uiSlice';
import eventReducer from './eventSlice';
import locationReducer from './locationSlice';
import secretsReducer from './secretsSlice';
import adventureReducer from './adventureSlice';
import settingsReducer from './settingsSlice';
import { activityLoggerMiddleware } from '../middleware/activityLoggerMiddleware';
// console.log('NODE_ENV: '+process.env.NODE_ENV)
export var store = configureStore({
    reducer: {
        auth: authReducer,
        user: userReducer,
        app: appReducer,
        ui: uiReducer,
        location: locationReducer,
        event: eventReducer,
        secrets: secretsReducer,
        adventure: adventureReducer,
        settings: settingsReducer
    },
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware().concat(activityLoggerMiddleware);
    },
    devTools: process.env.NODE_ENV !== 'production', // Enable DevTools only in non-production environments
});
