var _a;
// website/src/store/uiSlice.ts
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    snackbar: {
        open: false,
        message: '',
        severity: 'info',
        duration: 6000, // default duration: 6 seconds
    },
};
var uiSlice = createSlice({
    name: 'ui',
    initialState: initialState,
    reducers: {
        showSnackbar: function (state, action) {
            state.snackbar = {
                open: true,
                message: action.payload.message,
                severity: action.payload.severity,
                duration: action.payload.duration || 6000,
            };
        },
        hideSnackbar: function (state) {
            state.snackbar.open = false;
        },
    },
});
export var showSnackbar = (_a = uiSlice.actions, _a.showSnackbar), hideSnackbar = _a.hideSnackbar;
export default uiSlice.reducer;
