// src/i18n/locales/fr/index.ts
export default {
    common: {
        buttons: {
            submit: 'Soumettre',
            cancel: 'Annuler',
            close: 'Fermer'
        },
        navigation: {
            home: 'Accueil',
            profile: 'Profil'
        }
    },
    home: {
        title: 'routy',
        subtitle: 'partagez vos aventures',
        logo: {
            alt: 'logo routy'
        }
    },
    preferences: {
        languageAndRegion: 'Langue et Région',
        language: 'Langue',
        timezone: 'Fuseau Horaire',
        timezones: {
            'UTC': 'UTC/GMT',
            'America/New_York': 'Heure de l\'Est (ET)',
            'America/Chicago': 'Heure Centrale (CT)',
            'America/Denver': 'Heure des Rocheuses (MT)',
            'America/Phoenix': 'Heure des Rocheuses - Arizona',
            'America/Los_Angeles': 'Heure du Pacifique (PT)',
            'America/Anchorage': 'Heure de l\'Alaska (AKT)',
            'Pacific/Honolulu': 'Heure d\'Hawaï (HT)',
            'Europe/London': 'Heure Britannique (BST/GMT)',
            'Europe/Paris': 'Heure d\'Europe Centrale (CET)',
            'Europe/Helsinki': 'Heure d\'Europe de l\'Est (EET)',
            'Asia/Tokyo': 'Heure du Japon (JST)',
            'Asia/Shanghai': 'Heure de Chine (CST)',
            'Asia/Singapore': 'Heure de Singapour (SGT)',
            'Australia/Sydney': 'Heure de l\'Est de l\'Australie (AET)',
            'Asia/Dubai': 'Heure du Golfe (GT)',
            'Asia/Kolkata': 'Heure de l\'Inde (IST)'
        }
    }
};
