var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// website/src/pages/ForgotPassword.tsx
import { useState, useEffect } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword, resetPassword, clearAuthError, setAuthError } from '@store/authSlice';
import { validatePassword, getPasswordErrorMessage } from '@utils/passwordUtils';
import { withLayout } from '@components/withLayout';
import PasswordStrengthMeter from '@components/PasswordStrengthMeter';
import { validateEmail } from '@utils/validateEmail';
import { Button, TextField, Typography, Box, Container, Alert, Snackbar, Link, } from '@mui/material';
var ForgotPassword = function () {
    var _a = useState(''), email = _a[0], setEmail = _a[1];
    var _b = useState(''), code = _b[0], setCode = _b[1];
    var _c = useState(''), newPassword = _c[0], setNewPassword = _c[1];
    var _d = useState(1), step = _d[0], setStep = _d[1];
    var _e = useState(null), passwordError = _e[0], setPasswordError = _e[1];
    var _f = useState(null), successMessage = _f[0], setSuccessMessage = _f[1];
    var _g = useState(false), isEmailValid = _g[0], setIsEmailValid = _g[1];
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var _h = useSelector(function (state) { return state.auth; }), isLoading = _h.isLoading, error = _h.error, isAuthenticated = _h.isAuthenticated;
    useEffect(function () {
        if (isAuthenticated) {
            navigate('/dashboard');
        }
    }, [isAuthenticated, navigate]);
    useEffect(function () {
        dispatch(clearAuthError());
    }, [dispatch, email, code, newPassword]);
    useEffect(function () {
        return function () {
            dispatch(clearAuthError());
            setEmail('');
            setCode('');
            setNewPassword('');
            setStep(1);
            setPasswordError(null);
            setSuccessMessage(null);
        };
    }, [dispatch]);
    var handleEmailChange = function (e) {
        var newEmail = e.target.value;
        setEmail(newEmail);
        setIsEmailValid(validateEmail(newEmail));
    };
    var handleSubmitEmail = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var resultAction;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    if (!isEmailValid)
                        return [2 /*return*/];
                    dispatch(clearAuthError());
                    return [4 /*yield*/, dispatch(forgotPassword(email))];
                case 1:
                    resultAction = _a.sent();
                    if (forgotPassword.fulfilled.match(resultAction)) {
                        setStep(2);
                        setSuccessMessage('Verification code sent to your email.');
                    }
                    else if (forgotPassword.rejected.match(resultAction)) {
                        if (resultAction.payload === 'Username/client id combination not found.') {
                            dispatch(setAuthError('No account found with this email address. Please check and try again.'));
                        }
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handleResetPassword = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var resultAction;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    dispatch(clearAuthError());
                    setPasswordError(null);
                    if (!validatePassword(newPassword)) {
                        setPasswordError(getPasswordErrorMessage());
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, dispatch(resetPassword({ email: email, code: code, newPassword: newPassword }))];
                case 1:
                    resultAction = _a.sent();
                    if (resetPassword.fulfilled.match(resultAction)) {
                        setSuccessMessage('Password reset successful. Please login with your new password.');
                        setTimeout(function () { return navigate('/login'); }, 3000);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Container, __assign({ component: "main", maxWidth: "xs" }, { children: [_jsxs(Box, __assign({ sx: {
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                } }, { children: [_jsx(Typography, __assign({ component: "h1", variant: "h5", gutterBottom: true }, { children: "Reset Password" })), step === 1 && (_jsx(Typography, __assign({ variant: "body2", color: "text.secondary", align: "center", sx: { mb: 2 } }, { children: "To reset your password, please enter the email address associated with your account. We will send a verification code to this email, which you'll use on the next screen to authorize the password change." }))), error && _jsx(Alert, __assign({ severity: "error", sx: { mt: 2, width: '100%' } }, { children: error })), _jsxs(Box, __assign({ component: "form", onSubmit: step === 1 ? handleSubmitEmail : handleResetPassword, noValidate: true, sx: { mt: 1, width: '100%' } }, { children: [step === 1 ? (_jsx(TextField, { margin: "normal", required: true, fullWidth: true, id: "email", label: "Email Address", name: "email", autoComplete: "email", autoFocus: true, value: email, onChange: handleEmailChange, error: email.length > 0 && !isEmailValid, helperText: email.length > 0 && !isEmailValid ? 'Please enter a valid email address' : '' })) : (_jsxs(_Fragment, { children: [_jsx(TextField, { margin: "normal", required: true, fullWidth: true, id: "code", label: "Verification Code", name: "code", autoFocus: true, value: code, onChange: function (e) { return setCode(e.target.value); } }), _jsx(TextField, { margin: "normal", required: true, fullWidth: true, name: "newPassword", label: "New Password", type: "password", id: "newPassword", autoComplete: "new-password", value: newPassword, onChange: function (e) { return setNewPassword(e.target.value); }, error: !!passwordError, helperText: passwordError }), _jsx(PasswordStrengthMeter, { password: newPassword })] })), _jsx(Button, __assign({ type: "submit", fullWidth: true, variant: "contained", sx: { mt: 3, mb: 2 }, disabled: isLoading || (step === 1 && !isEmailValid) || (step === 2 && !validatePassword(newPassword)) }, { children: isLoading
                                    ? (step === 1 ? 'Sending...' : 'Resetting...')
                                    : (step === 1 ? 'Send Verification Code' : 'Reset Password') })), _jsx(Box, __assign({ sx: { textAlign: 'center' } }, { children: _jsx(Link, __assign({ component: RouterLink, to: "/login", variant: "body2" }, { children: "Back to Login" })) }))] }))] })), _jsx(Snackbar, { open: !!successMessage, autoHideDuration: 6000, onClose: function () { return setSuccessMessage(null); }, message: successMessage })] })));
};
export default withLayout(ForgotPassword);
