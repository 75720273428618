var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, TextField, } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Save } from '@mui/icons-material';
export var PersonalInfoTab = function (_a) {
    var formData = _a.formData, handleInputChange = _a.handleInputChange, handleSaveProfile = _a.handleSaveProfile, isSaving = _a.isSaving;
    // logger.log('PersonalInfoTab formData: ', JSON.stringify(formData,null,2))
    return (_jsxs(Grid, __assign({ container: true, spacing: 3 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(TextField, { fullWidth: true, label: "First Name", name: "firstName", value: formData.basic.firstName, onChange: handleInputChange, disabled: isSaving }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(TextField, { fullWidth: true, label: "Last Name", name: "lastName", value: formData.basic.lastName, onChange: handleInputChange, disabled: isSaving }) })), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(TextField, { fullWidth: true, label: "Phone", name: "phone", value: formData.basic.phone, onChange: handleInputChange, disabled: isSaving }) })), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(TextField, { fullWidth: true, multiline: true, rows: 4, label: "Bio", name: "bio", value: formData.basic.bio, onChange: handleInputChange, disabled: isSaving }) })), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(LoadingButton, __assign({ loading: isSaving, variant: "contained", onClick: handleSaveProfile, startIcon: _jsx(Save, {}) }, { children: isSaving ? 'Saving Changes...' : 'Save Changes' })) }))] })));
};
