var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// website/src/pages/SignUp.tsx
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signUp, clearAuthError, setAuthError, setEnableSignups } from '@store/authSlice';
import { validatePassword, getPasswordErrorMessage } from '@utils/passwordUtils';
import { logger } from '@utils/logger';
import { getPoolConfig } from '@utils/cognito';
import { withLayout } from '@components/withLayout';
import { setSignupEmail } from '@utils/authUtils';
import { validateEmail } from '@utils/validateEmail';
import PasswordStrengthMeter from '@components/PasswordStrengthMeter';
import { Button, TextField, Typography, Box, Link, Container, Alert } from '@mui/material';
var SignUp = function () {
    var dispatch = useDispatch();
    var _a = useSelector(function (state) { return state.auth; }), error = _a.error, isLoading = _a.isLoading, isAuthenticated = _a.isAuthenticated;
    var isDevServer = useSelector(function (state) { return state.app.isDevServer; });
    var navigate = useNavigate();
    var _b = useState(isDevServer ? "rustynations+dev".concat(Math.floor(Date.now() / 1000), "@gmail.com") : ''), email = _b[0], setEmail = _b[1];
    var _c = useState(isDevServer ? 'Abc123!!' : ''), password = _c[0], setPassword = _c[1];
    var _d = useState(isDevServer ? 'Abc123!!' : ''), confirmPassword = _d[0], setConfirmPassword = _d[1];
    var _e = useState(false), pendingVerification = _e[0], setPendingVerification = _e[1];
    useEffect(function () {
        if (isAuthenticated) {
            navigate('/dashboard');
        }
    }, [isAuthenticated, navigate]);
    useEffect(function () {
        dispatch(clearAuthError());
    }, [dispatch]);
    useEffect(function () {
        if (isDevServer) {
            dispatch(setEnableSignups(true));
        }
        else {
            var poolConfig = getPoolConfig();
            logger.log('poolConfig:', JSON.stringify(poolConfig, null, 2));
            dispatch(setEnableSignups(poolConfig.enableSignups));
        }
    }, [dispatch, isDevServer]);
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var result, errorMessage;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    e.preventDefault();
                    dispatch(clearAuthError());
                    setPendingVerification(false);
                    if (!validateEmail(email)) {
                        dispatch(setAuthError("Please enter a valid email address."));
                        return [2 /*return*/];
                    }
                    if (!validatePassword(password)) {
                        dispatch(setAuthError(getPasswordErrorMessage()));
                        return [2 /*return*/];
                    }
                    if (password !== confirmPassword) {
                        dispatch(setAuthError("Passwords do not match"));
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, dispatch(signUp({ email: email, password: password }))];
                case 1:
                    result = _b.sent();
                    // logger.log('signUp result:', JSON.stringify(result, null, 2));
                    if (signUp.fulfilled.match(result)) {
                        setSignupEmail(email);
                        navigate('/verify', { state: { email: email } });
                    }
                    else if (signUp.rejected.match(result)) {
                        // Check for the specific error that indicates the user exists but is not confirmed
                        if (result.error && result.error.name === 'UsernameExistsException') {
                            setPendingVerification(true);
                            setSignupEmail(email);
                        }
                        else {
                            errorMessage = ((_a = result.error) === null || _a === void 0 ? void 0 : _a.message) || 'An unknown error occurred';
                            // logger.error('signUp error:', errorMessage);
                            //replace 'SignUp is not permitted for this user pool' with something more user friendly
                            if (errorMessage.includes('SignUp is not permitted for this user pool')) {
                                errorMessage = 'Sign up is not permitted for this application.';
                            }
                            dispatch(setAuthError(errorMessage));
                        }
                        // Other errors will be displayed by the error state from Redux
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(Container, __assign({ component: "main", maxWidth: "xs" }, { children: _jsxs(Box, __assign({ sx: {
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            } }, { children: [_jsx(Typography, __assign({ component: "h1", variant: "h5" }, { children: "Sign Up" })), error && !pendingVerification && _jsx(Alert, __assign({ severity: "error", sx: { mt: 2, width: '100%' } }, { children: error })), pendingVerification && (_jsxs(Alert, __assign({ severity: "warning", sx: { mt: 2, width: '100%' } }, { children: ["This email address is pending verification.", _jsx(Link, __assign({ component: RouterLink, to: "/verify", state: { email: email, fromSignup: true }, onClick: function () { return dispatch(clearAuthError()); } }, { children: "Click here to verify your email." }))] }))), _jsxs(Box, __assign({ component: "form", onSubmit: handleSubmit, noValidate: true, sx: { mt: 1 } }, { children: [_jsx(TextField, { margin: "normal", required: true, fullWidth: true, id: "email", label: "Email Address", name: "email", autoComplete: "email", autoFocus: true, value: email, onChange: function (e) { return setEmail(e.target.value); } }), _jsx(TextField, { margin: "normal", required: true, fullWidth: true, name: "password", label: "Password", type: "password", id: "password", autoComplete: "new-password", value: password, onChange: function (e) { return setPassword(e.target.value); } }), _jsx(PasswordStrengthMeter, { password: password }), _jsx(TextField, { margin: "normal", required: true, fullWidth: true, name: "confirmPassword", label: "Confirm Password", type: "password", id: "confirmPassword", autoComplete: "new-password", value: confirmPassword, onChange: function (e) { return setConfirmPassword(e.target.value); } }), _jsx(Button, __assign({ type: "submit", fullWidth: true, variant: "contained", sx: { mt: 3, mb: 2 }, disabled: isLoading }, { children: isLoading ? 'Signing Up...' : 'Sign Up' })), _jsx(Box, __assign({ sx: { textAlign: 'center' } }, { children: _jsx(Link, __assign({ component: RouterLink, to: "/login", variant: "body2" }, { children: "Already have an account? Log in" })) }))] }))] })) })));
};
export default withLayout(SignUp);
