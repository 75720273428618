// website/src/utils/cognito.ts
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import configJson from '@config/infrastructure.json';
var config = JSON.parse(JSON.stringify(configJson));
// logger.log('getBaseURL for stage: ' + process.env.REACT_APP_STAGE);
var stage = process.env.REACT_APP_STAGE || 'beta'; // Default to 'beta' if not set
var poolKey = stage === 'prod' ? 'routy-web-prod' : 'routy-web-beta';
// Access the pool configuration
if (!config.cognito || !config.cognito.pools || !(poolKey in config.cognito.pools)) {
    throw new Error("Invalid pool key: ".concat(poolKey));
}
var poolConfig = config.cognito.pools[poolKey];
if (!poolConfig || typeof poolConfig.userPoolId !== 'string' || typeof poolConfig.userPoolClientId !== 'string') {
    throw new Error("Invalid pool configuration for: ".concat(poolKey));
}
// Detailed logging
// logger.log('Full config:', JSON.stringify(config, null, 2));
// logger.log('Cognito config:', JSON.stringify(config.cognito, null, 2));
// logger.log('Pool config:', JSON.stringify(poolConfig, null, 2));
// logger.log('userPoolId:', poolConfig.userPoolId);
// logger.log('userPoolClientId:', poolConfig.userPoolClientId);
//strip the quotes
var thisUserPoolId = poolConfig.userPoolId.replace(/"/g, '');
var thisUserPoolClientId = poolConfig.userPoolClientId.replace(/"/g, '');
// logger.log('thisUserPoolId: ' + thisUserPoolId);
// logger.log('thisUserPoolClientId: ' + thisUserPoolClientId);
var poolData = {
    UserPoolId: thisUserPoolId,
    ClientId: thisUserPoolClientId
};
export var userPool = new CognitoUserPool(poolData);
export var getPoolConfig = function () { return poolConfig; };
