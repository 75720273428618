var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// website/src/store/adventureSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ApiConnector from '@utils/apiConnector';
var initialState = {
    selectedAdventureId: null,
    adventureHistory: {}
};
export var fetchAdventureHistory = createAsyncThunk('adventure/fetchHistory', function (params, _a) {
    var getState = _a.getState, rejectWithValue = _a.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var apiConnector, response, locations, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    apiConnector = new ApiConnector(function () { return getState(); });
                    return [4 /*yield*/, apiConnector.post('/adventures', {
                            operation: 'GET_ADVENTURE_LOCATION_HISTORY',
                            payload: {
                                adventureId: params.adventureId,
                                userId: params.userId,
                                eventId: params.eventId,
                                startTime: params.startTime,
                                limit: params.limit || 1000,
                                includeNotes: params.includeNotes || false,
                                includeMedia: params.includeMedia || false,
                                includeEvents: params.includeEvents || false
                            },
                            repository: 'routy-web'
                        })];
                case 1:
                    response = _b.sent();
                    locations = response.data.locations.map(function (loc) { return ({
                        timestamp: loc.timestamp,
                        latitude: parseFloat(loc.latitude),
                        longitude: parseFloat(loc.longitude),
                        heading: parseFloat(loc.heading),
                        speed: parseFloat(loc.speed),
                        altitude: parseFloat(loc.altitude),
                        accuracy: parseFloat(loc.accuracy)
                    }); });
                    return [2 /*return*/, {
                            locations: locations,
                            lastEvaluatedKey: response.data.lastEvaluatedKey,
                            adventureId: params.adventureId
                        }];
                case 2:
                    error_1 = _b.sent();
                    if (error_1 instanceof Error) {
                        return [2 /*return*/, rejectWithValue(error_1.message)];
                    }
                    return [2 /*return*/, rejectWithValue('Failed to fetch adventure history')];
                case 3: return [2 /*return*/];
            }
        });
    });
});
var adventureSlice = createSlice({
    name: 'adventure',
    initialState: initialState,
    reducers: {
        setSelectedAdventure: function (state, action) {
            state.selectedAdventureId = action.payload;
        }
    },
    extraReducers: function (builder) {
        builder
            .addCase(fetchAdventureHistory.pending, function (state, action) {
            var adventureId = action.meta.arg.adventureId;
            state.adventureHistory[adventureId] = __assign(__assign({}, state.adventureHistory[adventureId]), { isLoading: true, error: null });
        })
            .addCase(fetchAdventureHistory.fulfilled, function (state, action) {
            var _a = action.payload, adventureId = _a.adventureId, locations = _a.locations, lastEvaluatedKey = _a.lastEvaluatedKey;
            state.adventureHistory[adventureId] = {
                locations: locations,
                isLoading: false,
                error: null,
                lastEvaluatedKey: lastEvaluatedKey,
                hasMoreData: !!lastEvaluatedKey
            };
        })
            .addCase(fetchAdventureHistory.rejected, function (state, action) {
            var adventureId = action.meta.arg.adventureId;
            state.adventureHistory[adventureId] = {
                locations: [],
                isLoading: false,
                error: action.error.message || 'Failed to fetch adventure history',
                hasMoreData: false
            };
        });
    }
});
export default adventureSlice.reducer;
