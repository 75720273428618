import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
var AuthGuard = function () {
    var _a = useSelector(function (state) { return state.auth; }), isAuthenticated = _a.isAuthenticated, isCheckingAuth = _a.isCheckingAuth;
    var location = useLocation();
    // logger.log('AuthGuard: isAuthenticated:', isAuthenticated);
    if (isAuthenticated) {
        // Redirect to dashboard if user is already authenticated
        return _jsx(Navigate, { to: "/dashboard", replace: true });
    }
    return _jsx(Outlet, {});
};
export default AuthGuard;
