var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// website/src/store/selectors/eventSelectors.ts
import { createSelector } from '@reduxjs/toolkit';
export var selectCachedProfiles = createSelector([function (state) { return state.user.cachedProfiles; }], function (profiles) {
    return Object.entries(profiles).reduce(function (acc, _a) {
        var userId = _a[0], profileTypes = _a[1];
        acc[userId] = {
            AUTHENTICATED: profileTypes.AUTHENTICATED,
            PUBLIC: profileTypes.PUBLIC
        };
        return acc;
    }, {});
});
export var makeSelectEventParticipants = function () { return createSelector([
    function (state) { return state.event.eventParticipants; },
    function (state) { return state.user.cachedProfiles; },
    function (_, eventId) { return eventId; }
], function (participants, cachedProfiles, eventId) {
    var eventParticipants = participants[eventId] || {};
    return Object.entries(eventParticipants).reduce(function (acc, _a) {
        var _b, _c;
        var userId = _a[0], participant = _a[1];
        var normalizedUserId = userId.replace('USER#', '');
        var profile = ((_b = cachedProfiles[normalizedUserId]) === null || _b === void 0 ? void 0 : _b.AUTHENTICATED) ||
            ((_c = cachedProfiles[normalizedUserId]) === null || _c === void 0 ? void 0 : _c.PUBLIC);
        acc[normalizedUserId] = __assign(__assign({}, participant), { screenName: (profile === null || profile === void 0 ? void 0 : profile.basic.screenName) || participant.screenName, profileData: profile });
        return acc;
    }, {});
}); };
export var makeSelectEventSpectators = function () { return createSelector([
    function (state) { return state.event.eventSpectators; },
    function (state) { return state.user.cachedProfiles; },
    function (_, eventId) { return eventId; }
], function (spectators, cachedProfiles, eventId) {
    var eventSpectators = spectators[eventId] || {};
    return Object.entries(eventSpectators).reduce(function (acc, _a) {
        var _b, _c;
        var userId = _a[0], spectator = _a[1];
        var normalizedUserId = userId.replace('USER#', '');
        var profile = ((_b = cachedProfiles[normalizedUserId]) === null || _b === void 0 ? void 0 : _b.AUTHENTICATED) ||
            ((_c = cachedProfiles[normalizedUserId]) === null || _c === void 0 ? void 0 : _c.PUBLIC);
        acc[normalizedUserId] = __assign(__assign({}, spectator), { screenName: (profile === null || profile === void 0 ? void 0 : profile.basic.screenName) || spectator.screenName, profileData: profile });
        return acc;
    }, {});
}); };
