// src/utils/formatTimeHMS.ts
export function formatTimeHMS(seconds) {
    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor((seconds % 3600) / 60);
    var remainingSeconds = seconds % 60;
    var formattedHours = hours.toString().padStart(2, '0');
    var formattedMinutes = minutes.toString().padStart(2, '0');
    var formattedSeconds = remainingSeconds.toString().padStart(2, '0');
    return "".concat(formattedHours, ":").concat(formattedMinutes, ":").concat(formattedSeconds);
}
