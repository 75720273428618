var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// website/src/pages/VerifyEmail.tsx
import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { verifyEmail, clearAuthError, resendVerificationCode } from '@store/authSlice';
import { logger } from '@utils/logger';
import { withLayout } from '@components/withLayout';
import { getSignupEmail, clearSignupEmail } from '@utils/authUtils';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
var VerifyEmail = function () {
    var _a, _b;
    var _c = useState(''), verificationCode = _c[0], setVerificationCode = _c[1];
    var _d = useState(''), userEmail = _d[0], setUserEmail = _d[1];
    var _e = useState(false), resendSuccess = _e[0], setResendSuccess = _e[1];
    var _f = useState(false), codeSent = _f[0], setCodeSent = _f[1];
    var location = useLocation();
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var _g = useSelector(function (state) { return state.auth; }), isLoading = _g.isLoading, error = _g.error, isAuthenticated = _g.isAuthenticated;
    var storedEmail = getSignupEmail();
    var emailFromState = (_a = location.state) === null || _a === void 0 ? void 0 : _a.email;
    var email = emailFromState || storedEmail || '';
    var fromSignup = (_b = location.state) === null || _b === void 0 ? void 0 : _b.fromSignup;
    var emailInputRef = useRef(null);
    var codeInputRef = useRef(null);
    useEffect(function () {
        if (isAuthenticated) {
            navigate('/dashboard');
        }
    }, [isAuthenticated, navigate]);
    useEffect(function () {
        var _a, _b;
        if (email) {
            setUserEmail(email);
            setCodeSent(true);
            (_a = codeInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
        else {
            (_b = emailInputRef.current) === null || _b === void 0 ? void 0 : _b.focus();
        }
    }, [email]);
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var emailToVerify, resultAction;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    dispatch(clearAuthError());
                    emailToVerify = email || userEmail;
                    if (!emailToVerify) {
                        logger.error('Email not provided');
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, dispatch(verifyEmail({ email: emailToVerify, code: verificationCode }))];
                case 1:
                    resultAction = _a.sent();
                    if (verifyEmail.fulfilled.match(resultAction)) {
                        logger.log('Verification successful', resultAction.payload);
                        clearSignupEmail(); // Clear the stored email after successful verification
                        navigate('/login');
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handleResendCode = function () { return __awaiter(void 0, void 0, void 0, function () {
        var emailToVerify, resultAction;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    emailToVerify = email || userEmail;
                    if (!emailToVerify) {
                        logger.error('Email not provided');
                        return [2 /*return*/];
                    }
                    dispatch(clearAuthError());
                    return [4 /*yield*/, dispatch(resendVerificationCode(emailToVerify))];
                case 1:
                    resultAction = _b.sent();
                    if (resendVerificationCode.fulfilled.match(resultAction)) {
                        logger.log('Verification code resent successfully');
                        setResendSuccess(true);
                        setCodeSent(true);
                        setTimeout(function () { return setResendSuccess(false); }, 5000);
                        (_a = codeInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Box, __assign({ sx: { maxWidth: 400, margin: 'auto', mt: 4 } }, { children: [_jsx(Typography, __assign({ variant: "h4", gutterBottom: true }, { children: "Verify Your Email" })), error && !fromSignup && _jsx(Alert, __assign({ severity: "error", sx: { mb: 2 } }, { children: error })), resendSuccess && _jsx(Alert, __assign({ severity: "success", sx: { mb: 2 } }, { children: "Verification code resent successfully!" })), _jsxs(Box, __assign({ component: "form", onSubmit: handleSubmit, noValidate: true, sx: { mt: 1 } }, { children: [!codeSent && (_jsx(TextField, { margin: "normal", required: true, fullWidth: true, id: "email", label: "Email Address", name: "email", autoComplete: "email", value: userEmail, onChange: function (e) { return setUserEmail(e.target.value); }, sx: { mb: 2 }, inputRef: emailInputRef })), codeSent && (_jsxs(Typography, __assign({ variant: "body1", gutterBottom: true }, { children: ["We've sent a verification code to ", _jsx("strong", { children: email || userEmail }), ". Please check your email and enter the code below."] }))), _jsx(TextField, { margin: "normal", required: true, fullWidth: true, id: "verificationCode", label: "Verification Code", name: "verificationCode", value: verificationCode, onChange: function (e) { return setVerificationCode(e.target.value); }, inputRef: codeInputRef }), _jsx(Button, __assign({ type: "submit", fullWidth: true, variant: "contained", sx: { mt: 3, mb: 2 }, disabled: isLoading || (!codeSent && !userEmail) }, { children: isLoading ? 'Verifying...' : 'Verify' })), _jsx(Button, __assign({ fullWidth: true, variant: "outlined", onClick: handleResendCode, disabled: isLoading || (!codeSent && !userEmail), sx: { mb: 2 } }, { children: "Resend Verification Code" }))] })), _jsx(Box, __assign({ sx: { mt: 2, textAlign: 'center' } }, { children: _jsx(Link, __assign({ component: RouterLink, to: "/signup" }, { children: "Back to Sign Up" })) }))] })));
};
export default withLayout(VerifyEmail);
